.dropdown-menu76 {
  position: absolute;
  transform: translate(0%,0%);
  border: 1px solid rgb(253, 253, 253);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 10px;
  z-index: 3;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s smooth;
  transition-duration: 1s;
  pointer-events: none; /* Prevent the dropdown menu from blocking hover events */
  height: 400px; /* Set a maximum height for the dropdown menu */
  width: 400px;
  overflow-y: auto; /* Enable scrolling when content exceeds the maximum height */
  overflow-x: auto;
}

.dropdown-menu75 {
  position: absolute;
  transform: translate(-100%,0%);
  border: 1px solid rgb(253, 253, 253);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 10px;
  z-index: 3;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s smooth;
  transition-duration: 1s;
  pointer-events: none; /* Prevent the dropdown menu from blocking hover events */
  height: 400px; /* Set a maximum height for the dropdown menu */
  width: 400px;
  overflow-y: auto; /* Enable scrolling when content exceeds the maximum height */
  overflow-x: auto;
}


.city-image-container {
  position: relative;
  border: white;
  border-width: 1px;
}

.city-name-over-image {
  position: absolute;
  top: 100%; /* Adjust to vertically center the text */
  left: 50%; /* Adjust to horizontally center the text */
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: xx-large;
  font-family: 'Forum', sans-serif;
}

.city-info-container{
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;;
}

.ex-info-styling{
  width: 100%;
  font-family: 'Forum', sans-serif;
  font-size: 12px;
  flex: auto;
}

.ddinfo{
  width: 7vw;
  height: 5vw;
  margin-bottom: 10px;
  margin-left: 0px;
  border: 1px solid white;
  
}
.dropdown-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid white;
}

.dropdown-menu76::-webkit-scrollbar {
  width: 6px;
  height:6px; /* Set the width of the scrollbar */
}

.dropdown-menu76::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.3); /* Set the background color of the scrollbar track */
}

.dropdown-menu76::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255); /* Set the color of the scrollbar thumb */
  border-radius: 3px; /* Set the border radius of the scrollbar thumb */
}

.dropdown-menu76:hover::-webkit-scrollbar-thumb {
  background-color: rgba(253, 252, 251, 0.5); /* Set the color of the scrollbar thumb on hover */
}

.dropdown-menu75::-webkit-scrollbar {
  width: 6px;
  height:6px; /* Set the width of the scrollbar */
}

.dropdown-menu75::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.3); /* Set the background color of the scrollbar track */
}

.dropdown-menu75::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.696); /* Set the color of the scrollbar thumb */
  border-radius: 3px; /* Set the border radius of the scrollbar thumb */
}

.dropdown-menu75:hover::-webkit-scrollbar-thumb {
  background-color: rgba(253, 252, 251, 0.5); /* Set the color of the scrollbar thumb on hover */
}

.mob-text1 p {
  margin-bottom: 5px; /* Add some margin to the paragraphs inside the dropdown menu */
}

.city-circle:hover ~ .dropdown-menu76,
.dropdown-menu76:hover {
  opacity: 1;
  pointer-events: auto; /* Allow hover events on the dropdown menu */
  animation: animation_popup 0.1s;
}
.city-circle:hover ~ .dropdown-menu75,
.dropdown-menu75:hover {
  opacity: 1;
  pointer-events: auto; /* Allow hover events on the dropdown menu */
  animation: animation_popup 0.1s;
}

.city-circle:hover::before {
  opacity: 0.7;
  pointer-events: none; /* Prevent the city circle from blocking hover events */
  animation: hoverblock 4s;
}

@keyframes animation_popup {
  from {
    max-height: 0px;
    max-width: 0px;
  }
  to {
    max-height: 400px;
    max-width: 400px;
  }
}

@keyframes hoverblock {
  0% {
    pointer-events: auto;
  }
  50% {
    pointer-events: auto;
  }
  100% {
    pointer-events: none;
  }
}

.mapData{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 5px;
  font-family: 'Forum', sans-serif;
 
}
.mapData p{
  margin: 0;
  font-family: 'Forum', sans-serif;
}
.mapData.mapDataName{
  font-size: 12px;
}
.mapData.ex-info-styling{
  font-size: 10px;

}