.betting-grid {
  background-image: url("./assets/bg-image.jpg");
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  overflow-y: auto; /* or overflow-y: scroll; */
  overflow-x: hidden;
  max-width: 100vw;
}


  .user-section {
    background-color: #f0f0f0;
    padding: 66px;
    padding-bottom: 0px;
    padding-top: 5px;
    margin-bottom: 19px;
  }
  
  .user-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .day-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100vw; /* Adjust the max-width as needed */
    margin-top: 10px;
    
  }
