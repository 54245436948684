/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  overflow-x: hidden; /* Prevents unwanted horizontal scroll on small screens */
}

.Contactbox{
  display: flex;
  justify-content: space-around;
  flex-basis: 1.2em;
  border:3px solid #a2f8c5;
  margin-right: 7vw;
  margin-left: 7vw;
  margin: 7vw;
  font-size: 21px;
  border-radius: 10px;
  height: 100pt;
  align-items: center;
  color: white;
  font-family:'Times New Roman', Times, serif;
  font-weight: 900;
  background-color:rgba(0,0, 0, 0.7);
  }
  @media(max-width: 450px) {
      .Contactbox{
          height: 200px;
          flex-direction: column;
      }
  }

/* .heading {
    text-align: center;
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); 
    top:0px;
    position: absolute;
    color: white;
    font-family: Montserrat;
    font-weight: 700;
    margin-top: -30px;
} */

.smp {
  width: 100%;
  max-width: 600px; /* Set a max-width for larger screens */
  margin: 150px auto 0 auto;
  display: block;
}

.pp {
  width: 100%;
  max-width: 600px; /* Set a max-width for larger screens */
  margin: 10px auto 0 auto;
  display: block;
}
/* Background container */
.smp-background {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Sponsor section styling */
.sponsor-section {
  padding: 5rem 1rem;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1; /* Ensures sponsor content stays on top */
}

.sponsor-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: white;
}

/* Sponsor grid layout */
.sponsor-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); 
  gap: 12rem;
  max-width: 1200px;
  margin: 0px auto;
  padding: 1rem; 
}

/* Sponsor item */
.sponsor-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease;
}

.sponsor-item:hover {
  transform: translateY(-10px);
}

.sponsor-logo {
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  object-fit: cover; /* Ensures the image fills the container while maintaining its aspect ratio */
  border-radius: 8px; /* Optional: for rounded edges */
  margin-bottom: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .smp, .pp {
      max-width: 70%; /* Smaller width for mobile */
  }

  .sponsor-grid {
      grid-template-columns: repeat(2, 1fr); /* Adjust grid for smaller devices */
  }
}

@media (max-width: 480px) {
  .sponsor-grid {
      grid-template-columns: 1fr; /* Single column on very small devices */
  }
}