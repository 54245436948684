.media {
font-family: Avenir LT Pro ;
padding: 120px 0 50px 0;
}

.media_fixedbg{
    /* background-image: url("./media/bg.jpg"); */
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0;
    z-index: -1;
    background-color: #000000;
}
.media .gradient{
content:'';
position:fixed;
left:0; bottom:0;
width:100%; height:15%;
z-index: 1;
background:  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,  #000000 100%);
}
.media .col-md-4{
margin-bottom: 20px;
justify-content: space-evenly;
/* padding-left: 100px;
padding-right: 100px; */
}

.media .container-fluid{
width: 100%;
padding-right: 100px;
padding-left: 100px;
margin-right: auto;
margin-left: auto;
}

.media .card{
margin-bottom: 15px;
}

.media .card-body{
margin-bottom: -15px;
margin-top: -17px;
text-align: center;
}

.media .card-body img{
vertical-align: middle;
}
/* .image-popup {
 text-decoration: none;
   height: 29px;
   font-family: 'Bebas Kai';
   font-style: normal;
   font-weight: 400;
   font-size: 24px;
   line-height: 29px;
   color: #FFFFFF;
} */