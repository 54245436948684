/* home.module.css */
.footer {

  box-sizing: border-box;
  position: relative;
  display: flex;
  /* flex-wrap: wrap;  */
  width: 100vw;
  /* max-width: 100%; */
  height: 140px;
  background: linear-gradient(180deg, transparent, black);
  justify-content: center;
  text-align: center;
  /* padding: 10px; */
  /* width: 1445px;
  height: 190px;
  top: 7729px;
  gap: 0px;
  opacity: 0px; */
}

.line{
  width: 100%;
  height: 4px;
  top: 100px;
  background-color: #89656577;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.line1{
  width: 100%;
  height: 25px;
  top: 100px;
  background-color: #89656577;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  clip-path: polygon(0 0,80% 0,100% 20%,100% 50%,80% 25%,0 20%);
}

.pulse {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #dcedf5, transparent);
  position: absolute;
  animation: pulse-animation 2s infinite linear;
}

@keyframes pulse-animation {
  0% {
      left: -100%;
  }
  100% {
      left: 100%;
  }
}




.Pulse2{
  position: absolute;
  top: 0;
  width: 28vw;
  height: 50vw;
  right: 0;
}

.dots1{
  position: absolute;
  top: 70px;
  width: 33vw;
  height: auto;
  right: 0;
  animation: dot-animation1 3s infinite linear;
}

.dots2{
  position: absolute;
  top: 70px;
  width: 33vw;
  height: auto;
  right: 0;
  animation: dot-animation2 3s infinite linear;
}

.gradient{
  background: linear-gradient(#1E262A,transparent);
  width: 100vw;
  height: 150px;
}

.mmc{
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.mmc img{
  width: 80%;
}

@keyframes dot-animation1 {
      0% {
        opacity:1;
    }
    50% {
        opacity: 0;
    }

    100%{
      opacity: 1;
    }
}

@keyframes dot-animation2 {
  0% {
    opacity:0;
}
50% {
    opacity: 1;
}

100%{
  opacity: 0;
}
}

.Pulse1{
  position: absolute;
  top: 6vw;
  width: 33vw;
  height: 50vw;
}

/* footer p{
  max-width: 100%;
} */
.icons {
  /* display: inline-block; */
  position: relative;
  height: 80px;
  top: 42px;
  margin-right: 10px;
  /* left: 700px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 40vw;
  /* max-width: 100%; */
}

.icons img {
  /* margin: 0 20px; */
  height: 50px;
  width: 50px;
  margin-bottom: -7px;
  margin-right: 8px;
}

.copyright {
  /* max-width: 100%; */
  /* display: inline-block; */
  position: relative;
  font-size: 24px;
  font-family: Roboto;
  line-height: 28.13px;
  color: #ffffff;
  width: 243px;
  height: 28px;
  top: 72px;
}

@media screen and (max-width:950px) {
  .icons img {
    height: 40px;
    width: 40px;
    margin-right: 4px;
  }

  .copyright {
    font-size: 18px;
  }
}

@media screen and (max-width:750px) {
  .icons img {
    height: 30px;
    width: 30px;
    margin-right: 4px;
  }

  .copyright {
    font-size: 15px;
  }
}

@media screen and (max-width:580px) {
  .footer {
    justify-content: space-around;
  }

  .icons img {
    height: 25px;
    width: 25px;
    margin-right: 4px;
  }

  .copyright {
    font-size: 15px;
  }

  /* Hide scrollbar for all elements */
* {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;     /* Firefox */
}

*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
}

@media screen and (max-width:480px) {
  .footer {
    flex-direction: column;
  }

  .copyright {
    left: 90px;
    top: 20px;
    font-size: 24px;
  }

  .icons {
    top: 15px;
    left: 120px;
  }

  .icons img {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-width:360px) {
  .copyright {
    left: 45px;
    top: 20px;
    font-size: 24px;
  }

  .icons {
    top: 15px;
    left: 100px;
  }
}

.home {
  position: relative;
  /* padding: 5rem; */
  height: 100vh;
  /* Ensure the container has a height */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Ensure text is visible against the background */
  flex-wrap: wrap;
  overflow-x: hidden;
}

/* @font-face {
    font-family: 'LazenbyComputerBold';
    src: url('fonts/LazenbyCompSmooth.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */

body {
  font-family: 'Bai Jamjuree;', sans-serif, 'Roboto';
  background: #0B0C10;
}



.content {
  position: relative;
  z-index: 1;
  color: white;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  /* width: 100vw; */
  /* top: 2vh; */
  /* left:20px; */
  /* left: -200px; */
  /* padding-top: 100vh; */
}

.firstView {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: #1E262A;
   
  justify-content: center;
  /* background-color: ; */
}

.right {
  height: 100vh;
  width: 33vw;
  background: #1E262A;
  /* background:  #1E262A; */
}

.middle {
  height: 100vh;
  width: 33vw;
  position: relative;
  /* margin-top: 5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainrobot {
  width: 450px;
  position: absolute;
  /* left: 2rem; */
  top: 200px;
  z-index: 3;
  /* opacity: 0; */
}

.bgcircle1 {
  height: 450px;
  width: 450px;
  position: absolute;
  top: 100px;
  /* border: solid 2px white; */
  border-radius: 50%;
  background-image: url('./assets/pulsecircle.png');
  background-position: center;
  animation: rotateanimation 3s infinite linear;
  background-size: contain;
  

}

@keyframes rotateanimation {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.rotate {
  position: absolute;
  top: 41%;
  left: 41%;
  height: 5rem;
  width: 5rem;
  -webkit-animation: circle 5s linear infinite;
  animation: circle 5s linear infinite;
  z-index: 1;
}

.rotatingCircle {
  position: absolute;
  top: 0;
  left: 0;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: black;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 1rem grey; */
  z-index: 1;
}

.innerCircle {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #EFEDC4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

}

.innermostCircle {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: black;
  background: linear-gradient(135deg, white 1%, black 25%);
  z-index: 3;

}

@keyframes circle {
  0% {
    transform: rotate(0deg)translate(14rem)rotate(0deg);
  }

  100% {
    transform: rotate(360deg)translate(14rem)rotate(360deg);
  }
}

.verticalLine {
  position: absolute;
  top: 90px;
  height: 15rem;
  border-right: solid 2px #EFEDC4;
  
}

.hallowCircle {
  height: 1rem;
  width: 1rem;
  border: solid 2px #EFEDC4;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  left: -0.4rem;
}

.horizontalLine {
  position: absolute;
  /* top: 50%; */
  top: 320px;
  width: 32rem;
  border-top: solid 2px #EFEDC4;
  /* animation: rotatePhoto 2s infinite linear; */
}

.horizontalLine2 {
  position: absolute;
  /* top: 50%; */
  top: 320px;
  width: 32rem;
  border-top: solid 2px #EFEDC4;
  /* animation: rotatePhotorev 2s infinite linear; */
}


.hallowCircle1 {
  height: 1rem;
  width: 1rem;
  border: solid 2px #EFEDC4;
  border-radius: 50%;
  position: absolute;
  top: -0.5rem;
  right: -1rem;
}

.hallowCircle2 {
  height: 1rem;
  width: 1rem;
  border: solid 2px #EFEDC4;
  border-radius: 50%;
  position: absolute;
  top: -0.75em;
  left: -1rem;
}

.bgcircle2 {
  height: 200px;
  width: 200px;
  top: 220px;
  background: #F8F6D2;
  /* box-shadow: 0px 0px 120px 30px #F8F6D2; */
  position: absolute;
  border: solid 2px black;
  border-radius: 50%;
  z-index: 0;
  /* opacity: 0; */
}

.textbox{
  position: absolute;
  top: 80%;
  z-index: 4;

  .text1{
    position: absolute;
    top: -40%;
    display: flex;

  }

  .text2{
    position: absolute;
    top: 0;
    display: flex;

    .textColomn{
      margin: 0 0.2rem 0 1rem;
    }
  }
}

.left {
  position: relative;
  height: 100vh;
  width: 33vw;
  display: flex;
  background: #1E262A;
  /* background-color: #1e262a; */

}

.cardrow {
  margin-top: 100px;
  width: 100vw;
  display: flex;
  /* gap: 20px; */
  justify-content: center;
  flex-direction: row;
  z-index: 10;
  flex-wrap: wrap;
}

@media (max-width:900px) {
  .cardrow{
    margin-top: 400px;
  }
}


.vid_desc_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 90px;
  gap: 50px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: white;
}

.video {
  border-radius: 25px;
  box-shadow: 10px 10px 5px black;
}

.desc{
  font-size: 15px;
  line-height: 25px;
  font-family: Bruno Ace,sans-serif;
  text-align: justify;
}

.carddescription {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  /* margin-bottom: 20px; */
  color: #FFFFFF;
  ;
}

.cardbutton {
  width: 50%;
  position: absolute;
  bottom: 20px;
  left: 30%;
  display: inline-block;
  padding: 10px 20px;
  background-color: #9BE7BE;
  color: #000000;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  z-index: 3;
}

.cardbutton:hover {
  background-color: #7CD5A3;
  color: blue;
}

@media(max-width: 1100px) {
  .vid_desc_container {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    padding: 90px;
    gap: 50px;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  
  .video {
    border-radius: 25px;
    box-shadow: 10px 10px 5px black;
  }
  
  .desc {
    font-family: Bruno Ace, sans-serif;
    font-size: 18px;
    text-align: center;
  }
}

@media (max-width: 660px) {
  .vid_desc_container {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    padding: 10px;
    gap: 30px;
    margin-top: 16rem;
    margin-bottom: 3rem;
  }

  .video {
    width: 400px;
    height: 250px;
  }

  .desc {
    font-size: clamp(0.9rem, 2vw, 1.2rem); /* Adjust clamp for smaller screens */
    margin-left: 5px;
    margin-right: 5px;
  }
  .right{
    display: none;
  }
  .left{
    display: none;
  }
  .cardrow{
    margin-top: 100px;
  }

  .gradient{
    height: 50px;
  }
}

@media (max-width: 500px) {
  .video {
    width: 280px;
    height: auto;
  }
}

@media(max-width:2560px) {

  .cardrow {
    justify-content: space-evenly;
    align-items: center;
  }

  .card {
    border-radius: 20px;
    text-align: center;
    color: #ffffff;
    font-family: Bruno Ace, sans-serif;
    width: 250px;
    height: 320px;
    margin: 10px 0;
  
    position: relative;
  }
  
  .front {
    position: absolute;
    top: -5rem;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.396) 0%, rgba(153, 153, 153, 0.24) 100%);
    /* background: linear-gradient(180deg,#518768, #3A3A3A); */
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #ffffff;
    font-family: Bruno Ace, sans-serif;
    width: 100%;
    height: 320px;
    /* padding: 10px 0; */
    opacity: 0;
    z-index: 3;
  

      
    }

    .textoverlay{
      height: 300px;
      width: 90.5%;
      position: absolute;
      top: 10px;
      left: 4.95%;
      z-index: 2;
      border-radius: 15px;
      background-color: rgb(0,0,0,0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 3;
  
  }

  .cardtitle{
    font-size: 25px;
    position: absolute;
  }
  
  .back {
    position: absolute;
    top: 0;
    left: 0;
    /* background: linear-gradient(180deg,#518768, #3A3A3A); */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.396) 0%, rgba(153, 153, 153, 0.24) 100%);
  
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #ffffff;
    font-family: Bruno Ace, sans-serif;
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
   
  }

  .cardtitle {
    width: 90%;
    position: absolute;
    bottom: 0;
    left: 5%;
    font-size: 20px;
    font-weight: bold;
    /* margin-bottom: 10px; */
    color: #FFFFFF;
    font-family: Bruno Ace;
    background-color: rgb(0,0,0,0.8);
    border-radius: 0 0 15px 15px;
  }
  
  .card:hover {
    .back {
      opacity: 0;
      transform: rotateY(360deg) translateY(-5rem);
      transition: 0.5s ease-in-out, transform 0.5s;
  
    }
  
    .front {
      opacity: 1;
      transform: rotateY(360deg) translateY(5rem);
      transition: 0.5s ease-in-out, transform 0.5s;
      transition-delay: 0.25s;
    }
    
    .cardtitle{
      opacity: 0;
    }
  }
  
  .cardicon {
    /* margin-bottom: 15px; */
  }
  
  .cardicon img {
    height: 300px;
    width: 90%;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    left: 5%;
    object-fit: cover;
  }
  

}

@media(max-width:2000px) {
  .cardrow {
    justify-content: space-evenly;
    align-items: center;
  }

  
}

@media(max-width: 1024px) {
  .cardrow {
    width: 100vw;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .middle{
    height: 50vh;
  }

  .firstView{
    height: 50vh;
  }

  .card {
    width: 200px;
  }

  #mainrobot {
    height: auto;
    width: 230px;
    top: 180px;
  }

  .bgcircle1 {
    height: 307.2px;
    width: 307.2px;
    top: 100px;
  }

  .rotate {
    height: 3rem;
    width: 3rem;
    left: 129.6px;
    top: 129.6px;

    .rotatingCircle {
      height: 3rem;
      width: 3rem;
    }

    .innerCircle {
      height: 1.5rem;
      width: 1.5rem;
      top: 0.75rem;
      left: 0.75rem;
    }

    .innermostCircle {
      height: 1rem;
      width: 1rem;
      top: 1rem;
      left: 1rem;
    }
  }

  @keyframes circle {
    0% {
      transform: rotate(0deg)translate(153.6px)rotate(0deg);
    }

    100% {
      transform: rotate(360deg)translate(153.6px)rotate(360deg)
    }
  }

  .verticalLine {
    height: 20rem;
    top: 5rem;
  }

  .horizontalLine {
    width: 23rem;
    top: 260px;
  }

  .bgcircle2 {
    height: 150px;
    width: 150px;
    top: 180px;
  }

  
}

@media(max-width: 769px) {
  .card {
    width: 250px;
    margin: 1rem;
  }

  .front {
    font-size: 10px;
  }

  .cardtitle{
    font-size: 10px;
    position: absolute;
  }

  .cardbutton {
    margin-bottom: 0.5rem;
  }

}


@media(max-width: 426px) {

  .card {
    width: 200px;
  }

  .front {
    width: 200px;
  }

  .back {
    width: 200px;
  }

  .left,
  .right {
    display: none;
  }

  .middle {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* height: 300px; */
  }

  #mainrobot {
    width: 50vw;
    top: 25vw;
  }

  .bgcircle1 {
    height: 240px;
    width: 240px;
    top: 15vw;
  }

  .firstView{
    height: 300px;
  }

  .rotate {
    height: 2rem;
    width: 2rem;
    top: 104px;
    left: 104px;

    .rotatingCircle {
      height: 2rem;
      width: 2rem;
    }

    .innerCircle {
      height: 1rem;
      width: 1rem;
      top: 0.5rem;
      left: 0.5rem;
    }

    .innermostCircle {
      height: 0.5rem;
      width: 0.5rem;
      top: 0.75rem;
      left: 0.75rem;
    }
  }

  @keyframes circle {
    0% {
      transform: rotate(0deg)translate(120px)rotate(0deg);
    }

    100% {
      transform: rotate(360deg)translate(120px)rotate(360deg)
    }
  }

  .verticalLine {
    height: 16rem;
    left: 50vw;
    top: 10vw;
  }

  .horizontalLine {
    width: 18rem;
    
    top: 43vw;
  }

  .bgcircle2 {
    height: 28vw;
    width: 28vw;
    
    top: 30vw;
    box-shadow: 0px 0px 50px 25px #F8F6D2;
  }

  .cardrow {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100vw;
    height: auto;
    justify-content: space-evenly;
  }
}

@media(max-width: 376px) {
  .cardrow {
    flex-wrap: nowrap;
    height: 90rem;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }


  #mainrobot {
    width: 60vw;
    height: 65vw;
    top: 25vw;
  }

  .bgcircle1 {
    height: 262.5px;
    width: 262.5px;
    top: 14vw;
  }

  .rotate {
    height: 2rem;
    width: 2rem;
    left: 115.25px;
    top: 115.25px;

    .rotatingCircle {
      height: 2rem;
      width: 2rem;
    }

    .innerCircle {
      height: 1rem;
      width: 1rem;
      top: 0.5rem;
      left: 0.5rem;
    }

    .innermostCircle {
      height: 0.5rem;
      width: 0.5rem;
      top: 0.75rem;
      left: 0.75rem;
    }


  }

  @keyframes circle {
    0% {
      transform: rotate(0deg)translate(131.25px)rotate(0deg);
    }

    100% {
      transform: rotate(360deg)translate(131.25px)rotate(360deg)
    }
  }

  .verticalLine {
    height: 18rem;
    top: 9vw;
    left: 50vw;
  }

  .horizontalLine {
    width: 19rem;
    top: 50vw;
    left: 9vw;
  }

  .bgcircle2 {
    height: 35vw;
    width: 35vw;
    top: 32vw;
    box-shadow: 0px 0px 50px 20px #F8F6D2;
  }

  
}

@media(max-width:321px) {

  #mainrobot {
    top: 50vw;
    scale: 1.5;
  }

  .bgcircle1 {
    height: 250px;
    width: 250px;
    top: 12vw;
  }

  .rotate {
    top: 106px;
    left: 105.5px;
  }

  @keyframes circle {
    0% {
      transform: rotate(0deg)translate(125px)rotate(0deg);
    }

    100% {
      transform: rotate(360deg)translate(125px)rotate(360deg)
    }
  }

  .verticalLine {
    height: 15rem;
  }

  .horizontalLine {
    width: 17rem;
  }

  .bgcircle2 {
    box-shadow: 0px 0px 50px 30px #F8F6D2;
  }

}

/* .card::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -25px;
  width: 50px;
  height: 2px;
  background-color: #9BE7BE;
} */
.explorebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem auto 2.5rem auto;
  width: 10%;
  height: 45px;
  border-radius: 20px;
  position: relative;
  transition: width 0.4s;
  background-color: #9BE7BE;
  border-radius: 20px;
  text-align: center;
  min-width: 100px;
  margin-top: 100px;
  cursor: pointer;

}

.exploreAnchor {
  text-decoration: none;
  font-weight: bold;
  color: black;
  position: relative;
  transition: left 0.5s ease-in-out;
  margin: 50px;
}

.exploreAnchor:hover{
  color: #000000;
}

.exploreIcon {
  font-size: 1.5em;
  color: black;
  position: absolute;
  left: 65%;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.explorebtn:hover {
  width: 17%;
  min-width: 150px;

  .exploreAnchor {
    left: 17%;
  }

  .exploreIcon {
    opacity: 1;
    left: 80%;
    transition-duration: 0.1s;
  }

}

.workshophead {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  height: 100px;
}

@media (max-width:500px) {
  .workshophead img{
    width: 90%;
  }

  .left{
    display: none;
  }

  .right{
    display: none;
  }
}


.yoyo {
  width: 100vw;
  height: auto;
}

.gallery {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  transform-style: preserve-3d;
  transform-origin: center center;
  animation: rotate 10s infinite linear;
  border-radius: 50%;
  /* background: linear-gradient(135deg,rgba(255,255,255,0.1),rgba(255,255,255,0)); */
  /* border: 1px solid rgba(255,255,255,0.18); */
  /* box-shadow: 0 8px 32px 0 rgba(0,0,0,0.37); */
  margin-top: 100px;
  margin-bottom: 200px;
}

.galleryItem {
  position: absolute;
  width: 200px;
  height: 200px;
  transform-style: preserve-3d;
  transform-origin: center center;
  left: 50%;
  top: 50%;
  margin: -100px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  border: 4px solid skyblue;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  /* border-radius: 100% 0px; */
}

.galleryItem img {
  width: 80%;
  height: 80%;
  object-fit: cover;
  /* border-radius: 100% 0px; */
}

@keyframes rotate {
  from {
    transform: perspective(1000px) rotateY(0deg);
  }

  to {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.tflogo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.timtim {
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;

  background: radial-gradient(circle, white 0%, yellow 40%, transparent 70%);
  border-radius: 50%;
  /* Makes the div a circle */

  /* Glowing effect */
  box-shadow: 0 0 2px 4px rgba(255, 255, 0, 0.7),
    0 0 3px 7px rgba(255, 255, 0, 0.5),
    0 0 5px 10px rgba(255, 255, 0, 0.3);

  /* Optional: Animation for the shimmering effect */
  animation: glow 0.5s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 2px 1px rgb(255, 255, 98),
      0 0 3px 2px rgba(255, 255, 0, 0.5),
      0 0 5px 3px rgba(255, 255, 0, 0.3);
  }

  100% {
    box-shadow: 0 0 6px 3px rgb(255, 255, 98),
      0 0 8px 6px rgba(255, 254, 0, 0.7),
      0 0 14px 10px rgba(255, 254, 0, 0.5);
  }
}
.iconconatainer{
  position: relative;
    width: 1rem;
    height: 1rem; 
}
.backgroundImage{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hoverImage{

  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease;
}
.iconcontainer:hover .hoverImage{
  transform:  scale(1);
}

.workh{
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translateY(-200%) ; */
}

.workh img{
  width: 20%;
  transform: translateY(500%);
}

.payment{
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paymentbox{
  width: 33%;
  border-radius: 15px;
}