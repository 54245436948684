.parallaxcontainer {
  position: relative;
  height: 109%;
  width: 100%;
  top: -5rem;
}
  
.parallaxlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

/* Scroll Prompt Styling */
.scrollPrompt {
  position: absolute;
  bottom: 86%;
  left: 71%;
  z-index: 100;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  transition: opacity 1s ease-out;
}

@media (max-width:500px) {
  .scrollPrompt{
    bottom: 94%;
    left: 68%;
  }
}

.scrollPromptShape {
  position: absolute;
  animation: slidedown 2.25s infinite;
}

.scrollPromptShape::before,
.scrollPromptShape::after {
  content: "";
  display: block;
  position: absolute;
  width: 3em;
  height: 0.5em;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 0 0.25em 0.25em 0;
  transform-origin: bottom left;
}

.scrollPromptShape::before {
  transform: scaleX(-1) rotate(-45deg);
}

.scrollPromptShape::after {
  transform: rotate(-45deg);
}

/* Keyframes for sliding down effect */
@keyframes slidedown {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
    transform: translate(0, 80px);
  }
  100% {
    opacity: 0;
    transform: translate(0, 80px);
  }
}


.layer1 {
  background-image: url('../img/bg_workshop1.png');
  z-index: -1;
  position: relative;
  background-size: cover;
  background-attachment: top center;
  background-repeat: no-repeat;
  background-position: center;
}

/* .layer2 {
  background-image: url('../img/workshop_header.png');
  opacity: 1;
  z-index: -1;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position-y:20%;
  background-position-x: center;
} */

/* .layer3 {
  background-image: url('../img/w1\ 2.png');
  opacity: 1;
  z-index: -1;
  background-size: auto 121vh;
  background-repeat: no-repeat;
  background-position: center;
} */

.firstView{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background: #1E262A;
}

.right{
  height: 100vh;
  width: 33vw;
  object-fit: cover;
  background: #1E262A;
}

.left{
  height: 100vh;
  width: 33vw;
}



.middle{
  height: 50vh;
  width: 33vw;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  /* background: #1E262A; */
}

@media (max-width:500px) {
  .middle{
    width: 100vw;
    height: 100%;
  }

  .middleleft img{
    width: 100%;
    position: absolute;
    height: auto;
    scale: 1.75;
  }
  
  
  .middleright img{
    width: 100%;
    position: absolute;
    height: auto;
    scale: 1.75;
  }

  .middlemid img {
    position: absolute; /* Position images absolutely within their container */
    width: 100%; /* Adjust size as necessary */
    height: auto;
    scale: 2;
  }

  .firstView{
    height: 100vh;
    justify-content: center;
    overflow: hidden;
  }

}

.middle img{
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

}

.middlemid img {
  position: absolute; /* Position images absolutely within their container */
  width: 100%; /* Adjust size as necessary */
  height: auto;
  transform: translateY(-20%);
}

.middleleft img{
  width: 100%;
  position: absolute;
  height: auto;
  transform: translateY(-20%);
  /* scale: 1.75; */
}


.middleright img{
  width: 100%;
  position: absolute;
  height: auto;
  transform: translateY(-20%);
  /* scale: 1.75; */
}

.workh{
  width: 20vw;

}

.workh img{
  width: 80%;
  transform: translateY(430%) translateX(25%);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0);
}

@media(max-width:2560px){
  .middle{
    padding-top: 80px;
  }
  .workh img{
    transform: translateY(350%) translateX(10%);
  }
}

@media(max-width:426px){
  .middle{
    padding-top: 150px;
  }
  .workh img{
    transform: translateY(300%) translateX(8%);
  }
}

.middleleft {
  position: absolute;
  width: 33vw;
  z-index: 2;
  
}

.middlemid {
  position: absolute;
  width: 33vw;
  z-index: 1;
  
}

.middleright {
  position: absolute;
  width: 33vw;
  z-index: 3;
  
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}




.image {
  /* background-image: url('../img/bg_workshop1.png'); */
  min-height: 200vh;
  position: relative;
  background-size: cover;
  z-index: -1;
  background-attachment: fixed;
  /* top: -40vh; */
  background-repeat: no-repeat;  
}
.xyz{
  display: inline-block;
}

.exTe{
  position: absolute;
  top: 60vh;
  text-align: center;
  width: 100%;
  font-size: 20px;
}
@media(max-width:748px){
  .layer2{
    background-size: 140%;
    background-position-x: center;
    background-position-y:20%;
  }
  .layer3{
    background-size: auto 95vh;
  
  }
  .image{
    top: -31vh;
  }
}

@media screen and (max-width: 500px) {
  .exTe{
    max-width: 90%;
    margin-right: 3.5%;
    margin-left: 1.5%;
  }
  
}
  