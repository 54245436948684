.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 500ms;
  }
  .popup {
    margin: 0 auto;
    padding: 20px;
    background:rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
    border: 1px solid rgba(0,0,0,1);
    border-radius: 5px;
    width: 30%;
    position: fixed;
    top: 30%;
    /* left: 35%; */
    transition: all .5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: #fff;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #fff;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px) {
    .popup {
      width: 70%;
    }
  }
  