.Card{
    width: 250px;
    height: 300px;
    border: 2px solid black;
    border-radius: 5px;
    align-items: center;
    position: relative;
    margin: 40px;
}

.Card div{
    margin: 2px 0px 2px 0;
    z-index: 1;
    color: white;
}

.text{
    position: absolute;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 5px;
    bottom: 0;
    /* background-color: black; */
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(18,58,50,1));
}

.text div{
    width: 80%;
    text-align: center;
}
.img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.Post{
    font-size: 20px;
}

.Name{
    font-size: 25px;
}

.Email{
    font-size: 13px;
}

.phn{
    font-size: 15px
}

.SecyCards{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media (max-width: 330px){

    .Card{
        width: 220px;
        height: 270px;
        margin: 20px;
    }
}

