*{
    padding: 0;
    margin: 0;
    /* font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal; */
    font-family:  Helvetica, sans-serif;
    font-weight: 100;
}



.mainImg{
    width: 100vw;
    height: 40vw;
    background:linear-gradient(to bottom, #713522, rgba(0,0,0,0));
    display: flex;
    justify-content: space-between;
}



.bright, .bleft{
    width: 100%;
}


.speakerHeader{
    font-size: 50px;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.title{
    display: flex;
    align-items: center;
    font-size: 55px;
    color: wheat;
    text-align: center;
    
}


.cardCont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.reg{
    margin-top: 30px;
    height: fit-content;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.regbtn{
    width:fit-content;
    padding: 10px;
    background-color: #40b0df;
    color: white;
    font-size: 30px;
    border-radius: 10px;

}

.regbtn:hover{
    transform: scale(1.1);
    /* background-color: #40b0df; */
    background-color: #0067b3;
    cursor: pointer;
    box-shadow: 5px 2px 2px rgba(0,0,0,0.3);
    
}

.sliderWrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 150px; /* Adjust based on image height */
    position: relative;
    white-space: nowrap;
    /* top: -63px; */
}

.sliderContainer {
    display: inline-flex;
    animation: scroll 120s linear infinite;
}

.sliderImage {
    min-width: 200px; /* Adjust based on image size */
    height: 100%; /* Make sure images fit the container height */
    object-fit: cover;
    margin-right: 20px; /* Space between images */
}

/* Keyframes for the continuous scrolling animation */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.registerForm {
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(145deg, rgba(0, 0, 0, 0.8), rgba(50, 50, 50, 0.8)); /* Black transparent gradient */
    border-radius: 5px; /* Sharpen the corners */
    width: 80%;
    max-width: 600px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Stronger shadow for sharp effect */
    color: white; /* Text color */
}

.registerForm input, .registerForm button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    border: 1px solid #333; /* Darker border for sharp effect */
    border-radius: 3px; /* Sharpen the corners */
    background-color: rgba(0, 0, 0, 0.7); /* Darker background for inputs */
    color: white; /* White text */
}

.registerForm input::placeholder {
    color: rgba(255, 255, 255, 0.6); /* Lighter placeholder text */
}

.registerForm button {
    background-color: rgba(0, 0, 0, 0.9); /* Dark button */
    color: white;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s; /* Smooth transition */
}

.registerForm button:hover {
    background-color: rgba(30, 30, 30, 0.9); /* Darker shade on hover */
}


/*Media queries */


@media (max-width: 770px){
    .title{
        font-size: 30px;
        padding: 7px;
        height: 250px;
    }
    .bleft, .bright{
        height: 325px ;
    }

    
    .sliderWrapper{
        transform: scaleY(0.9);
        top: 0px;
    }


}

@media (max-width:500px) {
    .mainImg{
        height: 100vh;
    }
    .title{
        padding-top: 40px;
        font-size: 22px;
        height: 200px;
    }
    .mainImg{
        height: 300px;
    }
    .sliderWrapper{
        top: -20px;
        
    }
    .sliderImage{
        min-width: 150px;
    }

    
}


