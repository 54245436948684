
.passpage {
  /* background-image: url("./assets/loadingBG.jpg"); */
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  gap: 20px;
  /* background: radial-gradient(circle, #1f1c18, #0f0c29, #302b63, #24243e); */
  font-family: "Roboto", sans-serif;
  color: #fff;
  overflow: hidden;
  overflow-y: scroll;
}

.container{
  overflow: hidden;
  /* overflow-y: scroll; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10%;
  /* margin-bottom: 20vh;
  padding: 20vh 0; */
}
.ticketcontainer {
  position: relative;
  width: 80%;
  height: auto;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.vipContainer::before{
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 215, 0, 0.4), transparent 80%);
  animation: shimmer 2s infinite;
  transform: rotate(-20deg);
}

.infocontainer{
  width: 60%;
  padding: 2%;
  margin: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  /* border-radius: 1rem; */
  /* border: 1px solid white; */
}

.infocontainer img{
  width: 40%;
}

/* Ticket styling */
.ticket {
  width: 90%;
   backdrop-filter: blur(10px);
    -webkit-backdrop-filter:blur(10px);
    background-color: #212733;
    border: 2px solid #efedc4;
  color: white;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: transform 0.2s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0.8;
}

.name{
  color: white;
  font-size: 2rem;
  margin: 0;
}

.ticketname{
  font-size: 2rem;
  text-shadow: 0 0 10px #004cff, 0 0 10px #004cff;
  font-weight: 700;
  color: white;
}

.ticket img{
  height: 5vh;
  width: 5vh;
}

.ticket:hover {
  transform: scale(1.05);
}

/* Pass styling */
.pass {
  /* position: absolute; */
  top: 100%;
  /* left: 0; */
  width: 100%;
  /* height: 0; */
  height: 0;
  background: black;
  color: black;
  border: 2px solid #f1c40f;
  border-radius: 5px;
  transform: translateY(-10px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  /* margin-bottom: 10vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Visible pass styling */
.slideout {
  transform: translateY(10px);
  opacity: 1;
  height: auto;
  pointer-events: auto;
  position: relative;
  margin: 10px;
  padding: 15PX 0;
}

.passtext{
  text-shadow: none;
  font-size: 1.1rem;
  color: white;
  font-weight: 800;
  align-items: center;
  text-align: center;
}

.map a{
  height: 5vh;
  width: 20vw;
  background-color: #efedc4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 2vh;
  font-weight: 700;
}

.passType{
  width: 100%;
  background-color: white;
  color: black;
  padding: 5% 10%;
  /* border-radius: 1rem; */
  text-shadow: none;
  font-weight: 800;
  text-align: center;
  margin-bottom: 10px;
}

.qrcontainer{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url("./assets/loadingBG.jpg"); */
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  /* background-color: black; */
}

.qrCode {
  /* background: #0d0d0d; */
  padding: 15px;
  height: 100%;
  /* border-radius: 15px; */
  display: inline-block;
  border: 1px solid white;
  background-color: white;
  /* box-shadow: 0 0 15px rgba(255, 255, 255, 0.2), 0 0 25px rgba(255, 255, 255, 0.1); */
}

.qrCode img {
  display: block;
  height: 80%;
}

.emptyspace{
  height: 100%;
  width: 100%;
  background-color: transparent;
}

/* VIP-specific styles */
.vipContainer {
  border: 2px solid #7d700c;
}

.vipPass {
  background: linear-gradient(to bottom, #fff8dc, #f7e7b2);
  border: 3px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
  /* padding: 15px 0; */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2), inset 0px 0px 5px rgba(255, 255, 255, 0.7);
  /* position: absolute;
  top: 0; */
  overflow: hidden;
}

.vipPass h1 {
  color: #daa520;
  font-family: "Georgia", serif;
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  /* margin-bottom: 5px; */
}

.vipPass .passtext {
  color: #806607;
  font-family: "Times New Roman", serif;
  font-size: 1rem;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.vipPass::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 215, 0, 0.4), transparent 80%);
  animation: shimmer 3s infinite;
  transform: rotate(-20deg);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(-20deg);
  }
  100% {
    transform: translateX(100%) rotate(-20deg);
  }
}

@media(max-width: 430px){
  .pass{
    flex-direction: column;
  }

  .container{
    padding-top: 20%;
  }

  .ticket{
    width: 90%;
  }

  .qrCode{
    margin-top: 5%;
  }

  .qrcontainer{
    width: 100%;
  }

  .passType{
    font: 1rem;
    padding: 0;
    width: 105%;
  }

  .vipPass .passtext{
    font-size: 0.75rem;
  }
  .infocontainer{
    width: 90%;
  }

  .ticketname{
    font-size: 1.5rem;
  }

  .infocontainer img{
    width: 80%;
  }

  .map a{
    font-size: 1.5vh;
    width: 35vw;
  }
}