@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');


.navbar {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 20px;
  gap: 50px;
  top: 0;
  background: transparent;
  /* background: black; */
  /* top:100vh; */
  /* background: linear-gradient(90deg, black, white); */
  /* backdrop-filter: blur(10px); */
  /* -webkit-backdrop-filter: blur(10px); */
  /* box-shadow: 0 4px 8px rgba(255, 0, 144, 0.5); */
  height: 6.3vw;
  width: 50vw;
  left: 50vw;
  z-index: 2;
  /* border: 1px solid #E2DFB0; */
  /* border-top: 15px solid black;
  border-left: 15px solid black;
  border-right: 25px solid black; */
  /* clip-path: polygon(0 0, 90% 0, 100% 20%, 100% 100%, 10% 100%, 0 80%); */
}

.tflogo{
  width: auto;
  height: 60px;
  position: absolute;
  
}

.tflogo img{
  width: 100%;
  height: 100%;
  top: 0%;
}

.navimg{
  width: 100vw;
  position:absolute;
  height: 70px;
  right: 0px;
  background: transparent;
  background-color: transparent;
  /* z-index: 1000; */
}

/* .navimg img{
  width: 100%;
  height: 100%;
} */


.tflogo img{
  width: 26vw;
  height: 5.2vw;
}

.navbod{
  height: 6.5vw;
  width: 100%;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUpReverse {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDownReverse {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.navbar h1 {
  color: yellow;
  /* font-family: 'Orbitron', sans-serif; */
  text-transform: uppercase;
  /* letter-spacing: 3px; */
  font-size: 10px;
  text-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff;
  z-index: 100;
  position: relative;
}

.nav-links {
  display: flex;
  gap: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 15;
}

.nav-link-box {
  position: relative;
  /* border: 1px solid white; */
  /* background-color: black; */
  /* overflow: hidden; */
  height: 50px; /* Adjust height to fit the text */
  width: 50px;
  transition: width 0.3s ease, height 0.3s ease; /* Smooth transition for border radius */
  border-radius: 50%;
  /* top: 10px; */
  /* border-top:4px solid #cbfe0142;
  border-bottom:4px solid #cbfe0142;
  border-left: 2px solid black;
  border-right: 2px solid black; */
  animation: none;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 25;
  /* animation: spin 10s linear infinite; */
}

/* .outercircle{
  border: 1px solid skyblue;
 

  width: 150px;
  height: 150px;

  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  
} */

.middlecircle{
  border-top: 2px dashed #cafe01;
  /* border-right: 2px dashed skyblue; */
  border-bottom: 2px dashed #cafe01;
  /* border-left: 2px solid yellow; */
  width: 95px;
  height: 95px;
  border-radius: 50%;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  animation: spin 10s linear infinite reverse;
}
.innercircle{
  border: 1px dotted #cafe01;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  animation: spin 10s linear infinite;
  /* animation: spin 2s linear infinite reverse; */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* .nav-link-box:hover {
  width: 150px;
  height: 150px;
  border-top: 4px solid #cafe01;
  border-bottom:4px solid #cafe01;
} */

.nav-links a {
  color: white;
  /* text-shadow: 0 0 5px rgb(93, 141, 212),
               0 0 10px rgb(102, 163, 212),
               0 0 20px rgb(81, 134, 198),
               0 0 30px skyblue,
               0 0 40px skyblue,
               0 0 50px skyblue,
               0 0 60px skyblue,
               0 0 70px black,
               0 0 80px black,
               0 0 90px black,
               0 0 100px black,
               0 0 110px black,
               0 0 120px black; */
  text-decoration: none;
  text-align: center;
  /* font-family: 'Bruno Ace', sans-serif; */
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%; /* Make the link take full width of the box */
  height: 100%; /* Make the link take full height of the box */
  /* animation: none; */
  z-index: 5000;
  /* animation: spin 10s linear infinite reverse; */
  overflow: visible;
  rotate: 0deg;
  position: relative;
}

.nav-links a span {
  display: flex;
  transition: transform 0.5s ease;
  position: absolute;
  width: 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.nav-links a .top {
  transform: translateY(0);
}

.nav-links a .bottom {
  transform: translateY(50%);
}

.nav-link-box:hover .top {
  animation: slideUp 0.3s forwards;
}

.nav-link-box:hover .bottom {
  animation: slideDown 0.3s forwards;
}

.nav-link-box:not(:hover) .top {
  animation: slideUpReverse 0.3s forwards;
}

.nav-link-box:not(:hover) .bottom {
  animation: slideDownReverse 0.3s forwards;
}

.auth-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed;
  right: 120px;
  top: 20px; */
  /* right: -30vw;
  position: relative; */
}

.sign-in-button, .sign-out-button {
  background-color: #A2F8C5;
  color: black;
  /* border: 2px solid skyblue; */
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  /* font-family: 'Montserrat', monospace; */
  font-family: Bruno Ace,sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 31px;
  width: 120px;
  position: relative;
  /* right: -100px; */
  justify-content: center;
  text-align: center;
  display: flex;
  top: -10px;
}

.sign-in-button:hover, .sign-out-button:hover {
  background-color: white;
  color: red;
}

.tflogohead{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background: linear-gradient(black,transparent);
  display: none;
  z-index: 999;
  position: absolute;
  width: 100vw;
  top: 0;
}

.tflogohead img{
  /* scale: 0.8; */
}

.logo {
  height: 40px;
}

.sidebar{
  width: 100vw;
  height: auto;
  background: black;
  z-index: 55;
  position: fixed;
  top: 0;
  left: -100vw;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s left ease-in-out;
  z-index: 9999;
  height: 100vh;
}

.sidebar.open{
  left: 0;
  transition: 0.5s left ease-in-out;
}

.link {
  margin: 15px 0;
}

.link a {
  text-decoration: none;
  color: white;
  font-family: Bruno Ace;
  font-size: 16px;
  font-weight: 900;
  display: block;
}

.link a:hover {
  color: #A2F8C5;
}

.hamburger {
  font-size: 30px;
  cursor: pointer;
  padding: 10px;
  /* background-color: #black; */
  color: #A2F8C5;
  border: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 10000;
  display: none;
}

@media(max-width:1000px){
  .navbar{
    display: none;
  }

  .sidebar{
    display: flex;
  }

  .hamburger{
    display: flex;
    z-index: 99999999999999999999999999999999999;
  }
  .tflogohead{
    display: flex;
    padding: 15px;
  }
}



