.merchCard{
    height: 334px;
    width: 500px;
    border-radius: 10px;
    background:linear-gradient(to bottom, rgb(255, 255, 255), #000000);

    margin-top: 20px;
    position: relative;
    border: 3px solid rgba(119, 33, 83, 0.874);
    /* box-shadow: 7px 7px 4px rgba(239, 200, 173, 0.707); */
    /* , -7px -7px 4px  rgba(24, 227, 223, 0.6); */
}


.merchCard:hover{
    transform: scale(1.01);
    .overlay{
        height: 60%;
        border-top: 2px solid rgb(24, 227, 223);

    }
}


.overlay{
    width: 100%;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.7);
    height: 0px;
    position: absolute;
    bottom: -5px;
    text-align: center;
    font-size: 30px;
    overflow-y: hidden;
    transition: height ease-in-out 0.2s;
    color: aliceblue;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    /* border-top: 2px solid rgb(24, 227, 223); */
}

.btn{
    background-color: rgb(24, 227, 223);
    /* background: linear-gradient(to right, blue, rgb(247, 244, 244)); */
    border-radius: 5px;
    height: fit-content;
    width: fit-content;
    padding: 1%;
    color: black;
    font-family: Montserrat;
    font-size: 25px;

}

.btn:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.line{
    width: 80%;
    height: 0px;
    border: 2px solid rgb(69, 217, 205);
    border-radius: 2px;
}

.Cardimg{
    height: 100%;
    width: 100%;
    border-radius: 7px;
    
}

.Pricetext span{
    font-family: Montserrat;
    
}

.oldPrice{
   text-decoration: line-through;
   margin-left: 6px;
   font-size: 75%;
}

.saleprice{
    font-weight: bold;
}



@media(max-width:640px){
    .merchCard{
        width: 450px;
        height: 300px;
    }
}

@media(max-width:540px){
    .merchCard{
        width: 400px;
        height: 267px;
    }
    .btn{
        font-size: 20px;
    }
    .Pricetext{
        font-size: 20px;
    }
}

@media(max-width:440px){
    .merchCard{
        width: 300px;
        height: 200px;
    }
    .line{
        border: 1px solid rgb(24, 227, 223);
    }
}

@media(max-width:370px){
    .merchCard{
        width: 230px;
        height: 154px;
    }
    .btn{
        font-size: 12px;
    }
    .Pricetext{
        font-size: 15px;
    }
    .line{
        border: 1px solid rgb(24, 227, 223);
        margin-bottom: -5px;
    }
}