/* signin.module.css */

.signinContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .signinButton, .signoutButton {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background: #4caf50; /* Green button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .signinButton:hover, .signoutButton:hover {
    background: #45a049;
  }
  
  .signoutButton {
    background: #f44336; /* Red button */
  }
  
  .signoutButton:hover {
    background: #e53935;
  }
  
  @media (max-width: 768px) {
    .signinButton, .signoutButton {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  