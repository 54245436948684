* {
    padding: 0;
    margin: 0;
    font-family: "Forum", serif;
}

.body {
    background-image: url('./assets/mun.jpg');
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

.body .section {
    margin-top: 400px;
    /* background-color: white; */
    background-color: #F3E5AB;
}

.techfestHeader {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 10px;

}

/* welcome letter modal */
.modalHaiJi {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    /* Adjusted for responsiveness */
    max-width: 600px;
    /* Upper limit for desktop */
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
    /* Smooth fade-in effect */
}

/* Modal Content Styling */
.modalHaiJi .info {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Close Button Styling */
.closebutton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.2s;
    display: block;
    margin: 0 auto;
    /* Center the button */
}

.closebutton:hover {
    background-color: #d32f2f;
}

/* Fade-in animation for the modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -40%);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .modalHaiJi {
        width: 90%;
        /* Take more width on smaller screens */
        padding: 15px;
    }

    .modalHaiJi .info {
        font-size: 16px;
    }

    .closebutton {
        padding: 8px 14px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .modalHaiJi {
        width: 95%;
        /* Max width on very small screens */
    }

    .modalHaiJi .info {
        font-size: 14px;
    }

    .closebutton {
        padding: 6px 12px;
        font-size: 12px;
    }
}


/* hero */
.hero {
    color: white;
    height: 100vh;
    width: 100vw;
    background-image: url('./assets/mun.jpg');
    /* background-image: url('./assets/mun.jpg'), linear-gradient(to bottom, #3E1C55, black); */
    /* mask-image: linear-gradient(to bottom, transparent 10%, white); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: rgba(255, 255, 255, 0.5); */
    background-blend-mode: overlay;
}

.hero .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5rem 0 5rem;
}

.hero .container img {
    height: 250px;
    width: 250px;
    margin-top: 10rem;
}

.hero .container h1 {
    font-size: 50px;
    font-weight: bold;
    margin-top: 3rem;
}

/* aboutus */
.aboutus {
    height: fit-content;
    width: 100vw;
    background-color: white;
    padding: 5vh;
}

.aboutus .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutus .container h2 {
    font-weight: bold;
    font-size: 4vw;
    margin: 15vh 0 5vh 0;
}

.aboutus .options {
    display: flex;
    flex-wrap: wrap;
}

.aboutus .container img {
    height: 200px;
    width: 200px;
}

.aboutus .container img:hover {
    scale: 1.1;
}

.aboutus .container .options .option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5vh 6vw 5vh 6vw;
}

.aboutus .container .options .option .name {
    font-size: 20px;
    font-weight: bold;
    margin: 2rem 0 0 0;
}

/* video */
.video {
    width: 100vw;
    height: fit-content;
    padding: 5vh;
    background-color: white;
    box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
    display: flex;
}

.vid1 {
    margin: 10vh 5vw 10vh 5vw;
    box-shadow: 10px 10px 15px black;
    width: 40vw;
    height: 25vw;
}

.vid2 {
    margin: 10vh 5vw 10vh 2vw;
    box-shadow: 10px 10px 15px black;
    width: 40vw;
    height: 25vw;
}

/* section */
.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.committees .content {
    width: 100%;
}

/* committees */
.section .committees {
    width: 90vw;
    /* height: 100vh; */
    background-color: white;
    box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    top: -200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bold;
    padding-bottom: 5rem;
}

.section .committees .header {
    font-weight: bold;
    font-size: 4vw;
    margin: 5rem 0 5rem 0;
}

.section .committees .content .school .box img {
    width: 250px;
    height: auto;
    border-radius: 5px;
}

.section .committees .content .college .box img {
    width: 250px;
    height: auto;
    border-radius: 5px;
}

.section .committees .boxesRow1,
.boxesRow2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.box {
    margin-bottom: 30px;
    /* margin-top: 30px; */
    position: relative;
}

.box:hover {
    transition: 0.1s ease-in-out;
    transform: scale(1.05);

    .overlay {
        z-index: 1;
        height: 100%;
        text-align: center;
        color: aliceblue;
    }
}

.overlay {
    position: absolute;
    height: 0px;
    width: 100%;
    background-color: rgba(91, 124, 153, 0.8);
    bottom: 0px;
    border-radius: 5px;
    transition: 0.25s ease-in-out;
    overflow-y: hidden;
    text-align: center;
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.readmore {
    width: 50%;
    height: fit-content;
    font-size: 18px;
    background-color: rgb(18, 58, 50);
    border: 1.5px solid white;
    padding: 3px 0 3px 0;
    border-radius: 3px;
    cursor: pointer;
}

.readmore:hover {
    transform: scale(1.01);
}



.section .committees .content .school,
.college {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 29px;
    gap: 30px;
}

.section .committees .content .school .boxes {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10vh;
}

.committees .content .college p {
    margin-bottom: 5vh;
}

.regheader {
    text-align: center;
    font-weight: bold;
    font-size: 7vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin-bottom: 0;
}

.registration {
    margin-top: -15vh;
    margin-bottom: 15vh;
    display: flex;
    flex-direction: column;
    width: 90vw;
    background-color: white;
    box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
}

.tabs {
    display: flex;
    /* border-bottom: 2px solid black; */
}

.delegates,
.delegation {
    width: 50%;
    text-align: center;
    font-size: 3vh;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 3px;
}

.delegates:hover,
.delegation:hover {
    cursor: pointer;
}

.delegates {
    border-right: 2px solid rgba(0, 0, 0, 0.3);
}

.regbtn {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: rgb(18, 58, 50);
    width: fit-content;
    padding: 10px;
    color: white;
    align-self: center;
    font-size: 3vh;
    border-radius: 3px;
}

.regbtn:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.regContent {
    margin-top: 30px;
    text-align: center;
    font-size: 2.8vh;
    padding-left: 20px;
    padding-right: 20px;
}

.back {
    /* background: linear-gradient(145deg, #e1e1e1, #ffffff); */
    border-bottom: solid 2px rgba(0, 0, 0, 0.3);
    background-color: #e1e1e1;
}

.bottomBorder {
    border-top: solid 2px rgba(0, 0, 0, 0.3);
}

.regbelow {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(-145deg, #ffffff, #e1e1e1); */
    background-color: #fff;
}

/* faqs */
.section .faqs {
    width: 90vw;
    /* height: 100vh; */
    background-color: white;
    box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    padding: 5rem;
    font-size: 17px;
    margin: 0 0 0 0;
}

.section .faqs h2 {
    font-weight: bold;
    font-size: 7vh;
}

.section .faqs .questions .ques {
    font-size: 23px;
}

.section .faqs .questions {
    margin: 3rem 0 0 0;
}

.section .faqs .questions .question {
    margin: 2.5rem 0 0 0;
}

.section .faqs .questions .question .ans {
    color: #626262;
}

/* secretariats */
.section .secretariats {
    margin-top: 10rem;
    width: 90vw;
    /* height: 100vh; */
    background-color: white;
    box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 17px;
    padding: 5rem 5vw 5rem 5vw;
    margin: 0vh 0 15vh 0;
}

.section .secretariats h2 {
    font-weight: bold;
    font-size: 7vh;
    margin-bottom: 5vh;
}

.secyCards {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.secyCards .box {
    margin-right: 0.5vw;
    margin-left: 0.5vw;
}

/* contact */
.contactus {
    margin: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
}

.contactus h2 {
    font-weight: bold;
    font-size: 7vh;
}

.upar {
    width: 100%;
    margin: 4rem 0 1rem 0;
    text-align: 30px;
}

.niche {
    display: flex;
    color: #626262;
}

.contactus .icon {
    width: 33.33%;
    font-size: 30px;
}

.contactus p {
    width: 33.33%;
    text-align: center;
}

/* modal */

.modal {
    height: 70vh;
    z-index: 50;
    position: fixed;
    top: 20vh;
    left: 10vw;
    background-color: grey;
    border: 2px solid black;
    width: 80vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    font-size: 1.3vw;
    /* padding: 3vw; */
}

.information {
    padding: 5vh;
    border-bottom: 2px solid black;
    margin-bottom: 5vh;
    background-color: white;
    height: 75%;
    width: 100%;
    white-space: pre-line;
}

.closebtn {
    padding: 5px 10px 5px 10px;
}

/* @media(max-width:450px){

    .delegates,.delegation{
        font-size: 16px;
        padding: 10px;
    }
}  */
@media (max-width:1245px) {
    .video {
        width: 100vw;
        height: fit-content;
        padding: 5vh;
        background-color: white;
        /* box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2); */
        display: flex;
    }

    .vid1 {
        margin: 10vh 2vw 10vh 5vw;
        box-shadow: 10px 10px 15px black;
        width: 40vw;
        height: 25vw;
    }

    .vid2 {
        margin: 10vh 2vw 10vh 2vw;
        box-shadow: 10px 10px 15px black;
        width: 40vw;
        height: 25vw;
    }
}

@media (max-width: 1120px) {
    .hero .container img {
        height: 55vh;
        width: auto;
        margin-top: 8rem;
    }

    .hero .container h1 {
        font-size: 7vh;
        font-weight: bold;
        margin-top: 1vh;
        text-align: center;
    }

    /* aboutus */
    .aboutus {
        height: fit-content;
        width: 100vw;
        background-color: white;
    }

    .aboutus .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .aboutus .container h2 {
        font-size: 4.5vw;
        font-weight: bold;
        margin: 8vh 2vw 5vh 2vw;
    }

    .aboutus .options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .aboutus .container img {
        height: 31vh;
        width: auto;
    }

    .aboutus .container .options .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2vh 4vw 2vh 2vw;
    }

    .aboutus .container .options .option .name {
        font-size: 20px;
        font-weight: bold;
        margin: 3vh 0 0 0;
    }

    /* section */
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .committees .content {
        width: 100%;
    }

    /* committees */
    .committees .content .school p {
        margin-bottom: -10vh;
    }

    .committees .content .college p {
        margin-bottom: -8vh;
    }

    .section .committees {
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        position: relative;
        top: -100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: bold;
        padding-bottom: 5vh;
    }

    .section .committees .header {
        font-weight: bold;
        margin: 10vh 0 10vh 0;
        font-size: 4.5vw;
    }

    .section .committees .content .school .box img {
        width: 30vh;
        height: auto;
        border-radius: 5px;
    }

    .section .committees .content .college .box img {
        width: 30vh;
        height: auto;
        border-radius: 5px;
    }

    /* .section .committees .content .school .box {
    
} */


    .section .committees .boxesRow1,
    .boxesRow2 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }


    /* registration */
    .registration {
        margin-top: 0vh;
        margin-bottom: 5vh;
        display: flex;
        flex-direction: column;
        width: 90vw;
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
    }

    .registration .regheader {
        font-size: 4.5vw;
    }

    .regContent {
        margin-top: 30px;
        text-align: center;
        font-size: 2.4vh;
        padding-left: 20px;
        padding-right: 20px;
    }

    /* faqs */
    .section .faqs {
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        padding: 8vh 6vh 8vh 6vh;
        font-size: 2.6vh;
        margin: 10vh 0 5vh 0;
    }

    .section .faqs h2 {
        font-weight: bold;
        font-size: 4.5vw;
        text-align: center;
    }

    .section .faqs .questions .ques {
        font-size: 3vh;
    }

    .section .faqs .questions {
        margin: 5vh 0 0 0;
    }

    .section .faqs .questions .question {
        margin: 2.5rem 0 0 0;
    }

    .section .faqs .questions .question .ans {
        color: #626262;
    }

    /* secretariats */
    .section .secretariats {
        /* margin-top: 10rem; */
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: 17px;
        padding: 10vh 0 5vh 0;
        margin: 10vh 0 5vh 0;
    }

    .section .secretariats h2 {
        font-weight: bold;
        font-size: 4.5vw;
    }

    .secyCards {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    /* contact */
    .contactus {
        margin: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 8vw;
    }

    .contactus h2 {
        font-weight: bold;
        font-size: 4.5vw;
    }

    .upar {
        width: 100%;
        margin: 5vh 0 2vh 0;
        text-align: 30px;
    }

    .niche {
        display: flex;
    }

    .contactus .icon {
        width: 33.33%;
        font-size: 4vw;
    }

    .contactus p {
        width: 33.33%;
        text-align: center;
        font-size: 2vw;
        color: #626262;
    }

    .modal {
        height: 60vh;
        z-index: 50;
        position: fixed;
        top: 20vh;
        left: 10vw;
        background-color: grey;
        border: 2px solid black;
        width: 80vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        font-size: 1.3vw;
        /* padding: 3vw; */
    }

    .information {
        padding: 5vh;
        border-bottom: 2px solid black;
        margin-bottom: 5vh;
        background-color: white;
        height: 75%;
        width: 100%;
        font-size: 2.5vh;
    }

    .closebtn {
        padding: 5px 10px 5px 10px;
        font-size: 3vh;
    }
}


@media (max-width: 820px) {

    .hero .container img {
        height: 45vh;
        width: auto;
        margin-top: 8rem;
    }

    .hero .container h1 {
        font-size: 7vw;
        font-weight: bold;
        margin-top: 7vh;
        text-align: center;
    }

    /* aboutus */
    .aboutus {
        height: fit-content;
        width: 100vw;
        background-color: white;
    }

    .aboutus .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .aboutus .container h2 {
        font-size: 7vh;
        font-weight: bold;
        margin: 10vh 2vw 10vh 2vw;
    }

    .aboutus .options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .aboutus .container img {
        height: 25vh;
        width: auto;
    }

    .aboutus .container .options .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2vh 2vw 5vh 2vw;
    }

    .aboutus .container .options .option .name {
        font-size: 20px;
        font-weight: bold;
        margin: 2rem 0 0 0;
    }

    /* section */
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .committees .content {
        width: 100%;
    }

    /* committees */
    .committees .content .school p {
        margin-bottom: -10vh;
    }

    .committees .content .college p {
        margin-bottom: -10vh;
    }

    .section .committees {
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        position: relative;
        top: -100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: bold;
        padding-bottom: 5vh;
    }

    .section .committees .header {
        font-weight: bold;
        margin: 10vh 0 15vh 0;
        font-size: 7vh;
    }

    .section .committees .content .school .box img {
        width: 35vw;
        height: auto;
        border-radius: 5px;
    }

    .section .committees .content .college .box img {
        width: 35vw;
        height: auto;
        border-radius: 5px;
    }

    /* .section .committees .content .school .box {
    
} */

    .section .committees .boxesRow1,
    .boxesRow2 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        /* margin-top: 50px; */
        margin-bottom: 0px;
    }

    /* faqs */
    .section .faqs {
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        padding: 8vh;
        font-size: 2.5vh;
        margin: 10vh 0 0 0;
    }

    .section .faqs h2 {
        font-weight: bold;
        font-size: 7vh;
        text-align: center;
    }

    .section .faqs .questions .ques {
        font-size: 3vh;
    }

    .section .faqs .questions {
        margin: 5vh 0 0 0;
    }

    .section .faqs .questions .question {
        margin: 2.5rem 0 0 0;
    }

    .section .faqs .questions .question .ans {
        color: #626262;
    }

    /* secretariats */
    .section .secretariats {
        /* margin-top: 10rem; */
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: 17px;
        padding: 10vh 0 5vh 0;
    }

    .section .secretariats h2 {
        font-weight: bold;
        font-size: 7vh;
    }

    .secyCards {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    /* contact */
    .contactus {
        margin: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px;
    }

    .contactus h2 {
        font-weight: bold;
        font-size: 7vh;
    }

    .upar {
        width: 100%;
        margin: 4rem 0 1rem 0;
        text-align: 30px;
    }

    .niche {
        display: flex;

    }

    .contactus .icon {
        width: 33.33%;
        font-size: 5vw;
    }

    .contactus p {
        width: 33.33%;
        text-align: center;
        font-size: 2vw;
    }
}

@media (max-width: 700px) {
    .information {
        font-size: 2vh;
    }
}

@media (max-width: 600px) {
    .video {
        width: 100vw;
        height: fit-content;
        padding: 5vh;
        background-color: white;
        /* box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2); */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .vid1 {
        margin: 10vh 5vw 5vh 5vw;
        box-shadow: 10px 10px 15px black;
        width: 75vw;
        height: 40vw;
    }

    .vid2 {
        margin: 5vh 5vw 10vh 5vw;
        box-shadow: 10px 10px 15px black;
        width: 75vw;
        height: 40vw;
    }
    /* modal */

.modal {
    height: 70vh;
    z-index: 50;
    position: fixed;
    top: 20vh;
    left: 10vw;
    background-color: grey;
    border: 2px solid black;
    width: 80vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    font-size: 1.3vw;
    /* padding: 3vw; */
}

.information {
    padding: 5vh;
    border-bottom: 2px solid black;
    margin-bottom: 5vh;
    background-color: white;
    height: 90%;
    width: 100%;
    white-space: pre-line;
}

.closebtn {
    padding: 5px 10px 5px 10px;
    margin: 0;
}
}

@media (max-width:560px) {
    .aboutus {
        height: fit-content;
        width: 100vw;
        background-color: white;
    }

    .aboutus .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .aboutus .container h2 {
        font-size: 7vh;
        font-weight: bold;
        margin: 10vh 2vw 10vh 2vw;
    }

    .aboutus .options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .aboutus .container img {
        height: 25vh;
        width: auto;
    }

    .aboutus .container .options .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2vh 2vw 5vh 2vw;
    }

    .aboutus .container .options .option .name {
        font-size: 20px;
        font-weight: bold;
        margin: 2rem 0 0 0;
    }
        /* modal */

.modal {
    height: 70vh;
    z-index: 50;
    position: fixed;
    top: 20vh;
    left: 10vw;
    background-color: grey;
    border: 2px solid black;
    width: 80vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    font-size: 1.3vw;
    /* padding: 3vw; */
}

.information {
    padding: 5vh;
    border-bottom: 2px solid black;
    margin-bottom: 5vh;
    background-color: white;
    height: 90%;
    width: 100%;
    white-space: pre-line;
}

.closebtn {
    padding: 5px 10px 5px 10px;
    margin: 0;
}
}

@media (max-width: 420px) {

    .hero .container img {
        height: 40vh;
        width: auto;
        margin-top: 8rem;
    }

    .hero .container h1 {
        font-size: 6vh;
        font-weight: bold;
        margin-top: 7vh;
        text-align: center;
    }

    /* aboutus */
    .aboutus {
        height: fit-content;
        width: 100vw;
        background-color: white;
    }

    .aboutus .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .aboutus .container h2 {
        font-size: 10vw;
        font-weight: bold;
        margin: 8vh 2vw 5vh 2vw;
    }

    .aboutus .options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .aboutus .container img {
        height: 28vh;
        width: auto;
    }

    .aboutus .container .options .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2vh 2vw 2vh 2vw;
    }

    .aboutus .container .options .option .name {
        font-size: 20px;
        font-weight: bold;
        margin: 1vh 0 0 0;
    }

    .modal {
        height: 80vh;
        z-index: 50;
        position: fixed;
        top: 10vh;
        left: 10vw;
        background-color: grey;
        border: 2px solid black;
        width: 80vw;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        font-size: 1.3vw;
        /* padding: 3vw; */
    }

    .information {
        padding: 2vh;
        border-bottom: 2px solid black;
        margin-bottom: 5vh;
        background-color: white;
        height: 90%;
        width: 100%;
        font-size: 1.8vh;
    }

    .closebtn {
        padding: 5px 10px 5px 10px;
        font-size: 2.5vh;
        margin-bottom: 5vh;
    }

    /* section */
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .committees .content {
        width: 100%;
    }

    /* committees */
    .committees .content .school p {
        margin-bottom: -10vh;
    }

    .committees .content .college p {
        margin-bottom: -10vh;
    }

    .section .committees {
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        position: relative;
        top: -100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: bold;
        padding-bottom: 5vh;
    }

    .section .committees .header {
        font-weight: bold;
        margin: 10vh 0 10vh 0;
        font-size: 10vw;
    }

    .section .committees .content .school .box img {
        width: 30vh;
        height: auto;
        border-radius: 5px;
    }

    .section .committees .content .college .box img {
        width: 30vh;
        height: auto;
        border-radius: 5px;
    }

    /* 
.section .committees .content .school .box {
    
} */

    .section .committees .boxesRow1,
    .boxesRow2 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 0px;
    }

    /* registration */
    .registration {
        margin-top: 0vh;
        margin-bottom: 5vh;
        display: flex;
        flex-direction: column;
        width: 90vw;
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
    }

    .registration .regheader {
        font-size: 10vw;
    }

    .regContent {
        margin-top: 30px;
        text-align: center;
        font-size: 2.4vh;
        padding-left: 20px;
        padding-right: 20px;
    }

    /* faqs */
    .section .faqs {
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        padding: 8vh 4vh 8vh 4vh;
        font-size: 2vh;
        margin: 10vh 0 5vh 0;
    }

    .section .faqs h2 {
        font-weight: bold;
        font-size: 10vw;
        text-align: center;
    }

    .section .faqs .questions .ques {
        font-size: 2.8vh;
    }

    .section .faqs .questions {
        margin: 5vh 0 0 0;
    }

    .section .faqs .questions .question {
        margin: 2.5rem 0 0 0;
    }

    .section .faqs .questions .question .ans {
        color: #626262;
    }

    /* secretariats */
    .section .secretariats {
        /* margin-top: 10rem; */
        width: 90vw;
        /* height: 100vh; */
        background-color: white;
        box-shadow: 0px 0px 40px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: 17px;
        padding: 10vh 0 5vh 0;
    }

    .section .secretariats h2 {
        font-weight: bold;
        font-size: 10vw;
    }

    .secyCards {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    /* contact */
    .contactus {
        margin: 10vh 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 8vw;
    }

    .contactus h2 {
        font-weight: bold;
        font-size: 10vw;
    }

    .upar {
        width: 100%;
        margin: 5vh 0 2vh 0;
        text-align: 30px;
    }

    .niche {
        display: flex;
    }

    .contactus .icon {
        width: 33.33%;
        font-size: 7vw;
    }

    .contactus p {
        width: 33.33%;
        text-align: center;
        font-size: 3.5vw;
        color: #626262;
    }
}