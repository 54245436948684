.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./img/image.png');
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    z-index: -1;
    overflow-y: scroll;
    /* background-color: white; */
}

.privacypolicy {
  font-family: 'Forum', sans-serif;
  font-size: 30px;
  width: 100vw;
  height: 20vh;
  position: relative;
  top: 7%;
  left: 0%;
  text-align: center;
  color: white;
}

.tnc {
  font-family: 'Forum', sans-serif;
  font-size: 30px;
  width: 100vw;
  height: 23vh;
  position: relative;
  top: 13%;
  left: 0%;
  text-align: center;
  color: white;
}

.content1::before {
  content: "";
  position: absolute;
  width: var(--labelOffset);
  height: var(--labelOffset);
  background: var(--cardColor);
  bottom: 0;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: brightness(0.75);
  color: white;
}

.content1::after {
  content: "";
  position: absolute;
  height: var(--cardsGap);
  width: var(--cardsGap);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.25), transparent 50%);
  left: 0%;
  top: 100%;
}

.transit {
  transition: 1s;
}

.drop-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.item:hover .drop-content {
  max-height: 500px; /* Adjust this value as needed */
  opacity: 1;
}

.tnctxt {
  font-family: 'Forum', sans-serif;
  font-size: 20px;
  width: 100%;
  height: 15vh;
  position: relative;
  text-align: center;
  top: 0%;
  color: white;
}

.privacypolicytxt {
  font-family: 'Forum', sans-serif;
  font-size: 20px;
  width: 100%;
  height: 12vh;
  position: relative;
  text-align: center;
  top: -3%;
  color: white;
}

.whatd {
  overflow: hidden;
  margin-top: -16px;
  margin-bottom: 0px;
  max-height: 0;
  opacity: 0;
  width: 100%;
  margin-left: -30px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.whatdopen {
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 5px;
  width: 100%;
  margin-left: 0px;
  max-height: 2000px; /* Adjust this value to a sufficiently large value */
  height: auto;
  opacity: 1;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  color: white;
}

.list-group-item {
  margin-left: 2vw;
  width: 72vw;
}

.itemuoi {
  margin-bottom: 25px;
  list-style-type: none;
}

.itemuoi li{
  height: 30px;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius:5px;
  border: 1px solid rgb(162, 248, 197);
}