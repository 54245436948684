.unesco-card{
    width: 20vw;
    height: 22vw;
    /* display: inline-block;
    vertical-align: middle; */
    /* flex-shrink: 0; */
    /* background: url("./rectangleRecogi.jpeg") lightgray 50% / cover no-repeat; */
    border-radius: 20px;
    /* align-items: center; */
}

.unesco{
    margin: 5vw 0;
    width: 20vw;
    height: 20vw;
}

.unesco-card p{
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    bottom: 22%;
    color: white;
    text-align: center;

}

@media (max-width:550px) {
    .unesco-card{
        display: flex;
        height: 55vw;
        width: auto;
        flex-direction: column;
        align-items: center;
    }
    .unesco{
        margin: 5vw 0;
        width: 42vw;
        height: 42vw;
    }
    .unesco-card p{
        bottom: 0;
    }
}