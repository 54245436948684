.exhibitions {
    position: relative;
    /* padding: 5rem; */
    height: 100vh; /* Ensure the container has a height */
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
     /* Ensure text is visible against the background */  
     flex-wrap: wrap;   
     overflow: auto; 
     overflow-x: hidden;
}
.heading {
    text-align: center;
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add text shadow for better readability */
    top:170px;
    position: absolute;
    color: white;
    font-family: Montserrat;
    font-weight: 700;
    margin-top: -30px;
}

/* Style the scrollbar */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

/* Style the track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

/* Style the handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Style the handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* body {
    font-family: Forum, sans-serif;
    background: #e3e3ed;
} */

/* .map1 {
    position: relative;
    top: 300px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
} */

.exca{
    /* width: 100vw; */
    display: grid;
    grid-template-columns: repeat(3,minmax(250px,1fr));
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* gap: 150px; */
    /* border: 2px solid blue; */
    position: relative;
    top: 450px;
    /* bottom: 200px; */
    margin: -120px 20px;
    /* z-index: 10; */
}

@media (max-width:1200px) {
    .exca{
        grid-template-columns: repeat(2,minmax(250px,1fr));
    }
}

@media (max-width:800px) {
    .exca{
        grid-template-columns: repeat(1,minmax(250px,1fr));
        margin-bottom: 4vh;
    }
}

.card {
    position: relative;
    width: 326px;
    height: 360px;
    overflow: hidden;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.cardBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Highlighted: Ensure background image stays behind */
}
.image {
    width: 81%;
    height: 85%;
    position:absolute;
    object-fit: cover;
    transition: transform 0.5s ease, filter 0.5s ease;
    z-index: -2;
    filter:brightness(75%);
}

.form {
    text-align: center;
    margin: 8vw;
    margin-top: 15vh;
    z-index: 1000;
}

.form p {
    color: white;
    font-size: 20px;

@media (max-width: 500px) {
    font-size: 15px;
}
}

.form .customButton {
    background: linear-gradient(135deg, #6efb8f, #77e3dc);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .customButton:hover {
    background: linear-gradient(135deg, #a777e3, #6e8efb);
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .customButton:active {
    transform: scale(0.98);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  

/* .polyL {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.5s ease;
    z-index: 1;
    clip-path: polygon(0 0, 0 80%,20% 100%,80% 100%,100% 80%,100% 20%,80% 0);
    rotate: -90deg;
} */

.Contactbox{
    display: flex;
    justify-content: space-around;
    /* flex-basis: 1.2em; */
    border:3px solid #a2f8c5;
    margin: 7vw;
    margin-bottom: 120px;
    font-size: 21px;
    border-radius: 10px;
    height: 100pt;
    align-items: center;
    color: white;
    /* font-family: Bruno Ace,sans-serif; */
    font-weight: 900;
    background-color:rgba(0,0, 0, 0.7);
    width: 85vw;
    font-family:'Times New Roman', Times, serif;
    }
    @media(max-width: 750px) {
        .Contactbox{
            height: 350px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 30px;
            margin-bottom: 100px;
        }
    }

/* .polyR {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.5s ease;
    clip-path: polygon(0 0, 0 80%,20% 100%,80% 100%,100% 80%,100% 20%,80% 0);
    rotate: 180deg;
} */

/* .card:hover .polyL {
    left: 10px;
    bottom: 10px;
    opacity: .9;
    width: 40%;
    height: 35%;
} */

/* .card:hover .polyR{
    right: 10px;
    bottom: 10px;
    opacity: .9;
    width: 40%;
    height: 35%;
} */

.card:hover .image {
    transform: scale(1.1);
    filter: blur(5px);
    filter:brightness(50%);
}

/* .card .image{
    filter: grayscale(100%);
} */

/* .card::before {
    bottom: 10px;
    left: 10px;

    transform: translateY(-100%);
}

.card::after {
    bottom: 10px;
    right: 10px;
    transform: translateX(-100%); 
} */

/* .card:hover::before {
    transform: translate(50%,50%);
} */

/* .card:hover::after {
    transform: translateX(0);
} */

/* .cardcontent {
    position: absolute;
    bottom: 40px;
    left: 5px;
    color: white;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.5s ease;
    z-index: 2;
} */

/* .card:hover .cardcontent {
    bottom: 45%;
    transform: translateY(20%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.6);
} */

/* .cardcontent h3 {
    margin: 0;
    font-size: 20px;
    color: white;
}

.exhicard {
    font-size: 17px;
}

.cardcontent p {
    margin: 8px 0 0;
    font-size: 17px;
    display: none;
}

.card:hover .cardcontent p {
    display: block;
} */

/* .info {
    position: absolute;
    top: 75%;
    left: 45%;
    z-index: 2;
    color: whitesmoke;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    border-radius: 3px;
    padding: 5px;
}

.info {
    font-style: italic;
} */

.text_exhi {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: absolute;
    top: 20%;
    left: 12.5%;
    width: 75%;
    height: 100%;
    margin-top: 12%;
    display: flex;
    transition: top 0.5s ease, opacity 0.5s ease;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none; /* Prevents text from interfering with card hover */
    z-index: 3;
    opacity: 1;
}



/* Name info always visible */
.nameInfo {
    font-size: 22px;
    color: white;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    transition: top 0.5s ease, opacity 0.5s ease;
    /* z-index: 2; */
    pointer-events: none;
}

.country{
    font-size: 11px;
    color: white;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    transition: top 0.5s ease, opacity 0.5s ease;
    /* z-index: 2; */
    pointer-events: none;
}

/* Paragraph info initially hidden */
.parainfo {
    font-size: 15px;
    color: rgb(255, 251, 251);
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none; /* Ensures smooth interaction with hover */
}

/* Show paragraph info on card hover */
.card:hover .parainfo {
    opacity: 1;
}

.card:hover .text_exhi
{
  top: -10%;
}
