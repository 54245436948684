.header {
  font-family: "Forum", sans-serif;
  z-index: 10;
  background-color: black;
  transition: background-color 0.5s ease-in-out;
}

.hamburger {
  display: none;
}

.logoDiv {
  justify-content: center;
}
.webNav {
  justify-content: space-between;
  display: flex;
  width: 100%;
  /* margin-top: 14px; */
}
.webNav li {
  list-style: none;
}
.webNav li a {
  /* font-size: 1rem; */
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease-in-out;
  font-family: Bruno Ace,sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 800;
  /* font-weight: bold; */
}

.tflogoka{
  width: 250px;
}

.webNav li a:hover {
  color: #a2f8c5;
}
.bar {
  display: block;
  width: 25px;
  height: 2px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
  /* position: inherit; */
}

@media (max-width: 1672px) {
  .webNav li a {
    font-size: 13px;
  }
  .logout_rect2 {
    font-size: 19px;
    height: 40px;
    width: 90px;
  }
  .logout_rect1 {
    height: 40px;
    width: 90px;
  }
}
@media (max-width: 1550px) {
  .webNav li a {
    font-size: 15px;
  }
}

.nav_slider ul {
  width: 100%;
  transition: 1s ease;
  display: none;
}

.nav_slider ul li {
  list-style: none;
  text-align: center;
  margin: 0 auto;
  padding: 1rem;
}

.nav_slider ul li a {
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.singin {
  margin-top: -8px;
  cursor: pointer;
}
.logout_rect1 {
  border: #cea167 2px solid;
  width: 90px;
  height: 45px;
  margin: auto;
}

.logout_rect2 {
  border: #cea167 2px solid;
  width: 90px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  transform: translate(-7px, 3px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: Landasans;
  color: #fff;
  text-transform: uppercase;
}

.logou_rect2 img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.navRow {
  position: relative;
  top: 18px;
  height: 100%;
  padding-bottom: 4px;
  /* overflow: hidden; */
  display: flex;
  justify-content: space-around;
}

.wnavLeft,
.wnavRight {
  width: 35%;
}

.sign-out-div {
  position: absolute;
  /* top: 100%; Position it below the img */
  /* left: 0; */
  right: 2pc;
  top: 30%;
  background-color: #000;
  border: 1px solid #cea167;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
}

.sign-out-div button {
  background-color: #000;
  color: white;
  border: none;
  padding: 5px 10px;
  /* margin-top: 5px; */
  cursor: pointer;
  font-family: Landasans;
}

.navfunc {
  z-index: 2147483640;
  width: 100%;
}
.navfunchide {
  position: fixed;
  top: -200px;
  transition: top 0.5s ease-in-out;
  /* background-image: url("./navimg.png"); */
  width: 100%;
  /* height: 160px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.navfuncshow {
  position: fixed;
  top: 0px;
  transition: top 0.5s ease-in-out;
  /* background-image: url("./navimg.png"); */
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: 57px;
}

/* @media (max-width: 1524px) {
  .webNav li a{
    font-size: 18px;
  }
} */

.logout_rect2 {
  font-size: small;
}

@media (max-width: 1240px) {
  .navSummit {
    display: block;
    width: 80%;
    margin: auto;
    top: -8px;
    position: relative;
  }
  .logoDiv img {
    top: 14px;
  }
}

.logoDiv img {
  position: relative;
}
@media (max-width: 1120px) {
  .logoDiv img {
    width: 17vw;
  }
  .navContact {
    display: block;
    width: 80%;
    margin: auto;
    /* top: -8px; */
    position: relative;
  }
}
@media (max-width: 1040px) {
  .webNav li a {
    font-size: 13px;
  }
  .logout_rect2 {
    height: 32px;
    width: 80px;
    font-size: 17px;
  }
  .logout_rect1 {
    height: 32px;
    width: 80px;
  }
  .signin {
    margin-top: -2px;
  }
}
@media (max-width: 878px) {
  .webNav li a {
    font-size: 16px;
  }
  .logout_rect2 {
    height: 32px;
    width: 80px;
    font-size: 16px;
  }
  .logout_rect1 {
    height: 32px;
    width: 80px;
  }
  .signin {
    margin-top: -2px;
  }
}

@media (max-width: 1000px) {
  .navfunchide {
    background-image: none !important;
  }
  .navfuncshow {
    background-image: none !important;
  }
  .headLogo {
    width: 280px;
  }
  .headHam {
    width: 58px;
    margin-top: -18px;
    cursor: pointer;
  }
  .hamburger.active_burger .bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }

  .hamburger.active_burger .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }

  .hamburger.active_burger .bar:nth-child(2) {
    opacity: 0;
  }

  .bar {
    display: block;
    width: 30px;
    height: 2px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
    position: inherit;
  }

  .nav_slider {
    position: fixed;
    min-width: 100%;
    height: 100vh;
    top: 0;
    right: -100vw;
    background: black;
    transition: 1s ease;
    z-index: 99;
    /* margin-top: 90px; */
  }
  .nav_slider.active_slide {
    right: 0;
    transition: 1s ease;
    overflow-y: scroll;
    /* margin-top: 90px; */
  }

  .nav_slider.active_slide::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .nav_slider.active_slide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .nav_slider ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 75px;
    position: relative;
  }

  .nav_slider ul li {
    list-style: none;
    text-align: center;
    margin: 0 auto;
    padding: 1rem;
  }

  .nav_slider ul li a {
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease-in-out;
  }
  .webNav {
    display: none;
  }
  .mnavfunchide {
    position: fixed;
    top: 0px;
    transition: top 0.5s ease-in-out;
    /* background-image: url("./navimg.png"); */
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    /* height: 170px; */
  }

  .mnavfuncshow {
    position: fixed;
    top: 0px;
    transition: top 0.5s ease-in-out;
    /* background-image: url("./navimg.png"); */
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    /* height: 170px; */
  }
  .hamburger {
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    align-content: flex-end;
    flex-wrap: wrap;
    margin-top: 12px;
    position: relative;
    z-index: 100;
    transition: 1s ease;
    align-items: center;
    justify-content: space-between;
  }
}

.llll {
  border: #cea167 2px solid;
  width: 150px;
  height: 45px;
  margin: auto;
}
.lllll {
  border: #cea167 2px solid;
  width: 150px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  transform: translate(-7px, 3px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-family: Landasans;
  color: #fff;
  text-transform: uppercase;
}

.evenLoShow {
  max-height: 500px; /* Adjust the max-height as needed */
  opacity: 1;
  visibility: visible;
  transition: max-height 1s ease, opacity 1s ease, visibility 0s;
}
.evenLoNo {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 1s ease, opacity 1s ease, visibility 0s 1s;
}
.tuJhoom {
  position: absolute;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  width: 208px;
  justify-content: space-evenly;
  z-index: 2;
  align-items: center;
  padding: 15px;
  border-radius: 15px;
  border: 2px solid #a2f8c5;
}
.tuJhoom ul {
  padding-left: 0;
  margin-bottom: 10px;
}
.evenLoNo ul li{
  display: none;
  margin-bottom: 10px;
  position: relative;
}
