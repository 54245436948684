/* ---- reset ---- */
body {
    margin: 0;
    font: normal 75% Arial, Helvetica, sans-serif;
  }
  
  canvas {
    display: flex;
    height: 100vh;
    position: relative;
    
  }
  
  /* ---- particles.js container ---- */
  .particlesJs {
    position: absolute;
    width: 90%;
    height: 100vh;
    /* background-color: black; */
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  /* ---- stats.js ---- */
  .countParticles {
    background: green;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 80px;
    color: green;
    font-size: .8em;
    text-align: left;
    text-indent: 4px;
    line-height: 14px;
    padding-bottom: 2px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    z-index: 1;
    display: none;
  }
  
  .jsCountParticles {
    font-size: 1.1em;
  }
  
  #stats,
  .countParticles {
    -webkit-user-select: none;
  }
  
  #stats {
    border-radius: 3px 3px 0 0;
    /* overflow: hidden; */
  }
  
  .countParticles {
    border-radius: 0 0 3px 3px;
  }
  