.Esports {
    position: relative;
    /* padding: 5rem; */
    height: 100vh;
    /* Ensure the container has a height */
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Ensure text is visible against the background */
    flex-wrap: wrap;
    overflow: auto;
    overflow-x: hidden;
}

.bgmiValoimg {
    height: 450px;
    width: 80vw;
    margin-top: 6rem;
    border-radius: 20px;
}

.HeaderText {
    position: absolute;
    z-index: 1;
    top: 45%;
    height: 100px;
    width: 350px;

}

.Headerimgbox {
    display: flex;
    justify-content: center;
    position: relative;
}


@keyframes heroPopup {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
  }
}
.herobox {
    margin-top: 100px;
    width: 100%;
    border-bottom: 2px solid #A2f8c5;
    border-top: 2px solid #A2f8c5;
}

.hero {
    width: 100%;
    animation-name: heroPopup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

/* .about {
    height: fit-content;
    width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 70px;
    border: solid #A2f8c5 4px;
    border-radius: 20px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    color: #fff;
    font-family: forum;
}

.aboutHeader {
    text-align: center;
    font-family: Bruno Ace;
    height: fit-content;
    padding-top: 25px;
    width: 30%;
    font-size: 30px;
    font-family: forum;
    font-weight: bold;
}

.aboutText {
    font-family: Bruno Ace;
    padding: 40px 40px;
    text-align: justify;
    font-size: 20px;
    line-height: 25px;
    font-family: forum;
} */

@keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .about { 
      height: fit-content;
      width: 80vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: 70px;
      border: solid #A2f8c5 4px;
      border-radius: 20px;
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      color: #fff;
      font-family: forum;
  }
  
  .aboutHeader {
      text-align: center;
      font-family: Bruno Ace;
      height: fit-content;
      padding-top: 25px;
      width: 30%;
      font-size: 30px;
      font-family: forum;
      font-weight: bold;
  }
  
  .aboutText {
      opacity: 0; /* Hidden initially */
      transform: translateX(-200px); /* Start slightly off-screen */
      font-family: Bruno Ace;
      padding: 40px 40px;
      text-align: justify;
      font-size: 20px;
      line-height: 25px;
      font-family: forum;
      transition: transform 1s ease-out;
  }
  
  .appearFromLeft {
      opacity: 1;
      transform: translateX(0);
      animation: slideInFromLeft 1.5s ease forwards;
  }
  


/* .cardbox{
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
    width: 100%;
    flex-wrap: wrap;

}

.card{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.1s;
    margin: 30px;
}


.cardoverlay{
    position: absolute;
    bottom: 0;
    width: 89.5%;
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px 15px;
    margin-bottom: 19px;
    gap: 10px;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
}

.card:hover{
    transform: scale(1.03);
    .cardoverlay{
        opacity: 1;
    }
 
 }

.cardimage{
    width: 300px;
    height: 366px;
    border-radius: 20px;
}

.Helmetimg{
    width: 80px;
    height: 75px;
}

.spikeimg{
    width: 90px;
    height: 100px;
}

.cardText{
    color: white;
    font-size: 30px;
    font-family: Bruno Ace;
}

.bgmibtn{
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}


.btnExp, .btnReg{
    font-family: Bruno Ace;
    color: black;
    padding: 10px;
    font-size: 15px;
    background-color: #A2f8c5;
    cursor: pointer;
    transition: linear 0.1s;
}


.btnExp{
    border-radius: 10px 0 0 0;
    border-right: solid 2px black;
}

.btnExp:hover, .btnReg:hover{
    color: red;
    background-color: antiquewhite;
    transform: scale(1.1);
    
}
.btnReg{
    border-radius: 0 10px 0 0;
} */
@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .cardbox {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    padding: 5px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
  }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Hidden initially */
    /* transform: translateY(100px); Starting position for animation */
    transition: ease-in-out 1s;
    margin-top: 80px;
    width: fit-content;
  }
  
  .appear {
    opacity: 1;
    transform: translateY(0);
    animation: fadeInUp 2s ease forwards;
  }
  
  .cardoverlay {
    position: absolute;
    bottom: 0;
    width: 89.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px 15px;
    margin-bottom: 19px;
    gap: 10px;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
    /* width: 100%; */
  }
  
  .card:hover .cardoverlay {
    opacity: 1;
  }
  
  .cardimage {
    width: 300px;
    height: 366px;
    border-radius: 20px;
  }
  
  .Helmetimg {
    width: 80px;
    height: 75px;
  }
  
  .spikeimg {
    width: 90px;
    height: 100px;
  }
  
  .cardText {
    color: white;
    font-size: 30px;
    font-family: Bruno Ace;
  }
  
  .bgmibtn {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .btnExp, .btnReg {
    font-family: Bruno Ace;
    color: black;
    padding: 10px;
    font-size: 15px;
    background-color: #A2f8c5;
    cursor: pointer;
    transition: linear 0.1s;
  }
  
  .btnExp {
    border-radius: 10px 0 0 0;
    border-right: solid 2px black;
  }
  
  .btnExp:hover, .btnReg:hover {
    color: red;
    background-color: antiquewhite;
    transform: scale(1.1);
  }
  
  .btnReg {
    border-radius: 0 10px 0 0;
  }
  




/* contact us */
.contactus {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border:3px solid #a2f8c5;
    margin: 10vh auto;
    font-size: 21px;
    border-radius: 10px;
    height: fit-content;
    align-items: center;
    color: white;
    font-weight: 900;
    background-color:rgba(0,0, 0, 0.7);
    width: 90vw;
    font-family:'Times New Roman', Times, serif;
}

.contactbox {
    color: white;
    padding: 1rem 5rem 1rem 5rem;
    height: min-content;
}


@media (max-width: 600px) {
    .aboutText {
        font-size: 15px;
        line-height: 1.3;
        padding: 3vh;
    }
}

@media (max-width: 350px){
    .card{
        transform: scale(0.9);
    }
}