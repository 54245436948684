@import url('https://fonts.googleapis.com/css2?family=Forum&family=Frijole&family=Fugaz+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.body {
  text-align: center;
  width: 100vw;
}

/* heading */
.topic {
  padding-top: 100px;
  font-family: "Fugaz One", sans-serif;
  font-size: 80px;
  font-weight: 400;
  font-style: normal;
  color: white;
  margin-bottom: -3vh;

  @media (max-width: 1000px) {
    font-size: 70px;
  }

  @media (max-width: 650px) {
    font-size: 60px;
  }

  @media (max-width: 500px) {
    font-size: 45px;
  }

}

/* registration cards */
.regCards {
  width: 80vw;
  margin: 0 5vh 5vh auto;

  @media (max-width: 1000px) {
    margin: 0 auto 5vh auto;
  }

  @media (max-width: 500px) {
    width: 70vw;
    margin: 0 auto 5vh auto;
  }

}

#card {
  margin: 0 auto;
  /* width: 15vw; */
  background-image: url('./assets/card.png');
}

#cardReg {
  background-image: url('./assets/cardReg.png');
}

#cardTitle {
  /* margin-bottom: -5vh; */
}


/* subheadings */
.subHeading {
  margin: 10vh 0 5vh 0;
  width: 350px;
  height: auto;
  /* width: auto; */

  @media (max-width: 500px) {
    width: 250px;
    height: auto;
    margin: 10vh 0 7vh 0;
  }
}

.desc {
  text-align: start;
  font-size: 20px;
  line-height: 1.5;
  font-family: forum;

  @media (max-width: 1100px) {
    text-align: center;
  }

  @media (max-width:450px) {
    font-size: 15px;
  }
}

.iaw {
  width: 30vw;
  margin: 50px auto;

  @media (max-width:450px) {
    width: 50vw;
  }
}

.iaw p{
  font-size: 2rem;
  color: white;
  font-weight: 700;

  @media(max-width: 450px){
    font-size: 1rem;
  }
}

.iaw img {
  width: 100%;
}

#mrc {
  width: 40vw;

  @media (max-width: 500px) {
    width: 60vw;
  }
}

/* past drivers */
.pastDrivers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: black;
}

.box {
  width: 300px;
  height: 350px;
  margin: 0;
  background-color: #de8c4c;
  border-radius: 5px;
  margin: 2vw;
  padding: 1vw;
  transition: all 0.3s ease;

  img {
    width: 80%;
    height: auto;
    position: relative;
    top: 30px;
    border-radius: 10px;
    margin-bottom: -5vh;
    transition: all 0.5s ease;
  }

  h5 {
    opacity: 0;
  }

  p {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  @media (max-width: 400px) {
    width: 250px;
    margin: 5vw;
  }
}

.box:hover {
  width: 300px;
  height: 370px;
  margin: 0;
  background-color: #de8c4c;
  border-radius: 5px;
  margin: 2vw;
  padding: 1vw;

  img {
    width: 80%;
    height: auto;
    position: relative;
    top: -50px;
    border-radius: 10px;
    margin-bottom: -5vh;
  }

  h5 {
    font-family: Forum;
    font-weight: bold;
    opacity: 1;
  }

  p {
    opacity: 1;
  }

  @media (max-width: 400px) {
    width: 250px;
    margin: 5vw;
  }
}




/* contactus */
.contactus {
  border: 3px solid #FFB232;
  margin: 0vw auto 10vh auto;
  font-size: 20px;
  border-radius: 10px;
  color: white;
  padding: 3vh;
  font-weight: 900;
  width: 70vw;
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  flex-wrap: wrap;

  @media (max-width:450px) {
    font-size: 15px;
  }
}

.contact1,
.contact2 {
  font-family: forum;
}

.forTechIssue {
  color: #D3832A;
  margin-top: 10px;
  margin-bottom: -10px;
  font-weight: bold;
  font-family: forum;
  font-size: 30px;

  @media (max-width:450px) {
    font-size: 20px;
  }
}



/* --------------------parallax--------------------- */

.parallax {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

.img1,
.img2,
.img3,
.car {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.img1 {
  z-index: 1;
  background-image: url('./assets/Top.png');
}

.img2 {
  background-image: url('./assets/Back.jpg');
}

.car {
  /* top: 11px; */
  z-index: 1;
  background-position: right;

  background-size: cover;
  background-image: url('./assets/Car.png');
}

.img3 {
  background-image: url('./assets/ift-d.png');
}

/*-------------------parallax close--------------------- */


/*-------------------cards------------------------------*/

.card {
  margin-bottom: 35px;
}

.compi_card {
  background: rgba(0, 5, 29, 0.847);
  font-family: "Forum", sans-serif;
  padding: 10px;
  transform: scale(1);
  aspect-ratio: 1/1.1;
  overflow: hidden;
  width: 300px;
  margin: auto;
}

.card_img {
  width: 200px;
  aspect-ratio: 1/1;
  object-fit: fill;
  opacity: 1;
  border: 1px solid #bb9564;
  margin: auto;
}


.card_tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_title {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  bottom: 0;
  transition: 1s ease;
}

.card_title h3 {
  color: #ffe381;
  font-family: "Forum", sans-serif;
  padding-top: 5%;
  margin-bottom: 0px;
  font-size: 20px;
  text-transform: capitalize;
}

.card_regexp {
  position: absolute;
  left: 8%;
  bottom: 15px;
  display: flex;
  border-top: 1px solid #bb9564;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  text-align: center;
  width: 85%;
  top: 88%;
  height: 10%;
}

.card_reg {
  border-right: 1px solid #bb9564;
  padding-right: 8%;
  padding-top: 0.4vw;
  padding-left: 8%;
  padding-bottom: 0.4vw;
  color: #ffe381;
}

.card_exp {
  border-left: 1px solid #bb9564;
  padding-right: 4%;
  padding-top: 0.4vw;
  padding-left: 8%;
  padding-bottom: 0.4vw;
}

@media (max-width: 1100px) {
  .card_exp {
    padding-left: 4%;
  }
}

@media (max-width: 991px) {
  .card_exp {
    padding-left: 8%;
  }
}

@media (max-width: 860px) {
  .card_exp {
    padding-left: 6%;
  }
}

.card_reg {
  padding-top: 0.2vw;
  padding-left: 1vw;
  font-size: 20px;
}

@media (max-width: 576px) {
  .card_exp {
    padding-left: 12%;
    padding-top: 1.7vw;
  }

  .card_reg {
    padding-top: 1.2vw;
    padding-left: 0vw;
    font-size: 20px;
    padding-right: 10%;
  }
}

.card_reg a {
  color: #ffe381;
  font-family: "Forum", sans-serif;
  letter-spacing: 0.1em;
  font-size: 18px;
  text-decoration: none;
}

.card_reg button {
  color: #ffe381;
  font-family: "Forum", sans-serif;
  letter-spacing: 0.1em;
  font-size: 18px;
  text-decoration: none;
  background: none;
  border: none;
}

.card_exp a {
  color: #bb9564;
  font-family: "Forum", sans-serif;
  letter-spacing: 0.1em;
  font-size: 18px;
  text-decoration: none;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
}

.card_rect1 {
  border: 1px solid #bb9564;
  position: absolute;
  width: 86%;
  height: 97%;
  left: 7.5%;
  top: 5px;
}

.card_rect2 {
  border: 1px solid #bb9564;
  left: 5.5%;
  width: 90%;
  height: 95%;
  position: absolute;
}

.chand {
  position: absolute;
  right: 9%;
  bottom: 55%;
  height: 20px;
  width: auto;
}

.espark {
  position: absolute;
  left: 15.5%;
  bottom: 25%;
  height: 5px;
  width: auto;
}

.espark2 {
  position: absolute;
  left: 12%;
  bottom: 89%;
  height: 25px;
  width: auto;
}

.espark3 {
  position: absolute;
  left: 88%;
  bottom: 90%;
  height: 5px;
  width: auto;
}

.espark4 {
  position: absolute;
  left: 79%;
  bottom: 20%;
  height: 25px;
  width: auto;
}

.fspark {
  position: absolute;
  left: 18%;
  bottom: 20%;
  height: 20px;
  width: auto;
}

.fspark2 {
  position: absolute;
  left: 10%;
  bottom: 85%;
  height: 20px;
  width: auto;
}

.fspark3 {
  position: absolute;
  left: 86%;
  bottom: 23%;
  height: 20px;
  width: auto;
}

.fspark4 {
  position: absolute;
  left: 83%;
  bottom: 90%;
  height: 20px;
  width: auto;
}

.card_reg:hover a {
  color: #a7e9ff;
}

.card_exp:hover a {
  color: #a7e9ff;
}

.card_title div {
  color: #a7e9ff;
  font-family: "Forum", sans-serif;
  letter-spacing: 0.1em;
  margin-bottom: 0px;
}

/* ----------------cards end------------------ */


.titSpn_rect1 {
  border: #ab8d60 2px solid;
  width: 268px;
  height: 50px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 30px;
}

.titSpn_rect2 {
  border: #ab8d60 2px solid;
  width: 268px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  transform: translate(3px, 3px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-family: "Forum", sans-serif;
}

.about {
  display: flex;
  margin: auto;
  max-width: 88%;
}

.about iframe {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 310px;
  height: 200px;
}

.aboutT {
  text-align: left;
  margin-left: 20px;
  font-size: 18px;
}

/* -------------------past drivers----------------------- */

.pastCards {
  height: 270px;
  border: 1px solid #fff;
  border-radius: 5px;
  overflow-y: hidden;
  margin-bottom: 35px;
}

.infoS {
  position: relative;
  bottom: -79%;
  text-align: left;
  transition: bottom 0.5s ease 0s;
  padding-left: 10px;
}

.infoS h4 {
  margin-bottom: 0;
}

.pastCards:hover .infoS {
  bottom: -55%;
}

@media screen and (max-width: 560px) {
  .pastCards {
    width: 300px;
    margin: auto;
    margin-bottom: 35px;
  }

  .infoS {
    bottom: -55%;
  }

  .about {
    flex-direction: column;
  }
}

/* -----------------past drivers end--------------------- */

.contact {
  width: 42%;
  background-color: #00000082;
  border: 1px solid #fff;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 88px;
}

.contact p {
  margin-top: 20px;
  margin-bottom: 0px;
}

.phone {
  margin-bottom: 20px;
}

.ass img {
  width: 200px;
  height: 100px;

}

@media (max-width: 560px) {
  .contact {
    width: 60%;
  }

  .car {
    background-image: url('./assets/mCard.png');
  }

  .img3 {
    background-image: url('./assets/ift-m.png');
  }
}


.heading {
  margin-top: -70vh;
  width: 100vw;
}