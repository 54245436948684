.body {
    padding: 30px;
    background-image: url("./assets/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
    padding-top: 15vh;
}

.body h1,
h2,h3,
h4,
h5 {
    color: white;
    font-family: avenir;
}

.body h5 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
} 

.one, .two, .three, .four, .five, .six, .seven, .eight {
    margin-bottom: 10vh;
} 

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.line {
    height: 0;
    width: 40vw;
    border: 1px solid #a2f8c5;
    
    @media (max-width:500px) {
        width: 70vw;
    }
}

.line0 {
    height: 0;
    width: 20vw;
    border: 1px solid #a2f8c5;

    @media (max-width:500px) {
        width: 70vw;
    }
}

/* one-------------------------------------------- */

.titleSponsor { 
    padding-bottom: 50px;

    img {
    width: 50%;

    @media (max-width: 700px) {
        width: 90%;
    }
}
}

.CotitleSponsor img {
    width: 40%;

    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 600px) {
        width: 80%;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
}

.onebox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content1 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 50px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content1 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

#oiii {
    width: 45vw;
}

/* two-------------------------------------------- */

.twobox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.content2 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 50px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content2 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

/* three------------------------------------ */

.threebox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content3 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content3 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

/* four------------------------------------------- */

.fourbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content4 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content4 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

/* five--------------------------------------------- */

.fivebox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content5 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content5 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

/* six----------------------------------------------- */

.sixbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content6 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content6 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

/* seven---------------------------------------------- */

.sevenbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content7 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content7 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}

/* eight--------------------------------------------- */
.eightbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content8 {
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;

    @media (max-width: 700px) {
        width: 45vw;
    }
}

.content8 img {
    width: 80%;

    @media (max-width: 500px) {
        width: 100%;
    }
}