/* src/components/card.module.css */
.match-card-container {
    max-width: 300px; /* Set your preferred maximum width */
  margin: 0 auto; /* Center the card */
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
}
.bettingcard3 {
    display: flex;
    width: 270px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .bettingcard3 > div {
    width: calc(33.33% - 0px); /* Adjust the width as needed, considering margin */
    margin-bottom: -10px; /* Adjust the margin as needed */
    box-sizing: border-box;
  }

.match-card-header {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.match-card-team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .match-card-team-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .match-card-team-name {
    font-weight: bold;
    color: white;
  }
.match-card-info {
    margin-bottom: -5px;
}

/* .match-card-update-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.match-card-update-input {
    margin-bottom: 10px;
    padding: 5px;
}

.match-card-update-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.match-card-update-button:hover {
    background-color: #45a049;
} */

.match-card-update-section {
    display: flex;
    flex-direction: column;
  }
  
  .update-section-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .match-card-update-input {
    margin-left: 10px; /* Add margin for separation */
  }
  
  .button-container {
    display: flex;
    gap: 10px;
  }
  
  .update-button {
    background-color: #4caf50; /* Green */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #ff0000; /* Red */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
