.recognition_bg{
    background: url("./Absolutereality.png");
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -100;
    filter: blur(2px);
}

.recogradient{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    background-attachment: fixed;
    padding: 100px 0 20px 0;
}

.recog-header{
    position: relative;
    top: 42%;
    width: 40%;
    height: 68%;
    left: 32%;
    right: 30%;
}

.recognition-header{
    height: 20vw;
    /* width: 40vw !important; */
    margin: 0;
    /* padding: -30vw; */
    display: flex;
    position: relative;
    top: -50px;
}

img{
    margin: 0;
    padding: 0;
}

.unesco-cards{
    /* align-items: center; */
    width: 90%;
    justify-content: center;
    display: flex;
    gap: 10vw;
    flex-direction: row;
    margin: auto;
}

.recog-frame2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    gap: -1vw;
    padding-top: 10%;
}


.recog-container{
    max-width: 80%;
    width: fit-content;
    margin: 0;
}

.leader-container{
    max-width: 80%;
    width: fit-content;
    margin: 0;
}

.recog-head{
    width: 80%;
    margin: 50px;
}

.leader-head{
    /* scale: 1.7; */
    width: 55%;
    left: 20%;
    position: relative;
    margin: 50px;
}

.leaders{
    display: flex;
    margin-top: -10vw;
    flex-direction: row;
}

.letterpm{
    margin-right: -4vw;
    width: 35vw;
    height: 48vw;
    flex-shrink: 0;
    scale: 0.7;
}

.guinness{
    width: 35vw;
    height: 48vw;
    flex-shrink: 0;
    scale: 0.7;
    margin-top: -10vw;
}
.apjk{
    margin-top: 5vw;
    margin-bottom: -4vw;
    scale: 0.75;
    width: 30vw;
    height: 21vw;
    flex-shrink: 0;
}

.twitter{
    scale: 0.75;
    width: 30vw;
    height: 21vw;
    flex-shrink: 0;
}

.horizontal-flex{
    /* max-width: 80%; */
    margin-right: 4vw;
    display: flex;
    /* gap: 30px; */
    flex-direction: column;
}


@media (max-width: 550px){
    .unesco-cards{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* height:1069px; */
        width:100%;
    }
    .recog-frame2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .unesco-card{
        display: flex;
        height: 50%;
        width: auto;
    }
    .cardContainer{
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .recog-header{
        width: 70%;   
        height: 60%; 
        left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .recognition-header{
        height: 40vw;
        /* width: 40vw !important; */
        margin: 0;
        /* padding: -30vw; */
        display: flex;
    }

    .leaders{
            display: flex;
            margin-top: -10vw;
            flex-direction: column;
        
    }
    .leader-head{
        width: 100%;
        left: -20%;
        justify-content: center;
    }
    .recog-head{
        width: 100%;
        left: -20%;
        justify-content: center;
        position: relative;
    }
    .guinness{
        width: 100vw;
        height: 145vw;
        flex-shrink: 0;
        margin-top: -30vw;
        margin-left: -20vw;
    }
    .letterpm{
        width: 100vw;
        height: 145vw;
        flex-shrink: 0;
        margin-top: -10vw;
        margin-left: -20vw;
    }
    .apjk{
        width: 87vw;
        height: 72vw;
        flex-shrink: 0;
        margin-top: -10vw;
        margin-left: -12vw;
    }
    .twitter{
        width: 87vw;
        height: 72vw;
        flex-shrink: 0;
        margin-top: -10vw;
        margin-left: -12vw;
    }
}
