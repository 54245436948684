.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    height: 100vh;
  }
  
  .modal-content {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    border: 2px solid #1ef8c5;
    width: 33%;
    color: white;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .confirm-btn,
  .cancel-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-btn {
    background: #d9534f;
    color: white;
    font-family: Bruno Ace, sans-serif;
  }
  
  .cancel-btn {
    background: #5bc0de;
    color: white;
    font-family: Bruno Ace, sans-serif;
  }

  @media (max-width:700px) {
    .modal-content{
      width: 80%;
    }
  }
  