.scannerContainer {
  text-align: center;
  padding: 40px;
  font-family: "Roboto", Arial, sans-serif;
  background: linear-gradient(135deg, #121212, #1c1c1c);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
  box-sizing: border-box;
}

h1 {
  font-size: 2.5rem;
  color: #00e676; /* Vibrant green for contrast */
  margin-bottom: 10px;
}

.status {
  margin: 15px 0;
  font-size: 1.2rem;
  color: #bdbdbd;
  font-weight: 500;
}

.qrContainer {
  display: inline-block;
  margin: 20px auto;
  border: 4px solid #37474f; /* Subtle border for dark theme */
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
  transition: transform 0.2s ease-in-out;
}

.qrContainer:hover {
  transform: scale(1.05);
}

.result {
  margin-top: 20px;
  background: #1e1e1e; /* Dark background for result */
  padding: 20px;
  border: 2px solid #00e676; /* Highlighted green border */
  border-radius: 10px;
  color: #e0f7fa;
  font-weight: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
}

.result h2 {
  font-size: 1.5rem;
  color: #64ffda; /* Vibrant cyan for headers */
  margin-bottom: 10px;
}

.result p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #e0e0e0;
}

.retryButton {
  margin-top: 20px;
  padding: 12px 25px;
  font-size: 1.2rem;
  background: linear-gradient(135deg, #00e676, #66bb6a); /* Vibrant green gradient */
  color: black; /* High contrast text */
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.retryButton:hover {
  background: linear-gradient(135deg, #00c853, #00e676); /* Slightly brighter hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.8);
}

.retryButton:active {
  transform: translateY(2px);
}

@media screen and (max-width: 768px) {
  .scannerContainer {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  .status {
    font-size: 1rem;
  }

  .qrContainer {
    border: 3px solid #37474f;
  }

  .result {
    font-size: 1rem;
  }

  .retryButton {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
