/* top.module.css */

.heading {
    font-size: 70px;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
}

.live-betting-container {
    text-align: center;
    padding: 100px;
    background-color: black;
    height: 100vh;
    background-attachment: fixed;
}

.round-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px; /* Adjust margin for spacing */
}

.round-image {
    position: relative;
    cursor: pointer;
    margin: 10px; /* Adjust margin for spacing */
}

.round-image-hover {
    width: 300px;
    height: 200px;
    border: 5px solid #fff;
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
}

.image-name-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #7f7878;
    color: #fff;
    font-size: 20px; /* Adjust font size */
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.round-image:hover .round-image-hover {
    transform: scaleX(-1);
}

.round-image:hover .image-name-container {
    opacity: 1;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .heading {
        font-size: 40px;
    }

    .round-image-hover {
        width: 200px;
        height: 150px;
    }

    .image-name-container {
        font-size: 16px;
    }
}
