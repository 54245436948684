.profile {
  padding: 20px;
  min-height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 0.85); /* Darker background */
  color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profilearrange {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profileHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 100px;
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  border: 2px solid #1ef8c5;
  background: rgba(28, 28, 28, 0.7);
  color: white;
  width: 80%;
}

.himanshu {
  background: #1ef8c5;
  border-radius: 5px;
  color: black;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
}

.himanshu:hover {
  background: black;
  color: #1ef8c5;
  border: 2px solid #1ef8c5;
}

.profilePhoto img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #1ef8c5;
}

.profileInfo {
  color: white;
  margin-top: 15px;
}

.profileContent {
  backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.7);
  width: 90%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: "Bruno Ace", sans-serif;
  text-align: center;
  border: 2px solid #1ef8c5;
}

.compiwork {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.compi,
.work {
  flex: 1;
  margin: 10px;
}

.compi h4,
.work h4 {
  color: #1ef8c5;
  font-weight: bold;
  margin-bottom: 10px;
}

.registrations {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.competition,
.workshop {
  border: 2px solid #1ef8c5;
  border-radius: 8px;
  margin: 10px 0;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.competition img,
.workshop img {
  max-width: 60%;
  height: auto;
  border-radius: 8px;
  margin: 10px 0;
}

.competition:hover,
.workshop:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.loading {
  text-align: center;
  color: #1ef8c5;
  font-size: 1.8em;
  padding: 20px;
  font-family: "Bruno Ace", sans-serif;
}

@media (max-width: 700px) {
  .profileHeader {
    width: 95%;
  }

  .compiwork {
    flex-direction: column;
  }

  .profileContent {
    width: 95%;
  }
}

@media (max-width: 430px) {
  .profileHeader {
    margin-right: 0;
    padding: 20px;
  }

  .profilePhoto img {
    width: 100px;
    height: 100px;
  }

  .himanshu {
    font-size: 1em;
    padding: 8px 16px;
  }

  .competition img,
  .workshop img {
    max-width: 80%;
  }
}

.roomContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.roomDetails {
  border: 2px solid white;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  font-size: 17px;
  font-family: Georgia, 'Times New Roman', Times, serif;

  @media (max-width: 800px) {
    font-size: 15px;
  }
}