@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.wdata {
   margin-top: 30px;
   font-family: "Poppins", sans-serif;
   font-weight: 500;
}

.sponsor {
   display: flex;
   justify-content: center;
}

.sponsor img {
   width: 100px;
   height: 50px;
   border-radius: 10px;
   object-fit: fill;
   border: 1px solid #fff;
}

.sponsor a {
   text-decoration: none;
   color: white;
}

.compi_img {
   width: 300px;
   height: 300px;
   border-radius: 20px;
   border: 1px solid #000000;
}

.overlay {
   position: relative;
   top: 80px;
}

.heading {
   text-transform: uppercase;
   font-family: Montserrat;
   font-weight: 700;
   display: flex;
   justify-content: center;
   color: white;
   text-align: center;
}

.compi_prize {
   font-family: "Monserrat", sans-serif;
   font-weight: 600;
   font-size: 24px;
   margin-top: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
}

.statement {
   display: flex;
   justify-content: center;
}

.statement a {
   text-decoration: none;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.2em;
   font-size: 18px;
}

.statement1 {
   display: flex;
   justify-content: center;
   background: #212733;
   border: 3px solid #1ef8c5;
   border-radius: 15px;
   width: 250px;
   margin-left: 50px;
   height: 50px;
   position: relative;
}

.statement1 a {
   text-decoration: none;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.2em;
   font-size: 18px;
}

@media (max-width:500px) {
   .statement1{
      margin-left: 36px;
      position: relative;
      top: -300px;
   }
}

.regbutton{
   background: transparent;
   border: transparent;
   color: white;
   width: 250px;
   height: 50px;
   font-size: 25px;
}

/* .stat_rect1 {
   height: 40px;
   width: 250px;
   border: 1px solid #fff;
   position: absolute;
} */

.stat_rect2 {
   height: 40px;
   width: 250px;
   border: 3px solid #1ef8c5;
   position: absolute;
   border-radius: 15px;
   text-align: center;
   justify-content: center;
   align-items: center;
   display: flex;
   background: #212733;
}


.int_reg {
   display: flex;
   justify-content: center;
   margin-top: 20px;
}

.int_reg a {
   text-decoration: none;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.2em;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0;
   width: 160px;
   height: 40px;
}

.reg_rect1 {
   height: 40px;
   width: 120px;
   border: 1px solid #fff;
   position: absolute;
}

.reg_rect2 {
   background-image: url("../static/card/register.png");
   background-size: cover;
   background-position: center;
   height: 40px;
   width: 160px;
   /* border: 1px solid #fff; */
   position: absolute;
   justify-content: center;
   align-items: center;
}


.reg_rect2:hover {
   background-image: url("../static/card/register2.png");
   background-size: cover;
   background-position: center;
   height: 40px;
   width: 160px;
   /* border: 1px solid #fff; */
   position: absolute;
   justify-content: center;
   align-items: center;
}

.compi_team {
   display: flex;
   justify-content: space-evenly;
   margin-top: 20px;
}

.compi_team a {
   text-decoration: none;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.1em;
   font-size: 18px;
   width: 200px;
   height: 50px;
   position: absolute;
   opacity: 0;
}

.create_rect1,
.join_rect1,
.single_rect1 {
   border: 1px solid #fff;
   position: absolute;
}

.single_rect2 {
   border: 1px solid #fff;
   position: absolute;
}

.join_rect2{
   /* border: 1px solid #fff; */
   position: absolute;
   background-image: url("../static/card/join.png");
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 200px;
   height: 50px;
}

.join_rect2:hover{
   background-image: url("../static/card/join2.png");
}

.create_team {
   width: 200px;
}

/* .create_rect1 {
   height: 40px;
   width: 140px;
} */

.create_rect2 {
   background-image: url('../static/card/create2.png');
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 200px;
   height: 50px;
}

.create_rect2:hover{
   background-image: url('../static/card/create.png');
}

.join_team {
   width: 120px;
   text-decoration: none;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.1em;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
}



.join_rect2 {
   height: 50px;
   width: 200px;
   
}

.single_team {
   width: 200px;
}

.single_rect1 {
   height: 40px;
   width: 200px;
}

.single_rect2 {
   height: 40px;
   width: 200px;
   margin-left: 7px;
   margin-top: 6px;
}

.reg_button {
   background-color: transparent;
   border: none;
   margin: 0;
   padding: 0;
   text-align: inherit;
   font: inherit;
   border-radius: 0;
   appearance: none;
   text-decoration: none;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.1em;
   font-size: 18px;
}

.wImg {
   height: 350px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.imgFrame {
   width: 350px;
   height: 312px;
   position: relative;
   top: 0%;
   border-radius: 26% 26% 0% 0%;
   z-index: 2;
}

.imgCompi {
   width: 250px;
   height: 250px;
   position: relative;
   top: 0%;
   border-radius: 0px;
   z-index: 1;
   filter: drop-shadow(0px 0px 0px #E2DFB0) /* First shadow creates the border */
   drop-shadow(2px 2px 0px #E2DFB0) /* Fine-tunes the sharpness of the border */
   drop-shadow(-2px -2px 0px #E2DFB0); /* Adjust offsets for more uniformity */
}

.youhave {
   margin-top: 0px;
   margin-bottom: 30px;
   color: white;
   background: rgba(0, 0, 0, 0.7);
   padding: 10px;
   border-radius: 10px;
}

.youhave span {
   color: rgb(218, 135, 0);
   font-weight: bolder;
}

/* Mobile  */

.mImgFrame {
   width: 365px;
   height: 319px;
   position: relative;
   top: 0%;
   left: 0.5%;
   /* border-radius: 26% 26% 0% 0%; */
   z-index: 2;
   opacity: 0;
}

.mImgCompi {
   width: 80vw;
   height: auto;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 25px;
   top: -50%;
   position: relative;
   margin: 50px;
}

/* Responsive adjustments */

@media (max-width: 1200px) {
   .imgFrame {
      left: -8.5%;
   }
}

@media (max-width: 992px) {
   .rightdata {
      margin-top: 60px;
   }

   .wImg {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .imgFrame {
      position: absolute;
      top: 9%;
      left: auto;
   }


}

@media (max-width: 768px) {
   .sponsor {
      justify-content: center;
   }

   .wdata {
      margin-top: 70px;
   }

   .basicInfo {
      top: -275px;
      position: relative;
   }

   .compi_prize {
      margin-top: 0;
   }

   .lol_reg {
      top: -275px;
      position: relative;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
   }

   .statement {
      top: -200px;
      position: relative;
   }
}

@media (max-width: 577px) {
   .compi_team {
      flex-direction: column;
      align-items: center;
   }

   .create_team {
      display: flex;
      justify-content: center;
   }
}

@media (max-width: 450px) {
   .doIt {
      justify-content: space-around;
   }

   .mImgFrame {
      width: 80vw;
      height: 322px;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      /* margin-left: 50px; */
      /* left: 50px; */
   }

   .mImgCompi {
      width: 80vw;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 25px;

   }
}

@media (max-width: 364px) {
   .mImgFrame {
      width: 300px;
   }

   .mImgCompi {
      width: 70vw;
   }
}

.doIt {
   display: flex;
   flex-wrap: wrap;
   top: -290px;
   justify-content: space-evenly;
   position: relative;
}

.share {
   display: flex;
   justify-content: center;
   margin-top: 20px;
}

.share_rect1 {
   height: 40px;
   width: 120px;
   border: 1px solid #fff;
   position: absolute;
}

.share_rect2 {
   height: 40px;
   width: 121px;
   border: 1px solid #fff;
   margin-left: 16px;
   margin-top: 7px;
   position: absolute;
   color: #fff;
   font-family: "Forum", sans-serif;
   letter-spacing: 0.2em;
   font-size: 18px;
}

.grouplink {
   display: flex;
}
