.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black; /* Black background */
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  z-index: 99;
}

.rotate0{
  position: absolute;
  width: 90px;

  animation: expand2 6s linear;
}

.rotate1 {
  position: absolute;
  animation: rotate1 3.5s linear infinite, scale1 3.5s linear,expand1 6s linear;
}

.rotate2 {
  position: absolute;
  animation: rotate2 2.2s linear infinite, scale1 2.2s linear,expand1 6s linear;
}

.rotate3 {
  position: absolute;
  animation: rotate3 3.7s linear infinite, scale1 3.7s linear,expand1 6s linear;
}

.rotate4 {
  position: absolute;
  animation: rotate4 5s linear infinite, scale1 5s linear,expand1 6s linear;
}

.rotate5 {
  position: absolute;
  animation: rotate5 5s linear infinite,scale4 6s linear;
}

.rotate6 {
  position: absolute;
  animation: rotate6 5s linear infinite,scale3 6s linear;
}

.rotate7 {
  position: absolute;
  animation: rotate7 1s linear,expand1 6s linear;
}



.rotate8 {
  position: absolute;
  animation: rotate8 1.5s linear,expand1 6s linear;
}

/* Add the gradient overlay using the ::before pseudo-element */
.rotate8::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(from 90deg, black 0%, black 25%, green 50%, blue 75%, red 100%);
  z-index: 2; 
  pointer-events: none; 
}

.rotate9 {
  position: absolute;
  animation: rotate9 5s linear infinite,scale2 1s linear,expand1 6s linear;
  rotate: 20deg;
}

.rotate10 {
  position: absolute;
  animation: rotate10 5s linear infinite,scale2 1s linear,expand1 6s linear;
  rotate:20deg;
}

.rotate11 {
  position: absolute;
  animation: rotate11 5s linear infinite,scale2 1s linear,expand1 6s linear;
}

.rotate12 {
  position: absolute;
  animation: rotate12 5s linear infinite reverse,scale2 1s linear,expand1 6s linear;
}

@keyframes expand1 {
  0%{
    opacity: 1;
  }
  50%{
    scale: 1;
    opacity: 1;
  }
  100%{
    scale: 100;
    opacity: 0;
  }
}

@keyframes expand2 {
  0%{
    scale: 1;
    opacity: 1;
  }
  70%{
    scale: 1;
    opacity: 0;
    scale: 1;
  }
  80%{
    scale: 100;
    opacity: 0;
  }
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale1 {
  0% {
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  32%{
    opacity: 0;
  }
  34%{
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale3{
  0%{
    opacity: 1;
  }
  20% {
    opacity: 0;
    scale: 1;
  }
  22%{
    opacity: 1;
  }
  25%{
    opacity: 0;
  }
  28%{
    opacity: 1;
  }
  30%{
    opacity: 0;
  }
  32%{
    opacity:1;
  }
  35%{
    opacity: 0;
  }
  40%{
    opacity: 1;
  }
  42%{
    opacity: 0;
  }
  45%{
    opacity: 1;
  }
  50%{
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 1;
    scale: 100;
  }
}

@keyframes scale4 {
  0%{
    opacity: 1;
  }
  20% {
    opacity: 1;
    scale: 1;
  }
  22%{
    opacity: 0;
  }
  25%{
    opacity: 1;
  }
  28%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  32%{
    opacity: 0;
  }
  35%{
    opacity: 1;
  }
  40%{
    opacity: 0;
  }
  42%{
    opacity: 1;
  }
  45%{
    opacity: 0;
  }
  50%{
    opacity: 1;
    scale: 1;
  }
  90%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    scale: 100;
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Define similar keyframes for each rotateX class */
@keyframes rotate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale2 {
  0%{
    scale: 0.1;
    opacity: 0;
  }
  100%{
    scale: 1;
    opacity: 1;
  }
}

@keyframes rotate5 {
  0% {
    transform: rotate(0deg);
  }

  25%{
    transform: rotate(20deg);
  }
  50%{
    transform: rotate(0deg);
  }
  60%{
    transform: rotate(10deg);

  }
  65%{
    transform: rotate(0deg);
  }
  /* 72%{
    transform: rotate(0deg);
  } */

  75%{
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate6 {
  0% {
    transform: rotate(0deg);
  }

  25%{
    transform: rotate(20deg);
  }
  50%{
    transform: rotate(0deg);
  }
  /* 72%{
    transform: rotate(0deg);
  } */
  60%{
    transform: rotate(10deg);

  }
  65%{
    transform: rotate(0deg);
  }

  75%{
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate7 {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1); /* Reverse direction */
  }
}

@keyframes rotate8 {
  0% {
    
    opacity: 0;
  }
  60%{
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes rotate9 {
  0% {
    transform: rotate(0deg);
  }

  40%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(40deg);
  }
  53%{
    transform: rotate(35deg);
  }
  57%{
    transform: rotate(40deg);
  }
  70%{
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate10 {
  0% {
    transform: rotate(0deg);
  }

  10%{
    transform: rotate(40deg);
  }
  20%{
    transform: rotate(0deg);
  }
  30%{
    transform: rotate(40deg);
  }

  35%{
    transform: rotate(35deg);

  }
  40%{
    transform: rotate(40deg);
  }
  70%{
    transform: rotate(40deg);
  }
  
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate11 {
  0% {
    transform: rotate(0deg);
  }

  10%{
    transform: rotate(40deg);
  }
  20%{
    transform: rotate(0deg);
  }
  30%{
    transform: rotate(40deg);
  }

  35%{
    transform: rotate(35deg);

  }
  40%{
    transform: rotate(40deg);
  }
  70%{
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate12 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg); /* Different direction */
  }
}
