@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.mainContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.firstview {
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url('./assets/cyber.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    background-color: transparent;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.accobanner {
    background-image: url('./assets/Group 225.png');
    background-position: center;
    background-size: cover;
    height: 100px;
    width: 400px;
    z-index: 5;
}

.regbutton {
    width: 100px;
    height: 50px;
}

.content1 {
    height: 100vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 100px;
}

.content2 {
    height: 100vh;
    width: 70vw;
}

.NavButton {
    height: 50px;
    width: 200px;
    background-color: Black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: solid 2px rgb(255, 245, 179);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}

.NavButton:hover {
    color: black;
    background-color: white;
}

.bar_oii1 {
    /* padding: 2px; */
    width: 40vw;
    /* padding: 2px 0px; */
    height: 100px;
    font-size: 30px;
    cursor: pointer;
    color: white;
    border-radius: 0px;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #a2f8c5;
    border-radius: 5px;
    /* overflow: hidden; */
    font-family: Bruno Ace, sans-serif;
}

.bar_oii1:hover {
    color: black;
    background: #a2f8c5;
    border: 1px solid rgba(0, 0, 0, 0.7);
    /* transition: 0.1s ease-in-out; */
}

.bar_oii {
    /* padding: 2px; */
    width: 180px;
    /* padding: 2px 0px; */
    height: 30px;
    font-size: 17px;
    cursor: pointer;
    color: white;
    border-radius: 0px;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #a2f8c5;
    border-radius: 5px;
    /* overflow: hidden; */
    font-family: Bruno Ace, sans-serif;
}

.bar_oii:hover {
    color: #a2f8c5;
    /* transition: 0.1s ease-in-out; */
}

.info_data {
    color: white;
    text-align: left;
    font-size: 15px;
    /* border: 1px solid white; */
}

.info_data h2 {
    font-family: Bruno Ace, sans-serif;
}

.info_data p {
    font-family: "Noto Sans", sans-serif;
}

.active {
    width: 180px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A2F8C5;
    border: 1px solid #a2f8c5;
    border-radius: 5px;
    text-align: center;
    color: black;
}

.info_tab {
    height: 400px;
    width: 100%;
    padding: 10px;
    padding-bottom: 50px;
    background: rgba(0, 0, 0, 0.7);
    /* box-shadow: 20px 20px 33px #868686, -20px -20px 33px #ffffff; */
    margin-top: 20px;
    border-radius: 10px;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    color: white;
    border: 1px solid #a2f8c5;
}

.information {
    color: white;
    width: 70%;
    margin: 10%;
}

.information .hide {
    display: none;
}

.register {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.form {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.genderOpt {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
}

input {
    width: 100%;
    height: 32px;
    margin: 5px;
    background-color: transparent;
    color: white;
    border: 1px solid rgb(162, 248, 197, 0.5);
    border-radius: 5px;
    padding: 11px;
}

label {
    display: flex;
    align-items: center;
}

.inputDiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    border-radius: 5px;
    border: 1px solid rgb(162, 248, 197, 0.5);
}

.inputDiv p {
    margin: auto 0;
}

.inputDiv:nth-child(2) {
    padding: 10%;
}

.inputDiv input {
    width: 50%;
    height: 20px;
    /* padding: auto; */
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerButton {
    margin: 10px;
    width: 150px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid rgb(162, 248, 197, 0.5);
    color: white;
}

.contacts {
    display: flex;
    flex-wrap: wrap;
}

.contact {
    margin-right: 5vh;
    margin-bottom: 4vh;
}

@media (max-width:500px) {
    .mainContainer {
        flex-direction: column;
    }

    .bar_oii1 {
        font-size: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .accobanner {
        height: 60px;
        width: 240px;
    }

    .content1 {
        justify-content: space-between;
        display: flex;
    }

    .active {
        text-align: center;
    }

    .contact {
        font-size: 2vh;
    }
}