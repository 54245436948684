/* src/components/Grid.module.css */
.grid-container {
    position: relative;
    top: 0vh;
    padding-bottom: 60px;
}
.flask{
  display: flex;
  flex-direction: row;
}
.export-csv-button-container {
  margin-top: 20px; /* Add some space between the form and the export button */
}

.export-csv-button {
  background-color: #4caf50; /* Green color */
  color: #fff; /* White text */
  padding: 15px 30px; /* Adjust the padding for your desired size */
  font-size: 18px; /* Adjust the font size for your desired size */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.grid{
    display: flex;
    flex-direction: column;
}
.day-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100vw; /* Adjust the max-width as needed */
    margin-top: 10px;
    
  }
  

.match-section {
    margin-bottom: 20px;
}

.match-type {
    font-size: 2rem;
    margin-bottom: 10px;
}

.match-day {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.add-match-section {
    margin-top: 20px;
    padding: 20px;
    width: 800px;
 

    position: relative;
    border: 2px solid #ccc;
    border-radius: 8px;
  }
  
  .add-match-form {
    display: flex;
    flex-direction: column;
  }
  .add-match-for {
    display: flex;
    flex-direction: row;
    padding-top: 50px;
  }
  
  .add-match-form label {
    margin-top: 10px;
  }
  
  .add-match-form input {
    margin-top: 5px;
    padding: 8px;
    width: 150px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-match-form button {
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-match-form button {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }
  