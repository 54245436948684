.Card{
    width: 400px;
    height: fit-content;
    /* padding: 20px; */
    z-index: 2;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 40px;
    /* background:linear-gradient(to top, rgb(194, 134, 237), rgb(62 ,29,86)); */
    background:linear-gradient(to top,  #327b7a, #133a39);
    padding-top: 20px;
    color: black;
    border: 3px solid wheat;
    /* padding: 10px; */
    
    /* border: 2px solid orange; */
}

.linkedin{
    color: azure;

}
.Card div{
    color: white;
}

.imgBox{
    display: flex;
    width: 100%;
    /* border-bottom: 2px solid #40B0DF; */
    height: 200px;
}

.imgDiv{
    width: 60%;
    display: flex;
    justify-content: center;
}

.img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.name, .post, .company{
    width: 100%;
    text-align: center;
    padding: 5px;
}

.name{
    padding-top: 20px;
    font-size: 25px;
    height: 40%;

}

.post{
    font-size: 18px;
    height: 30%;
}
.company{
    font-size: 16px;

}

.namePost{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center ;
    padding: 5px;
}

.logo{
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
}

.logoimg{
    height: 80px;

}


.desc{
    height: 40%;
    text-align: center;
    /* padding: 10px; */
    font-size: 16px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
    padding-top: 15px;
    /* overflow-y: hidden; */
    /* overflow-y: hidden;
    transition: height 0.5s linear;
    transition: top 0.5s linear; */
}

.linkedIn{
    text-decoration: none;
    color: azure;
}

.Card:hover{
    transform: scale(1.05);
    box-shadow: 10px 5px 5px rgba(0,0,0,0.3);
}

@media (max-width:500px) {
    .imgBox{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Card{
        height: 380px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .logoimg{
        transform: scale(0.8);
    }

    .img{
        transform: scale(1);
    }
}

