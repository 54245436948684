/* *{
  scroll-padding-top: 2000px;
} */

.ourReach h1{
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    
      color:white;
      
    
  }
  
  .ourReach{
    margin-bottom: 20px;
    position: relative;
    width: 99vw;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 40px;
      margin-top: 50px;
  }
  
.ourReach img{
  width: 30%;
}

@media (max-width:600px) {
  .ourReach img{
    width: 80%;
  }
}

  .loader{
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
  }
  .buffer{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    width: calc(33.33vw - 5vw);
    /* overflow: hidden; */
    margin-bottom: 10vh;
    /* margin: 2vw;   */
  }
  #myImg{
    /* position: absolute; */
    width: 80%;
    /* height: 80%; */
    /* animation: rotateAnimation 5s infinite linear ; */
  
    /* width: 100%; */
  }

  #down{
    position: absolute;
    animation: rotateAnimation 2s infinite linear ;
    width: 66%;
    /* height: 66%; */
  }

  #up{
    position: absolute;
    animation: rotateAnimationrev 2s infinite linear;
    width: 42%;
    /* height: 42%; */
  }
  .Overlay{
    /* transform: translateY(-30px) translateX(10px); */
    position: absolute;
    /* color:blueviolet; */
    /* font-weight: bold; */
    font-size: 20px;
    font-family: Bruno Ace, sans-serif;
    animation: none;
    color: white;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 20%;
    width: 20%;
    /* top: 30%;
    left:43%; */
  }

  .bottomhead{
    position: absolute;
    top: 100%;
    color: white;
    font-family: Bruno Ace;
    font-weight: 900;
  }

  .Overlay p{
    margin-bottom: 0;
    font-weight: 900;
  }


  
  @keyframes rotateAnimation {
    0%{
      transform: rotate(0deg);
    }
    50%{
      transform: rotate(180deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
  @keyframes rotateAnimationrev {
    0%{
      transform: rotate(360deg);
    }
    50%{
      transform: rotate(180deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  @media(max-width: 748px){
    .loader{
      flex-direction: column;
      align-items: center;
    }
    .loader .buffer{
      width: 50vw;
      /* height: 25vh; */
    }
    #myimg{
      width: 0px;
      height: 0px;
    }
    .Overlay{
      font-size: 20px;
    }
  }
  @media(max-width:400px){
    #myimg{
      width: 100%;
      height:100%;
    }
    .loader .buffer{
      width: 80vw;
    }
  }
  .Overlay h2{
    margin-top: 5pt;
  }
  .buffer img{
    /* margin-bottom: 15pt; */
  }
  