/* carousel.module.css */
.carouselContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top:-70px;
    z-index: -1;
    justify-content: center;
    align-items: center;
    display: flex;
    /* left:50%; */
    /* top:50%; */
    /* border: 70px solid white; */
}

.imageContainer {
    position: absolute;
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: clip-path 1s ease-in-out, transform 1s ease-in-out;
    clip-path: inset(0 0 0 0); /* Default to fully visible */
}

.previous {
    z-index: 1;
    clip-path: inset(0 0 0 0); /* Fully visible */
    transform: scale(1.1); /* Slightly scaled up */
    overflow: hidden;
}

.current {
    z-index: 2;
    clip-path: inset(0 0 0 100%); /* Start fully clipped */
    transform: scale(1); /* Slightly scaled down */
    animation: reveal 1s forwards, scale-up 1s forwards;
}

@keyframes reveal {
    0% {
        clip-path: inset(0 0 0 100%);
    }
    100% {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes scale-up {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

.previous:not(.current) {
    animation: scale-down 1s forwards;
}

@keyframes scale-down {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

body {
    overflow-x: hidden;
}

.infiniteCarousel {
    display: flex;
    position: absolute;
    top: 98vh; /* Starts at the same position as the shrunken carousel */
    left: 50%;
    transform: translateX(-50%);
    width: 100vw; /* Width of the shrunken carousel */
    height: 220px; /* Height of the shrunken carousel */
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;
    background-color: black;
    border: 15px solid black;

}

.infiniteImage {
    width: 300px;
    height: 220px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    margin-right: 20px; /* Gap between images */
}
