/* Countdown.css */
.countdown-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;
  font-family: 'Arial', sans-serif;
  margin: 10vh 0 20vh 0;
  scale: 1,
}

h1 {
  font-size: 3rem;
  color: whitesmoke;
  margin-bottom: 20px;
}

.time-display {
  display: flex;
  gap: 20px;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
  color: #388e3c;
}

.time-unit span {
  font-size: 3rem;
  color: #ff0000;
}

.time-unit p {
  margin: 5px 0 0;
  font-size: 1.2rem;
  color: #af4c4c;
}


@media screen and (max-width: 549px) {
  .countdown-container {
    transform: scale(0.5);
  } 
}
