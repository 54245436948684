.merch{
    height: fit-content;
    width: 100vw;
    /* background-color: rgb(0, 0, 0); */
    /* background: linear-gradient(to top, rgb(45, 193, 247),) */
    padding-bottom: 50px;
}

.title{
    font-size: 70px;
    font-family: Montserrat;
    text-align: center;
    color: aliceblue;
    padding-top: 100px;
    
}

.merchBox{
    padding-top: 3%;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    flex-wrap: wrap;
    gap: 40px;
    padding-bottom: 2%;
}

.merchCard{
    height: 334px;
    width: 500px;
    border-radius: 10px;
    /* background-color: rgb(40, 104, 104); */
    background:linear-gradient(to bottom, rgb(255, 255, 255), #000000);
    margin-top: 20px;
    /* border-right: solid 2px #ffffff; */
}

.merchCard:hover{
    transform: scale(1.01);
}

.BSimg{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    
}

.contactUs{
    width: 80vw;
    margin: auto;
    height: fit-content;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: azure;
    gap: 20px;
    padding: 10px;
    margin-top: 20px;
    border: 3px solid rgba(119, 33, 83, 0.874);
    border-radius: 10px;
}

.Name, .no{
    font-size: 28px;
    color: white;
    font-family: Montserrat;
}

.Mtitle{
    font-size: 35px;
    font-family: Montserrat;
    padding-bottom: 5px;
    border-bottom: solid 2px #fff;
}


@media(max-width:640px){
    .merchCard{
        width: 450px;
        height: 300px;
    }
}

@media(max-width:540px){
    .merchCard{
        width: 400px;
        height: 267px;
    }
    .title{
        font-size: 50px;
    }
    
}

@media(max-width:440px){
    .merchCard{
        width: 300px;
        height: 200px;
    }
    .Mtitle{
        font-size: 25px;
    }
    .Name, .no{
        font-size: 18px;
    }
    .contactUs{
        gap: 10px;
    }
}

@media(max-width:340px){
    .merchCard{
        width: 230px;
        height: 154px;
    }
}



