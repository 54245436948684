.dissolveTeam .dis_team {
    background-image: url('../../static/card/dissolve1.png');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 200px;
    height: 50px;
}
.dissolveTeam:hover .dis_team{
    background-image: url("../../static/card/dissolve2.png");
}

.dissolveTeam .add_team {
    background-image: url('../../static/card/addpart.png');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 200px;
    height: 50px;
}
.dissolveTeam:hover .add_team{
    background-image: url("../../static/card/addpart2.png");
}

.dissolveTeam .dis_rect1 {
    height: 40px;
    width: 200px;
    border: 1px solid #fff;
    position: absolute;
}

.dissolveTeam .dis_rect2 {
    
    height: 50px;
    width: 200px;
    /* border: 1px solid #fff; */
    position: absolute;
    /* color: black; */
    font-family: 'Forum', sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
    z-index: -1;
    opacity: 0;
    /* z-index: 500; */
}

.dis_team2:hover{
    background-image: url("../../static/card/dissolve2.png");
}

.dissolveTeam .add_rect2 {
    /* background-image: url('../../static/card/dissolve2.png'); */
    background-position: center;
    background-size: cover;
    height: 50px;
    width: 200px;
    /* border: 1px solid #fff; */
    margin-left: 16px;
    margin-top: 7px;
    position: absolute;
    color: black;
    font-family: 'Forum', sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
}

.joinsubmit {
    background: transparent;
    color: black;
    border: 1px white solid;
    margin: 10px;
    padding: 2px 5px;
    border-radius: 10px;
}

.jointeaminput {
    background: rgba(0, 0, 0, .5);
    color: white;
    border: 2px white solid;
    border-radius: 10px;
    margin: 10px;
    padding: 2px 5px;
    text-align: center;
}

.singleparti input {
    width: max-content;
    margin: 2px 0 2px 10px;
}

@media (max-width: 577px) {
    .dissolveTeam {
        height: 60px;
        display: flex;
        justify-content: center;
    }
    .jonojono{
        margin-top: 60px;
    }
}