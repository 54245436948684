.groupl{
    display: flex;
}
#wabuttonw{
    /* border: solid white 1px; */
    /* background-color: #0c8639; */
    background-image: url("./assets/whatsapp_button.png");
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    margin-right: 0.5vw;
    padding: 0.5vw;
    font-family: "Forum", sans-serif;
    color: white;
    width: 200px;
    height: 53px;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 2px;
    display: flex;
    background-repeat: no-repeat;
}

#wabuttonw:hover{
    background-image: url("./assets/whatsapp_button_pressed.png");
}
#wabuttond{
    border: solid white 1px;
    background-color: #7289da;
    margin-left: 0.5vw;
    border-radius: 5px;
    padding: 0.5vw;
    font-family: "Forum", sans-serif;
    color: white;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 2px;
}

@media (max-width: 820px)
{
    #wabuttond{
        padding: 2%;
    }
    #wabuttonw{
        padding: 2%;
    }
}