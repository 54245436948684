.main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100px;
    width: 100vw;
    /* background-color: #f3e5ab; */
    background-color: #5b7c99;
}

.main .right {
    display: flex;
    justify-content: center;
    margin: 2rem;
    font-size: 20px;
}

.main .right .option {
    margin: 2rem;
}

.option .line {
    height: 3px;
    background-color: #000f89;
    width: 0;
    transition: 0.2s ease-in-out;
}
.option:hover {
    .line {
        width: 100%;
    }
}

.left img {
    /* height: 70%; */
    margin: 3rem;
}