.world-map-container {
  position: relative;
}

.mapimage {
  width: 100%;
  height: auto;
}

.city-circle {
  position: absolute;
  width: 1.1rem;
  height: 1.1rem;
  background-color: whitesmoke;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2; 
  animation: ripple 2s infinite ease-in-out;
  transition: opacity 0.3s ease-in-out;
} 



@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
