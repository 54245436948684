/* General Styling */
.betting {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 20px auto;
  max-width: 90vw;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #09102d;
  border-radius: 8px;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.bettingcard1,
.bettingcard2 {
  display: flex;
  flex-direction: row;
}

.bettingcard2 {
  padding: 0 3rem;
}

.bettingcard3 {
  display: flex;
  width: 330px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.bettingcard3 > div {
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
  box-sizing: border-box;
}

/* Team Container */
.teamcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.teamlogo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #4caf50;
}

.teamname {
  margin-top: 8px;
  font-weight: bold;
  font-size: 1rem;
  color: #ffffff;
}

/* VS Container */
.vscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 15px 0;
  color: #ffffff;
}

.matchtime {
  margin-left: 10px;
  font-weight: bold;
  color: #cccccc;
}

/* Betting Info */
.bettinginfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.betamountcontainer,
.betpricecontainer {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.betbutton {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.betbutton:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Match Card Styling */
.match-card-container {
  max-width: 300px;
  margin: 20px auto;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #0f172a;
  color: #ffffff;
}

.match-card-header {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center;
  color: #e2e8f0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.match-card-team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.match-card-team-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #4caf50;
}

.match-card-team-name {
  margin-top: 10px;
  font-weight: bold;
  color: #e2e8f0;
}

.match-card-info {
  margin-bottom: 15px;
  text-align: center;
}

.match-card-update-section {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
}

.match-card-update-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.match-card-update-button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
}

.match-card-update-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Blinking Text */
.blinkingText {
  color: red;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.7);
  animation: blink 1s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Responsive Design */
@media (max-width: 450px) {
  .betting {
    width: 95vw;
  }
}

@media screen and (max-width: 368px) {
  .bettingcard3 {
    flex-direction: row;
    align-items: center;
  }

  .match-card-team-container {
    flex-direction: column;
  }

  .match-card-team-image {
    margin-right: 0;
  }
}
