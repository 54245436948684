.contactus {
    position: relative;
    padding: 50px;
    padding-left: 0px;
    height: 100vh; /* Ensure the container has a height */
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
     /* Ensure text is visible against the background */  
     flex-wrap: wrap;   
     overflow: auto; 
}
.heading {
    text-align: center;
    font-size: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add text shadow for better readability */
    top:5vh;
    position: relative;
    color: white;
    font-family: Bruno Ace,sans-serif;
    font-weight: 700;
    padding-left: 50px;
    padding-bottom: 50px;
  }

.card{
    height: 10rem;
    width: 20rem;
    position: relative;
    scale: 0.8;
    margin: 1rem 2rem;

    img{
        height: 8.5rem;
        width: 8.5rem;
        z-index: 3;
        border-radius: 50%;
        transition: 1s ease-in-out, transform 0.5s;
        object-fit: cover;
        border: 3px solid #a2f8c5;
    }

    .info{
        position: absolute;
        bottom: 0;
        right: 0;
        height: 6rem;
        width: 16rem;
        background: linear-gradient(135deg,rgba(255,255,255,0.1),rgba(255,255,255,0));
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter:blur(10px); */
        background-color: #212733;
        opacity: 1;
    /* border-radius: 5px; */
    border: 3px solid #a2f8c5;
    /* box-shadow: 0 8px 32px 0 rgba(0,0,0,0.37); */
        clip-path: polygon(5rem 0, 16rem 0, 16rem 6rem, 0 6rem, 0 5rem,0.05rem 4.99975rem, 0.1rem 4.999rem, 0.15rem 4.99775rem, 0.2rem 4.996rem, 0.25rem 4.99375rem, 0.3rem 4.99099rem, 0.35rem 4.98773rem, 0.4rem 4.98397rem, 0.45rem 4.97971rem, 0.5rem 4.97494rem, 0.55rem 4.96966rem, 0.6rem 4.96387rem, 0.65rem 4.95757rem, 0.7rem 4.95076rem, 0.75rem 4.94343rem, 0.8rem 4.93559rem, 0.85rem 4.92722rem, 0.9rem 4.91833rem, 0.95rem 4.90892rem, 1rem 4.89898rem, 1.05rem 4.88851rem, 1.1rem 4.8775rem, 1.15rem 4.86595rem, 1.2rem 4.85386rem, 1.25rem 4.84123rem, 1.3rem 4.82804rem, 1.35rem 4.8143rem, 1.4rem 4.8rem, 1.45rem 4.78513rem, 1.5rem 4.7697rem, 1.55rem 4.75368rem, 1.6rem 4.73709rem, 1.65rem 4.7199rem, 1.7rem 4.70213rem, 1.75rem 4.68375rem, 1.8rem 4.66476rem, 1.85rem 4.64516rem, 1.9rem 4.62493rem, 1.95rem 4.60407rem, 2rem 4.58258rem, 2.05rem 4.56043rem, 2.1rem 4.53762rem, 2.15rem 4.51414rem, 2.2rem 4.48999rem, 2.25rem 4.46514rem, 2.3rem 4.4396rem, 2.35rem 4.41333rem, 2.4rem 4.38634rem, 2.45rem 4.35861rem, 2.5rem 4.33013rem, 2.55rem 4.30087rem, 2.6rem 4.27083rem, 2.65rem 4.23999rem, 2.7rem 4.20833rem, 2.75rem 4.17582rem, 2.8rem 4.14246rem, 2.85rem 4.10822rem, 2.9rem 4.07308rem, 2.95rem 4.03702rem, 3rem 4rem, 3.05rem 3.96201rem, 3.1rem 3.92301rem, 3.15rem 3.88298rem, 3.2rem 3.84188rem, 3.25rem 3.79967rem, 3.3rem 3.75633rem, 3.35rem 3.71181rem, 3.4rem 3.66606rem, 3.45rem 3.61905rem, 3.5rem 3.57072rem, 3.55rem 3.52101rem, 3.6rem 3.46987rem, 3.65rem 3.41724rem, 3.7rem 3.36304rem, 3.75rem 3.30719rem, 3.8rem 3.24962rem, 3.85rem 3.19022rem, 3.9rem 3.1289rem, 3.95rem 3.06554rem, 4rem 3rem, 4.05rem 2.93215rem, 4.1rem 2.86182rem, 4.15rem 2.78882rem, 4.2rem 2.71294rem, 4.25rem 2.63392rem, 4.3rem 2.55147rem, 4.35rem 2.46526rem, 4.4rem 2.37487rem, 4.45rem 2.2798rem, 4.5rem 2.17945rem, 4.55rem 2.07304rem, 4.6rem 1.95959rem, 4.65rem 1.8378rem, 4.7rem 1.70587rem, 4.75rem 1.56125rem, 4.8rem 1.4rem, 4.85rem 1.21552rem, 4.9rem 0.994984rem, 4.95rem 0.705331rem, 5rem 0);
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
        transition: 0.5s ease-in-out ,transform 1s;
    }

    .text{
        position: absolute;
        top: 0;
        margin: 1rem 1rem;
        color: white;
        font-size: 17px;
        font-family:'Times New Roman', Times, serif;
        font-weight: 600;
        left: 20%;
        p{
            width: 100%;
            text-align: center;
        }
    }

    .hidden{
        opacity: 0;
        transition-delay: 1s;
        transition: 1s ease-in-out;
    }

    transition: 0.5s ease-in-out;
}



.card:hover{
    height: 17rem;

    img{
        height: 6rem;
        width: 6rem;
        position: absolute;
        transform: translateX(9rem);
    }

    .info{
        height: 16rem;
        clip-path: polygon(0 0, 16rem 0, 16rem 16rem, 0 16rem, 0 5rem);
    }

    .text{
        margin-top: 5.5rem;
        margin-right: 3.5rem;
        position: absolute;
        left: 15%;
    }

    .hidden{
        opacity: 1;
    }
}

.postsections{
    display: grid;
    grid-template-columns: repeat(3,minmax(250px,1fr));
    /* flex-direction: row; */
    /* margin: 1rem; */
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.postsectionsoc{
    /* display: grid;
    grid-template-columns: repeat(2,minmax(250px,1fr)); */
    /* flex-direction: row; */
    /* margin: 1rem; */
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}

.office {
    display: flex;
    justify-content: space-around;
    border:3px solid #a2f8c5;
    margin: 10vh -3vh 10vh 5vh;
    font-size: 21px;
    border-radius: 10px;
    height: 100pt;
    align-items: center;
    color: white;
    font-weight: 900;
    background-color:rgba(0,0, 0, 0.7);
    width: 90vw;
    font-family:'Times New Roman', Times, serif;
}

@media (max-width:1100px) {
    .postsections{
        grid-template-columns: repeat(2,minmax(250px,1fr));
    }
}

@media (max-width:850px) {
    .postsections{
        grid-template-columns: repeat(1,minmax(250px,1fr));
    }
    .postsectionsoc{
        display: grid;
        grid-template-columns: repeat(1,minmax(250px,1fr));
        /* flex-direction: row; */
        /* margin: 1rem; */
        width: 100vw;
        justify-content: center;
        align-items: center;
    }
}
