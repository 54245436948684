.compi_bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("static/img/compi_bg4.jpeg");
    /* background-color: black; */
    z-index: -100;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: white; */

}

.Contactbox{
    display: flex;
    justify-content: space-around;
    /* flex-basis: 1.2em; */
    border:3px solid #a2f8c5;
    margin: 7vw;
    font-size: 20px;
    border-radius: 10px;
    height: 100pt;
    align-items: center;
    color: white;
    font-family:'Times New Roman', Times, serif;
    font-weight: 900;
    background-color:rgba(0,0, 0, 0.7);
    width: 85vw;
    }
    @media(max-width: 750px) {
        .Contactbox{
            height: 300px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 20px;
            font-size: 20px;
        }
        
    }
.gap{
    margin-top: 200px;
    margin-bottom: 0px;
}
@media(max-width:748px){
    .gap{
        margin-top: 70px;
    }
}

.frame{
    background-color: black;
    width: 100%;
    height: 100vh;
    position:relative;
    top: -50px;

}

.frame .shade{
    width: 100%;
    height: 100%;
    background: url("static/img/img4.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}


.frame .compi_background{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("./static/img/img8.jpg");
    animation: compi_loading_base 6s infinite ease-in;
    /* background-position: fixed; */
}

.frame .tittle{
    position: absolute;
    bottom: 20px;
    width: min(100vh,100%);
    z-index: auto;
    /* animation: compi_loading_tittle 6s infinite ease-in-out; */
}
@media(max-width: 768px)
{
    .frame .tittle{
        bottom: 69px;   
    }
}

.frame .svg{
    width: 40vh;
    position: absolute;
    z-index: 2;
    animation: compi_loading_svg 6s infinite ease-in-out;
    transition: smooth;
}

@keyframes compi_loading_tittle{
    0%,100%{opacity: 1;}
    30%,70%{opacity: 0;}
}

@keyframes compi_loading_base{
    0%,100%{background-image: url("static/img/img8.jpg");}
    35%{background-image: url("static/img/img6.jpg");}
    70%{background-image: url("static/img/img7.jpg");}
}

@keyframes compi_loading_svg{
    0%,100%{opacity: 1;}
    30%,70%{opacity: 0;}
}

.blackGradient-to-top::after{
    content: '';
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 150px; /* Adjust the height of the gradient */
    background: linear-gradient(to top,transparent, rgba(0, 0, 0, .6 ), rgba(0, 0, 0, 1));
    pointer-events: none;
  }

.blackGradient-to-bottom{
    content: '';
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    /* height: 50px; Adjust the height of the gradient */
    background: linear-gradient(to top,rgba(0, 0, 0, 1), rgba(0, 0, 0, .6),transparent);
    pointer-events: none;
}
