.Technorion {
  font-family: 'Forum', sans-serif;
  /* margin-top: 60px;
  padding-top: 100px; */
}

.Pulse2 {
  position: absolute;
  top: 0;
  width: 28vw;
  height: 50vw;
  right: 0;
}

.Pulse1 {
  position: absolute;
  top: 6vw;
  width: 33vw;
  height: 50vw;
}

.dots1 {
  position: absolute;
  top: 70px;
  width: 33vw;
  height: auto;
  right: 0;
  animation: dot-animation1 5s infinite linear;
}

.dots2 {
  position: absolute;
  top: 70px;
  width: 33vw;
  height: auto;
  right: 0;
  animation: dot-animation2 5s infinite linear;
}



@keyframes dot-animation1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot-animation2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.middle {
  height: 100vh;
  width: 33vw;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  background: #1E262A;
}

.middle img {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

}

.gridimage {
  width: 100vw;
  height: auto;
  z-index: 3;
  transform: translateY(93%);
  position: absolute;
}

.trophyimage {
  position: absolute;
  transform: translateY(-5%) scale(1.3);
  z-index: 4;
  top: 20%;
  animation: trophy-animation 2s linear;
}

.compiTextdiv {
  width: 450px;
  position: absolute;
  top: 75%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compiTextdiv img {
  width: 50%;
  top: 70%;
  position: relative;
}

@media(max-width:2561px) {
  .trophyimage {
    top: 25%;
  }

  .compiTextdiv {
    width: 600px;
  }
}

@media(max-width:1441px) {
  .compiTextdiv {
    top: 70%;
  }

  .trophyimage {
    top: 30%;
  }

}

@media(max-width:1025px) {
  .compiTextdiv {
    width: 400px;
    top: 70%;
  }

  .trophyimage {
    top: 30%;
  }
}

@media (max-width:426px) {
  .compiTextdiv {
    width: 150px;
  }
}

@media(max-width:321px) {
  .compiTextdiv {
    width: 130px;
  }
}


@keyframes trophy-animation {
  0% {
    top: -50%;
  }

  100% {
    top: 20%;
  }
}

.linesimage {
  position: absolute;
  z-index: 0;
  transform: translateY(-30%);
}

.wiresimage {
  position: absolute;
  z-index: 2;
  scale: 1.5;
  transform: translateY(-10%);
}



.rightimage {
  position: absolute;
  z-index: 0;
  transform: translateX(120%) translateY(-10%);
  width: 33vw;
  top: 0;
}

.firstView {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background: #1E262A;
}

.left {
  position: relative;
  height: 100vh;
  width: 33vw;
  /* display: flex; */
  background: #1E262A;
  /* background-color: #1e262a; */

}






/*================Map + Zonals========================================================*/
.desc {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  border: 1px solid #a2f8c5;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: auto;
  width: 80%;
  margin-bottom: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
}

tr {
  color: #a2f8c5;
}

.noReg {
  margin: auto;
  width: 92%;
  height: 88%;
  background-size: contain;
  text-align: left;
}

a {
  text-decoration: none;
  border-radius: 15px;
}

.noReg a h3 {
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin-bottom: 8px
}

.noReg a p {
  color: white;
  text-decoration: none;
  line-height: 1.4;
  margin-bottom: 3px;
}

.desc_cont {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
}

.mapContainer {
  width: 40%;
}

.descimage {
  width: 120%;
  margin-left: -15%;
}

.descT {
  font-family: 'Forum', sans-serif;
  font-size: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #ffffff;
  padding: 20px 0 15px 20px;
  margin-bottom: 0px;
}

.textContainer table {
  width: 80%;
  margin: 10px auto;
  border: none;
}

.textContainer th {
  border-bottom: 1px solid #BB9564;
}

.textContainer th,
.textContainer td {
  padding: 5px;
  font-size: 18px;
  text-align: left;
}

@media screen and (max-width: 920px) {
  .desc {
    flex-direction: column;
    width: 95%;
    height: 140%;
  }

  .descT {
    font-size: 14px;
  }

  .textContainer th,
  .textContainer td {
    font-size: 13px;
  }

  .desc_cont {
    width: 100%;
  }

  .mapContainer {
    width: 70%;
    margin: 0 auto;
  }
}

/*================Cards=============================================================*/

.technorion_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.zonalhead {
  width: 100vw;
  /* background: green; */
  height: 50px;
  position: relative;
  /* margin-right: 50vw; */
  margin-top: 100px;
  margin-bottom: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.zonalhead img {
  width: 30%;
}

@media (max-width:600px) {
  .zonalhead img {
    width: 80%;
  }
}

.zonals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 50px; */
}

@media (max-width:900px) {
  .zonals {
    flex-direction: column;
  }
}

.techno {
  width: 40vw;
  border: 1px solid white;
  height: 50vw;
  position: relative;
  background: purple;
}

.zonalcards {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 300px));
  gap: 60px;
  width: 50%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
  background: red;
}


.cardGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 300px));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_img_container {
  position: absolute;
  width: 95%;
  left: 2%;
  top: 23%;
  /* height: 100%; */
  overflow: hidden;
  /* Ensure no overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Center the image */
  transition: all 0.4s ease-in-out;
  /* Smooth height transition */
  /* margin-top: 100px; */
  background-repeat: no-repeat;
  background-size: contain;
  /* Changed from cover to contain */
  background-position: center;
  /* Center the image */
  /* clip-path: polygon(0% 0%,5% 8%,25% 8%, 90% 0%, 100% 14%, 92% 14%, 92% 20%, 100% 20%, 100% 100%, 0% 100%, 0% 50%, 8% 50%, 8% 44%, 0% 44%, 0% 0%); */

}


.card_img {
  width: 100%;
  /* aspect-ratio: 0.817/0.96; */
  /* object-fit: fill; */
  opacity: 1;
  transition: all 1s ease-in;
  display: flex;
  justify-content: center;
  /* background: black; */
  /* margin-top: 250px; */
}

.compi_card .card_img_container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
  /* Ensure the gradient is above the image */
  clip-path: polygon(5% 0%, 12% 6%, 37% 6%, 45% 2%, 78% 2%, 81% 0%, 95% 0%, 100% 3%, 100% 88%, 95% 96%, 66% 96%, 60% 101%, 45% 101%, 41% 96%, 29% 96%, 23% 100%, 12% 100%, 0% 87%, 0% 54%, 6% 46%);

}

.compi_card:hover .card_img_container::before {
  opacity: 1;
  /* Show the gradient on hover */
}

.compi_card {
  position: relative;
  background-image: url('../Workshop/img/cardbox.png');
  font-family: 'Forum', sans-serif;
  padding: 10px;
  transform: scale(1);
  transition: all 0.4s ease-in-out;
  aspect-ratio: 135/207;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  /* Changed from cover to contain */
  background-position: center;
  /* Center the image */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

/* .leaf {
  position: absolute;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  border-radius: 25px;
  width: 69px;
  height: 69px;
  top: -14px;
  right: -14px;
  border-radius: 0px 100px;
  border: 11px solid white;
  z-index: 500;
} */

.card_tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_tag h3 {
  font-family: 'Bruno Ace', sans-serif;
  /* font-size: 120%; */
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: left;
  color: white;
  text-transform: uppercase;
  padding: 12px;
  margin-bottom: 0px;
  /* padding-bottom: 20px; */
  position: absolute;

}

.card_regexp {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  background-image: url('../Workshop/img/regexp1.png');
  background-repeat: no-repeat;
  background-size: contain;
  /* Changed from cover to contain */
  background-position: center;
  /* Center the image */
  /* margin-top: 100px; */
  justify-content: center;
  left: 50%;
  top: 90%;
  transform: translate(-50%);
  /* margin-right: 30%; */
}

.card_title {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 30%;
  width: 100%;
  transition: 1s ease;
  /* border: 1px solid red; */
  transition: top 1s ease;
  color: white;
}

.compi_card:hover .card_title {
  top: 0px;
}

.card_title p {
  opacity: 0;
  transition: opacity 1s ease;
}

.compi_card:hover .card_title p {
  opacity: 1;
}


.card_title h3 {
  color: #a2f8c5;
  font-family: 'Bruno Ace', sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  bottom: -102px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  position: relative;
  /* margin-left: 50%; */
  z-index: 999;
  /* margin-left: 110%; */
}

.card_title p {
  color: white;
  z-index: 999;
  margin-left: 50%;
}

.card_desc {
  color: #a2f8c5;
  font-size: 1px;
  position: relative;
  display: none;
  margin-top: 10px;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.compi_card:hover .card_desc {
  font-size: 10px;
  position: relative;
  display: flex;
  bottom: -90px;
  justify-content: center;
  width: 100%;
  text-align: center;
  align-items: center;
  text-transform: capitalize;
  color: #a2f8c5;
  z-index: 2
}

.card_sustitle {
  font-size: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -100px;
  align-items: center;
  scale: 0.9;
  color: white;
  margin-left: 34%;
  z-index: 999;
}

/* .compi_card:hover .card_sustitle {
  font-size: 18px;
  font-weight: 900;
  position: relative;
  display: flex;
  margin-left: 0;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
  scale: 1;
  z-index: 9999;
  color: white;
} */
.card_headtitle {
  font-size: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
  /* background: #8d8d8d; */
  margin-top: 8px;
  display: none;
  border-radius: 0px 0px 20px 20px;
  margin-left: 40%;
}

.compi_card:hover .card_headtitle {
  font-size: 15px;
  position: relative;
  display: none;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
  color: white;
  display: flex;
  z-index: 999;
}

.card_taggy {
  font-size: 20px;
  position: relative;
  display: none;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
  margin-top: 8px;
  z-index: 999;
  margin-left: 40%;
}

.compi_card:hover .card_taggy {
  font-size: 22px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
}

.card_post {
  opacity: 0;
  transition: opacity 0.1s linear 0s;
  width: 80%;
  margin: 0 auto 0 auto;
}

/* .card_regexp {
  position: absolute;
  left: 8%;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  top: 88%;
  height: 100%;
} */


.card_reg,
.card_exp {
  border-radius: 30px;
  color: white;
  font-size: 15px;
  padding: 5px;
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

}


.card_reg {

  display: flex;
  font-family: 'Bruno Ace', sans-serif;
  font-weight: 700;
  padding-left: 30px;
  /* margin-top: 300px; */
}

.card_exp {

  opacity: 1;
  /* margin-top: 300px; */
  justify-content: center;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* Changed from coer to contain */
  background-position: center;
  /* Center the image */
  width: auto;
}

.card_reg a,
.card_reg button {
  color: white;
  font-family: 'Bruno Ace', sans-serif;
  letter-spacing: 0.1em;
  font-size: 15px;
  padding: 15px;
  /* padding-left: 35px; */
  padding-right: 0;
  /* padding-bottom: 20px; */
  text-decoration: none;
  background: none;
  border: none;
  font-weight: 700;
}

.card_exp a {
  color: white;
  font-family: 'Bruno Ace', sans-serif;
  letter-spacing: 0.1em;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-right: 35px;
  padding-left: 0;
  /* padding-bottom: 20px; */
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 700;
}

/* .compi_card:hover {
  transform: scale(1);
  transition: transform 0.3s ease;
} */

/* .compi_card:hover .card_img_container {
  transform: aspect ratio 4.206/1; 
  transition: all 0.4s ease-in-out;
}
.compi_card:hover .card_img {
  aspect-ratio: 4.206/1;

  transition: aspect-ratio 0.4s ease-in-out, opacity 0.4s ease, scale 0.5s ease-in-out;
  scale: 1.2;
  
  
  opacity: 0;
}

.compi_card:hover .card_reg{
  display: flex;
  aspect-ratio: 4.206/1;
  width: 100%;
  height: 100%;
  top:-165px;
  position: relative;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.compi_card:hover .card_exp{
  opacity: 1;
  aspect-ratio: 3.23/1;
  width: 60%;
  height: 100%;
  top: -160px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  position: relative;
  background: black;
  color: white;
}

.compi_card:hover .espark4,
.compi_card:hover .espark1,
.compi_card:hover .fspark4,
.compi_card:hover .fspark1 {
  opacity: 0;
} */

.compi_card:hover .card_post {
  opacity: 1;
  transition: opacity 0.1s linear 0.3s;
}

.compi_card:hover .card_title {
  /* margin-top: -200px; */
}

/* .compi_card:hover .card_title h3{
  margin-top:0px;
  opacity: 1;
  display: flex;

} */



/* .card_share {
  position: absolute;
  left: 37%;
  bottom: 18%;
} */

.card_rect1,
.card_rect2 {
  position: absolute;
}

.card_rect1 {
  width: 86%;
  height: 97%;
  left: 7.5%;
  top: 5px;
}

.card_rect2 {
  left: 5.5%;
  width: 90%;
  height: 95%;
}

.share_rect1 {
  height: 35px;
  width: 100px;
  border: 1px solid #BB9564;
  position: relative;
}

.share_rect2 {
  height: 35px;
  width: 100px;
  border: 1px solid #BB9564;
  margin-left: 3px;
  margin-top: 3px;
  position: relative;
  font-size: 24px;
  font-weight: 200;
  font-family: 'Forum', sans-serif;
  color: #BB9564;
}

.share_rect2:hover {
  color: black;
}

.indiamapContainer {
  display: flex;
  align-items: center;
  height: 300px;
  margin: 20px auto;
}

.imageContainer {
  flex: auto;
  margin-left: 30px;
  padding: 10px;
  box-sizing: content-box;
  max-width: 50%;
}

.imageContainer img {
  max-width: 50%;
  max-height: 50%;
  width: auto;
  height: auto;
  border: 1px solid #BB9564;
  object-fit: contain;
}

.textContainer {
  flex: 1;
  margin-right: 20px;
}

.textContainer p {
  margin: 0;
  font-size: 18px;
  font-family: 'Forum', sans-serif;
}

.chand {
  position: absolute;
  right: 9%;
  bottom: 55%;
  height: 20px;
  width: auto;
}

.espark,
.espark2,
.espark3,
.espark4,
.fspark,
.fspark2,
.fspark3,
.fspark4 {
  position: absolute;
  height: 5px;
  width: auto;
}

.espark {
  left: 15.5%;
  bottom: 25%;
}

.espark2 {
  left: 12%;
  bottom: 89%;
  height: 25px;
}

.espark3 {
  left: 88%;
  bottom: 90%;
}

.espark4 {
  left: 79%;
  bottom: 20%;
  height: 25px;
}

.fspark {
  left: 18%;
  bottom: 20%;
  height: 20px;
}

.fspark2 {
  left: 10%;
  bottom: 85%;
  height: 20px;
}

.fspark3 {
  left: 86%;
  bottom: 23%;
  height: 20px;
}

.fspark4 {
  left: 83%;
  bottom: 90%;
  height: 20px;
}

.fspark4:hover {
  opacity: 0;
}

/* Add hover styles for card_reg and card_exp */
/* .card_reg:hover a,
.card_exp:hover a {
  color: #A7E9FF;
} */

/* For Media Queries */
@media (max-width: 768px) {
  /* .indiamapContainer {
    flex-direction: column-reverse;
    height: auto;
  }
  
  .imageContainer,
  .textContainer {
    margin: 0;
  }

  .textContainer p {
    font-size: 16px;
  } */

}

@media (max-width:1108px) {
  .cardGrid {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 270px));
  }
}


@media (max-width:882px) {
  .cardGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 300px));
  }
}

@media screen and (max-width: 576px) {

  /* .compi_card:hover {
    transition: none;
    transform: scale(1);
  }

  .compi_card:hover .card_img {
    opacity: 1;
  }

  .compi_card:hover .card_post {
    display: none;
  }

  .compi_card:hover .card_title {
    margin-top: 0px;
  } */
  .cardGrid {
    grid-template-columns: repeat(1, minmax(250px, 300px));
  }
}

/* @media screen and (max-width: 1400px) {
  .card_title h3 {
    font-size: 15px;
  }

  .card_sustitle {
    font-size: 13px;
  }

  .card_img {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
  }
} */

@media screen and (max-width: 1200px) {
  /* .card_title h3 {
    font-size: 15px;
  }

  .card_sustitle {
    font-size: 14px;
  }

  .card_img {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    border-radius: 30px;
  } */
}

@media screen and (max-width: 1100px) {
  /* .card_title h3 {
    position: relative;
    top: -2px;
    font-size: 15px;
  }

  .card_sustitle {
    position: relative;
    top: -2px;
    font-size: 12px;
  }

  .card_img {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
  } */
}

/* 
@media screen and (max-width: 768px) {
  /* .card_title h3 {
    top: 10px;
    font-size: 20px;
  }

  .card_sustitle {
    top: 20px;
    font-size: 15px;
  }

  .card_img {
    width: 100%;
  } */
/*} */

@media screen and (max-width: 675px) {
  /* .card_title h3 {
    top: 0px;
    font-size: 15px;
  }

  .card_sustitle {
    top: 0px;
    font-size: 15px;
  }

  .card_img {
    height: 70%;
  } */
}

.card_title div {
  color: white;
  width: 5rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-bottom: 0px;
}

/* 
@media screen and (max-width: 576px) {
  /* .card_title h3 {
    top: 0px;
    font-size: 25px;
  }

  .card_sustitle {
    top: 0px;
    font-size: 20px;
  }

  .card_img {
    height: 70%;
  }

  .compi_card {
    aspect-ratio: 1/1.25;
  } */
/*} */

@media(max-width: 430px) {
  /* .card_title h3 {
    top: 0px;
    font-size: 20px;
  }

  .card_sustitle {
    top: 0px;
    font-size: 15px;
  }

  .compi_card {
    aspect-ratio: 1/1.4;
  } */

  .trophyimage {

    position: absolute;
    transform: translateY(0%) scale(0.3);
    z-index: 4;
    top: 10%;
    animation: trophy-animation 2s linear;
  }

  /* .trophyimage img{
    height: 150px;
    width: 70px;
  } */
  @keyframes trophy-animation {
    0% {
      top: -50%;
    }

    100% {
      top: 10%;
    }
  }

  .compiTextdiv {
    position: absolute;
    top: 110%;
    z-index: 4;
    display: flex;
    scale: 2.5;

  }



}

@media (max-width:600px) {
  .dots1 {
    display: none;
  }

  .dots2 {
    display: none;
  }

  .rightimage {
    display: none;
  }

  .firstView {
    justify-content: center;
    align-items: center;
    height: 40vh;
    /* height: 300px; */
  }

  .middle {
    height: 40vh;
  }

  .left {
    display: none;
  }

  .trophyimage {
    /* scale: 6; */
    width: 100vw;
    /* max-width: 0px; */
    transform: translateY(-8%);
  }

  .linesimage {
    /* scale: 3; */
    transform: translateY(-50%);
  }

  .gridimage {
    /* scale: 6; */
    /* overflow: hidden; */
    transform: translateY(200%);
    /* height: 300px; */
  }

  .wiresimage {
    width: 200%;
    transform: translateY(10%);
  }
}

@media (max-width:500px) {}

/*==================Contact Box ==================*/
.Contactbox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* flex-basis: 1.2em; */
  border: 3px solid #a2f8c5;
  margin: 7vw;
  font-size: 18px;
  border-radius: 10px;
  height: fit-content;
  align-items: center;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 900;
  background-color: rgba(0, 0, 0, 0.7);
  width: 85vw;
  padding: 4vh;
  text-align: center;
}

.Contactbox1 {
  margin: 1.5vh;
}