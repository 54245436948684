@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


.bgmi {
    color: whitesmoke;
    background-color: rgba(0, 0, 0, 0);
    
}

.bgVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}

.bgmi_land {
    width: 100vw;
    height: auto;
}

.hero{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head {
    font-family: "Marcellus SC", serif;
    font-size: 5vh;
    /* margin: 3vh 0 5vh 1vw; */
    font-weight: bold;
    text-align: center;
    margin-top: 5vh;
    padding: 2vh;
    background-color: rgb(22, 202, 142);
    color: black;

}

.brief {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3vh;
    margin: 5vh 1vw 10vh 1vw;
    text-align: center;
}

/* sponsor */
.sponsor {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1vw 0 1vw;
}

.sponsor_head {
    font-family: "Marcellus SC", serif;
    font-size: 4vh;
    width: 20vw;
}

.sponsor_images {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    padding: 0 20vw 0 0;
}

.image {
    margin: 0 5vw 0 5vw;
}

/* Prize Pool */
.prizepool {
    margin: 0 1vw 0 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prizepool_head {
    font-family: "Marcellus SC", serif;
    font-size: 5vh;
    width: 20vw;
    margin: 5vh 0 -10vh 0;
    text-align: center;
}

/* Register */
.reg_btn {
    font-size: 5vh;
    display: flex;
    justify-content: center;
}

button {
    padding: 5px 20px 5px 20px;
}
.reg{
    width:100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    /* border: 2px solid black; */

}
.regbtn{
    height: fit-content;
    width: fit-content;
    padding: 15px;
    border-radius: 10px;
    background-color: aquamarine;
    color: black;
    font-size: 25px;
    font-weight: 600;
    font-family: "Marcellus SC", serif;
    border: 2px solid black;
}

.regbtn:hover{
    transform: scale(1.1);
    cursor: pointer;

}

/* box */
.box {
    height: fit-content;
    width: 90vw;
    margin: 10vh 5vw 20vh 5vw;
    border: 2px solid rgb(190, 188, 186);
    padding: 0 24px;
    padding-top: 12px;
    z-index: 2;
    background: linear-gradient(to bottom, rgba(28, 32, 27, 0.749),#3f9476e9);
    /* backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px); */

  }
  .tabs {
    display: flex;
    border-bottom: 1px solid #c6c6c6;
    justify-content: center;
    height: 50px;
    align-items: center;
    font-size: 25px;
    gap: 10px;
    text-align: center;
    
    /* padding-bottom: 20px; */
  }
  .tabButton {
    padding: 8px 16px;
    border-radius: 0;
    border: none;
    background: transparent;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    color: #b3b2b2;
    font-family: "Marcellus SC", serif;
  }

  .tabButton:hover {
    cursor: pointer;
    border-bottom: 3px solid #e0e7ef;
    color: #e0e7ef;
  }
  .tabButton.active {
    border-bottom: 3px solid #e0e7ef;
    color: #ffffff;
    font-weight: 700;
  }
  .tabPage {
    padding: 24px 16px 16px 16px;
    display: none;
  }
  .tabPage.active {
    display: block;
  }

  .Details li, .Timeline li{
     font-size: 20px;
     margin: 10px;
     font-family: "Marcellus SC", serif;
    
  }
   
  .managers{
    display: flex;
    justify-content: space-around;
  }

  .hardik, .ankit{
    padding: 15px;
    border: 2px solid #c4c3c3
    }

    

  .hardik div, .ankit div{
    font-family: "Marcellus SC", serif;
    font-size: 20px;
    text-align: center;
  }

  @media(max-width:920px){
    .tabs{
        font-size: 18px;
    }
  }

  @media(max-width:740px){
    .tabs{
        font-size: 16px ;
        gap: 0;
    }
    .Details li, .Timeline li{
        font-size: 15px;
    }

  }

  @media(max-width:560px){
    .tabs{
        font-size: 15px ;
        padding-bottom: 10px;
        margin-bottom: 7px;
    }
    .managers{
        margin: 20px;
    }
    .Details li, .Timeline li{
        font-size: 15px;
        margin: 0;
    }
    .hardik div, .ankit div{
        font-size: 12px;
    }
    .tabPage{
        padding: 0;
    }
    .head{
        font-size: 4vh;
    }
  }

  @media(max-width:440px){
    .tabs{
        font-size: 12px ;
        padding-bottom: 10px;
        margin-bottom: 7px;
    }

    .hardik div, .ankit div{
        font-size: 10px;
    }

    .brief{
        padding: 0 10px;
    }
  }

  @media(max-width:400px){
    .tabs{
        font-size: 12px ;
        padding-bottom: 10px;
        margin-bottom: 7px;
        gap: 5px;
    }

    .tabButton{
        padding: 4px 8px;
    }

    .managers{
        flex-direction: column;
        justify-content: space-around;
        gap: 10px;
    }

    .hardik div, .ankit div{
        font-size: 10px;
    }

    .box{
        padding: 4px 8px;
    }
  }

  @media(max-width:380px){
    .tabButton{
        padding: 2px 4px;
        font-size: 12px;
    }
    .head{
        font-size: 2.5vh;
    }
    .brief{
        font-size: 2vh;
        padding: 0 10px;
    }

  }


  