.card{
    position: absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13%;
    height: 25vw;
    width: 50vw;
    border-radius: 0%;
    /* From https://css.glass */
    background: rgba(87, 255, 193, 0.39);
    box-shadow: 6px 6px 1px rgb(255, 255, 255);
    backdrop-filter: blur(2.9px);
    -webkit-backdrop-filter: blur(2px);
    
}

.card:hover {
    scale: 1.03;
}

.head {
  /* position: absolute; */
  color: white;
  width: 100%;
  text-align: center;
  /* align-items: center; */
  left: 20%;
  height: 50%;
  font-size: 45px;
  font-family: Bruno Ace;
}

.head:after {
  /* position: absolute; */
  content: "";
  width: 100%;
  height: 100%;
  line-height: 160px;
  left: 30%;
  top: 50%;
  animation: abomination1 3.5s linear 1;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes abomination1 {
  0% {
    content: "C";
  }
  10% {
    content: "Co";
  }
  20% {
    content: "Com";
  }
  30% {
    content: "Comi";
  }
  40% {
    content: "Comin";
  }
  50% {
    content: "Coming";
  }
  60% {
    content: "Coming S";
  }
  70% {
    content: "Coming So";
  }
  80% {
    content: "Coming Soo";
  }
  90% {
    content: "Coming Soon";
  }
  92.5%{
    content: "Coming Soon.";
  }
  95%{
    content: "Coming Soon..";
  }
  97.5%{
    content: "Coming Soon...";
  }
  100% {
    content: "Coming Soon...";
  }
}

/* responsive */
@media (max-width: 840px) {
    .card {
        margin-top: 25%;
        height: 300px;
        width: 500px;
    }
}
@media (max-width: 550px) {
    .card {
        margin-top: 30%;
        height: 350px;
        width: 300px;
    }
    .head {
        margin-top: -120px;
    }
}
@media (max-width: 400px) {
    .card {
        margin-top: 50%;
        height: 350px;
        width: 250px;
    }
    .head {
        margin-top: -120px;
    }
}