.footfunchide{
    position: fixed;
    bottom: -200px;
    transition: bottom 0.5s ease-in-out;
    /* background-image: url('./footimg.png'); */
    width: 100vw;
    /* height: 160px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.footfuncshow{
    position: fixed;
    bottom: 0px;
    transition: bottom 0.5s ease-in-out;
    /* background-image: url('./footimg.png'); */
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100vw;
    /* height: 160px; */
}

.footfunc{
    z-index: 210000000000;
}

.anounceIt{
    top: -58px;
    position: relative;
    text-align: center;
    height: 10px;
}
.anounceIt a{
    text-decoration: none;
    color: white;
    font-size: 22px;
    animation: ohyeah 3s infinite;
    font-weight: bold;
    text-align: center;
    font-family: Bruno Ace;
}

@keyframes ohyeah {
    0% {
        opacity: 1; /* Fully visible */
      }
      50% {
        opacity: 0; /* Completely transparent */
      }
      100% {
        opacity: 1; /* Fully visible */
      }
}

.footWeb{
    display: flex;
    justify-content: space-between;
    /* bottom: 0; */
    /* margin-top: 95px; */
}

.footWeb{
    display: flex;
}

.footWeb li{
    list-style: none;
    text-align: center;
    margin: 0 auto;
    padding: 1vw;
}

.footWeb li a{
    color: white;
    font-family: Bruno Ace,sans-serif;
    font-size: 13px;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    /* font-weight: 300; */
}

.footWeb li a:hover{
    color: #1ef8c5;
}

.footLink{
    width: 35%;
    display:flex;
    align-items: flex-end;
}

.socialMedia{
    width: 35%;
    display: flex;
    justify-content: center;
    justify-content: space-around;
    align-items: flex-end;
}

.socialMedia img{
    width: 50px;
}

.footMulti{
    display: flex;
}



.socialMedia li {
    list-style: none;
    position: relative; /* Establishes a positioning context for absolute elements */
    width: 45px; /* Ensure the container matches the image size */
    height: 45px; /* Match this to the image size */
    /* padding: 10px; */
  }
  
  .socialMedia a {
    display: block; /* Ensures the images stack correctly */
    width: 200%; 
    height: 200%;
    position: relative; /* Ensures the images stay within the boundaries */
  }
  
  .socialMedia img {
    position: absolute; /* Stack images on top of each other */
    top: -5px;
    left: -15px;
    width: 100%; /* Make sure both images occupy the same size */
    height: 100%;
    transition: opacity 0.3s ease-in-out;
  }
  
  .socialMedia img.default {
    opacity: 1; /* Default image is visible initially */
    z-index: 1; /* Position default image behind the hover image */
  }
  
  .socialMedia img.hover {
    opacity: 0; /* Hover image is hidden initially */
    z-index: 2; /* Bring hover image to the front when visible */
  }
  
  .socialMedia li:hover .default {
    opacity: 0; /* Fade out the default image on hover */
  }
  
  .socialMedia li:hover .hover {
    opacity: 1; /* Fade in the hover image on hover */
  }
  

@media (max-width: 848px){
    .anounceIt{
        display :none;
    }
    .footLinkO{
        display: none;
    }
    .footfuncshow{
        background: none;
        height: auto;
    }
    .footfunchide{
        background: none;
        height: auto;
    }
    .footWeb{
        padding: 0;
    }
    .footWeb{
        margin-top: 0;
    }
    .socialMedia{
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
        justify-content: space-around;
        padding-top: 10px;
    }
    /* .socialMedia img{
        width: 25px;
    } */
    .socialMedia li{
        height: 35px;
        width: 35px;
    }
}

@media (max-width: 500px){
    .anounceIt{
        display :none;
    }
    .footfunc{
        z-index: 21;
    }
    .footLinkO{
        display: none;
    }

    .footfuncshow{
        background: none;
        height: auto;
    }
    .footfunchide{
        background: none;
        height: auto;
    }
    .footWeb{
        padding: 0;
    }
    .footWeb{
        margin-top: 0;
    }
    .socialMedia{
        width: 90%;
        margin: auto;
        /* margin-bottom: 20px; */
        justify-content: space-around;
        /* padding-top: 10px;
        padding-left: 15px; */
        align-items: center;
        left: 5%;
        position: relative;
    }
    
    .socialMedia li img{
        height: 30px;
        width: 30px;
    }
}

@media (max-width: 1720px){
    .footWeb li a{
        font-size: 13px;
    }
}
@media (max-width: 1520px){
    .footWeb li a{
        font-size: 13px;
    }
}
@media (max-width: 1420px){
    .footWeb li a{
        font-size: 13px;
    }
}
@media (max-width: 1300px){
    .footWeb li a{
        font-size: 13px;
    }
}
@media (max-width: 1300px){
    .footWeb li a{
        font-size: 13px;
    }
    .footLink {
        align-items: flex-end;
    }
    .footMulti{
        display: block;
    }
    .footMulti li{
        margin: 0 auto;
        padding: 0;
    }
    .footWeb li{
        margin-top: 1px;
        /* padding: 0; */
    }
    .footLink{
        justify-content: space-around;
    }
    .socialMedia li{
        height: 35px;
        width: 35px;
    }
}
@media (max-width: 1000px){
    .footLink {
        align-items: flex-end;
    }
    .footMulti{
        display: block;
    }
    .footMulti li{
        margin: 0 auto;
        padding: 0;
    }
    .footWeb li{
        margin: 0;
        /* padding: 0; */
    }
    .footLink{
        justify-content: space-around;
    }
    .socialMedia li{
        width: 34px;
        height: 34px;
    }
}

.about{
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    width: 168px;
    z-index: 2;
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    bottom: 40px;
    overflow: hidden;
    border-radius: 15px;
    border: 2px solid #a2f8c5;
}

.aboutyo{
    max-height: 300px;
    opacity: 1;
    visibility: visible;
    transition: max-height 1s ease, opacity 1s ease, visibility 1s;

}
.aboutno{
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: max-height 1s ease, opacity 1s ease, visibility 0s 1s;
}