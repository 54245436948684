.history {
    position: relative;
    min-height: 200vh; /* Increase height to ensure scrolling */
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.timeline {
    position: relative;
    justify-content: center;
    align-items: center;
    top: 150px;
    display: flex;
    flex-direction: column;
}

.timeline .timeimg {
    height: 50%;
    width: 70%;
}

.card1, .card2, .card3, .card4, .card5, .card6, .card7, .card8, .card9, .card10, .card11, .card12, .card13, .card14, .card15, .card16 {
    position: absolute;
}

.card1 {
    top: 565px;
    left: -270px;
}

.card2 {
    top: 1100px;
    left: 400px;
}
.card3 {
    top: 1600px;
    left: -270px;
}

.card4{
    top: 2130px;
    left: 400px;
}

.card5{
    top: 2660px;
    left: -270px;
}

.card6{
    top: 3160px;
    left: 400px;
}
.card7{
    top: 3660px;
    left: -270px;
}

.card8{
    top: 4170px;
    left: 400px;
}

.card9{
    top: 4670px;
    left: -270px;
}

.card10{
    top: 5190px;
    left: 400px;
}

.card11{
    top: 5700px;
    left: -270px;
}

.card12{
    top: 6210px;
    left: 400px;
}

.card13{
    top: 6710px;
    left: -270px;
}

.card14{
    top: 7230px;
    left: 400px;
}

.card15{
    top: 7740px;
    left: -270px;
}

.card16{
    top: 8250px;
    left: 400px;
}

.up {
    position: absolute;
    top: 87px;
    z-index: 5;
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.down {
    position: absolute;
    top: 140px;
    z-index: 5;
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.photo {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.HistoryText{
    height: 119px;
    width: 435px;
    color: white;
    font-size: 50px;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 50px;
}


/* Responsive Styles */
@media (max-width: 1024px){
    .card1, .card3, .card5, .card7, .card9{
        left: -230px;
    }
    .card2, .card4, .card6, .card8{
        left: 350px;
    }
}
@media (max-width: 768px) {
    .history {
        min-height: 100vh;
        padding: 20px;
    }

    .timeline {
        width: 90%;
        top: 80px;
    }

    .timeline .timeimg {
        height: 30%;
        width: 40%;
        max-width: 500px;
    }

    .HistoryText {
        font-size: 32px;
        margin-bottom: 30px;
    }

    .card1 {
        top: 510px;
        left: 45px;
    }

    .card2{
        top: 950px;
        left: 425px;
    }

    .card3{
        top: 1375px;
        left: 45px;
    }

    .card4{
        top: 1830px;
        left: 425px;
    }

    .card5{
        top: 2280px;
        left: 25px;
    }

    .card6{
        top: 2710px;
        left: 425px;
    }

    .card7{
        top: 3130px;
        left: 25px;
    }

    .card8{
        top: 3575px;
        left: 425px;
    }

    .card9{
        top: 4000px;
        left: 25px;
    }

    .card1, .card2, .card3, .card4, .card5, .card6, .card7, .card8, .card9 {
        /* margin: 15px auto; */
        width: 20vw;
    }
    
    .photo{
        width: 100%;
    }

    .down {
        top: 70px;
        width: 100%;
        transform: translateY(40px);
    }

    .up{
        top: 30px;
        width: 100%;
        transform: translateY(-50px);
    }
    
}

@media (max-width: 430px) {
    .history {
        min-height: 50vh;
        padding: 10px;
    }

    .HistoryText img {
        height: 50%;
        /* width: 35%; */
        font-size: 10px;
    }

    .timeline {
        width: 50%;
        top: 80px;
    }

    .timeline .timeimg {
        width: 100%;
    }

    .card1{
        top: 440px;
        left: -70px;
    }

    .card2{
        top: 790px;
        left: 185px;
    }

    .card3{
        top: 1125px;
        left: -70px;
    }

    .card4{
        top: 1480px;
        left: 185px;
    }

    .card5{
        top: 1835px;
        left: -70px;
    }

    .card6{
        top: 2170px;
        left: 185px;
    }

    .card7{
        top: 2505px;
        left: -70px;
    }

    .card8{
        top: 2855px;
        left: 185px;
    }

    .card9{
        top: 3190px;
        left: -70px;
    }

    .card1, .card2, .card3, .card4, .card5, .card6, .card7, .card8, .card9 {
        margin: 10px auto;
        max-width: 250px;
    }

    .photo{
        width: 100%;
    }

    .down {
        top: 40px;
        width: 100%;
        /* transform: translateY(40px); */
    }

    .up{
        top: 30px;
        width: 100%;
        /* transform: translateY(-50px); */
    }
}

@media (max-width: 380px){
    .history {
        min-height: 50vh;
        padding: 10px;
    }

    .HistoryText img {
        height: 40%;
        /* width: 35%; */
        /* font-size: 10px; */
    }

    .timeline {
        width: 40%;
        top: 80px;
    }

    .timeline .timeimg {
        width: 100%;
    }

    .card1{
        top: 360px;
    }

    .card2{
        top: 600px;
        left: 140px;
    }

    .card3{
        top: 835px;
    }

    .card4{
        top: 1080px;
        left: 140px;
    }

    .card5{
        top: 1340px;
    }

    .card6{
        top: 1570px;
        left: 140px;
    }

    .card7{
        top: 1800px;
    }

    .card8{
        top: 2050px;
        left: 140px;
    }

    .card9{
        top: 2280px;
    }

    .card1, .card2, .card3, .card4, .card5, .card6, .card7, .card8, .card9 {
        margin: 10px auto;
        max-width: 250px;

    }

    
}
