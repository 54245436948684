



.lectures {
  position: relative;
  /* padding: 5rem; */
  height: 100vh; /* Ensure the container has a height */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
   /* Ensure text is visible against the background */  
   flex-wrap: wrap;   
   overflow: auto; 
   overflow-x: hidden;
  }

  .Contactbox{
    display: flex;
    justify-content: space-around;
    flex-basis: 1.2em;
    border:3px solid #a2f8c5;
    margin-right: 7vw;
    margin-left: 7vw;
    margin: 7vw;
    font-size: 21px;
    border-radius: 10px;
    height: 100pt;
    align-items: center;
    color: white;
    font-family:'Times New Roman', Times, serif;
    font-weight: 900;
    background-color:rgba(0,0, 0, 0.7);
    }
    @media(max-width: 450px) {
        .Contactbox{
            height: 200px;
            flex-direction: column;
        }
    }
  
.heading {
    text-align: center;
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add text shadow for better readability */
    top:10vh;
    position: relative;
    color: white;
    font-family: Bruno Ace,sans-serif;
    font-weight: 900;
    /* margin: 100px; */
    justify-content: center;
    width: 100vw;
    align-items: center;
    margin-bottom: 6vh;
    margin-bottom: 40vh;
  }
.cardsBox{
  /* margin: 0 101px; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px;
    /* border: 2px solid blue; */
    position: relative;
    top: 80px;
  }
.card{
    /* border-radius: 13px; */
    width: 250px;
    height: 300px;
    position: relative;
    top: 1px;
    scale: 0.9;
    /* border: 2px solid rgb(0, 0, 0); */
    /* new */ 
    cursor: pointer;
  }

  .cardlayout{
    position: absolute;
    z-index: 100;
    width: auto;
    height: 360px;
    left: -19%;
    top: -11%;
    /* display: none; */
    /* transform: translate(-50%,-50%); */
  }
.btn{
    color: rgb(255, 255, 255);
    background-color: #074173;
    position: relative;
    top: 0px;
    /* border-radius: 11px; */
    outline: none;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 2;
    border: none;
  }
.btn:hover{
  outline: none;
    color: rgb(255, 255, 255);
    background-color: #1679AB;
}
.btn:active{
  background-color: #074173;
}
.para{
    padding: 6px;
    display: none;
    position: absolute;
    top: 4px;
    left: 11px;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    opacity: .7;
    z-index: 3;
    margin-top: 25px;
    margin-bottom: 0px;
    height: 267px;
    width: 317px;
    border-radius: 11px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.card .image{
  /* width: 300px; */
  /* height: 350px; */
  width: 100%;
  height: 100%;
  position: relative;
  top: -24px;
  /* border-radius: 11px; */
  z-index: -100;
  object-fit: cover;
  transition: transform 0.5s ease, filter 0.5s ease;
}

.card .image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 1; /* Ensure the gradient appears above the image */
}

.card:hover .image {
  /* filter: grayscale(0%); */
  transform: scale(1.03);
  filter: blur(5px);
  filter:brightness(50%);
}

.card:hover .image::after {
  opacity: 1; /* Show the gradient on hover */
}

.nameInfo {
  font-size: 21px;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  top: -90px;
  text-shadow: 1px 1px 3px rgb(255, 255, 53);
  z-index: 2;
  transition: top 0.7s ease, opacity 0.5s ease;
  opacity: 1;
}

.parainfo {
  font-size: 13px;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  top: -90px;
  text-shadow: 1px 1px 3px rgb(255, 255, 53);
  transition: top 0.8s ease, opacity 0.5s ease;
  opacity: 0;
  display: block; /* Display set to block initially for smooth transition */
}

/* Hover effect on the card to animate both text elements */
.card:hover .nameInfo,
.card:hover .parainfo {
  top: -160px;
}

/* .card:hover .image {
  : 50%;
} */
/* Additional hover effect to make .parainfo visible */
.card:hover .parainfo {
  opacity: 1;
}


.card .image {
  object-fit: cover;
  /* transition: filter .5s ease; */
  transition: transform 0.5s ease, filter 0.5s ease;
  /* filter: grayscale(10%); */
}
/* .card:hover .image {
  filter: grayscale(0%);
  transform: scale(1.03);
} */
/* .card{
  transition: filter 0.5s ease;
}
.card:hover{
  filter: grayscale(100%);
} */
.polygonL{
  /* border: 2px solid white; */
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 0px;
  height: 0px;
  clip-path: polygon(0 0, 0 80%,20% 100%,80% 100%,100% 80%,100% 20%,80% 0);
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgb(255, 255, 255);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease, clip-path 0.5s ease, left 0.5s ease, bottom 0.5s ease;
  z-index: 2;
  rotate: -90deg;
}

.form {
  text-align: center;
  margin-top: 8vw;
  margin-top: 15vh;
  z-index: 1000;
}

.form p {
  color: white;
  font-size: 20px;

@media (max-width: 500px) {
  font-size: 15px;
}
}

.form .customButton {
  background: linear-gradient(135deg, #6efb8f, #77e3dc);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.customButton:hover {
  background: linear-gradient(135deg, #a777e3, #6e8efb);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.customButton:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
