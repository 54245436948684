.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c{
    border-radius: 10px;
    text-align: left;
    background: #9d7fd370;
    margin-bottom: 5px;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 20px;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
    padding-right: 3em;
    padding-left: 8px;
}