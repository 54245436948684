@import url('https://fonts.googleapis.com/css2?family=Forum&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.body {
    height: fit-content;
    background-color: #1e170f;
    font-family: "Forum", serif;
    color: white;
    text-align: center;
    padding-bottom: 3vh;
}

.heroPage {
    color: white;
    height: 120vh;
    background-image: url('./assets/newhero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contentPage {
    margin: 10vh;
}

.heroImg {
    width: 100%;
}

.heading {
    padding-top: 10vh;
    font-family: "Forum", serif;
    font-size: 10vh;
    text-align: center;
}

.association {
    /* width: 80vw; */
    height: 35vh;
    padding-top: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.association img {
    height: 100%;
    width: auto;
}

.credit {
    font-size: 3vh;
    text-align: center;
    margin-top: 30vh;
}

.subHeading {
    font-family: "Forum", serif;
    margin: 0 0 5vh 0;
    font-weight: bold;
}

.heroPage p {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* p {
    font-size: 18px;
    font-family: "Forum", serif;
    line-height: 1.3;
} */

/* grp1 */
.grp1 {
    margin: 5vw;
    display: flex;

    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.grp1Left {
    width: 30vw;
}

.grp1Left img {
    width: 100%;
}

.grp1Right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.grp1Right .point {
    display: flex;
    align-items: center;
    width: 100%;
}

.box {
    font-size: 25px;
    background-color: #FFB232;
    margin: 0 3vh 0 7vh;
    padding: 2.5vh;
    font-weight: bold;
    width: 20%;
}

.point p {
    text-align: left;
    text-wrap: wrap;
}

/* the plan */
.planHead {
    font-size: 35px;
    font-family: serif;
    border-bottom: 3px solid #FFB232;
}

.plan {
    text-align: left;
}

.top {
    display: flex;
    margin-bottom: 5vh;
}

.topLeft {
    width: 45vw;
}

.topRight {
    width: 45vw;
}

.bottom {
    display: flex;
}

.bottomLeft {
    width: 45vw;
}

.bottomRight {
    width: 45vw;
    display: flex;
    justify-content: space-between;
}

.listitems {
    font-size: 18px;
    font-family: "Forum", serif;
    line-height: 1.3;
    text-wrap: wrap;
    margin-right: 5vh;
    margin-top: 1vh;
}

.explorebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 0 auto;
    width: 10%;
    height: 45px;
    border-radius: 20px;
    position: relative;
    transition: width 0.4s;
    background-color: #FFB232;
    border-radius: 20px;
    text-align: center;
    min-width: 100px;
    margin-top: 5vh;
    cursor: pointer;
    font-weight: bold;
}

.exploreAnchor {
    text-decoration: none;
    font-weight: bold;
    color: black;
    position: relative;
    transition: left 0.5s ease-in-out;
    margin: 50px;
}

.exploreAnchor:hover {
    color: #000000;
}

.exploreIcon {
    font-size: 1.5em;
    color: black;
    position: absolute;
    left: 65%;
    opacity: 0;
    transition: 0.5s ease-in-out;
}

.explorebtn:hover {
    width: 17%;
    min-width: 150px;

    .exploreAnchor {
        left: 17%;
    }

    .exploreIcon {
        opacity: 1;
        left: 80%;
        transition-duration: 0.1s;
    }

}

/* contactpage */
.contactPage {
    border: 3px solid #FFB232;
    margin: 0vw 30vw 10vh 30vw;
    font-size: 21px;
    border-radius: 10px;
    color: white;
    padding: 3vh;
    font-weight: 900;
    width: 40vw;
}

@media (max-width: 1000px) {
    .contentPage {
        margin: 3vh;
    }

    /* grp1 */
    .grp1Left {
        width: 50vw;
    }

    .grp1Left img {
        width: 100%;
    }

    .grp1Right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .grp1Right .point {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .box {
        font-size: 20px;
        background-color: #FFB232;
        margin: 0 0 0 0;
        padding: 1vh;
        font-weight: bold;
        width: 30%;
    }

    .point p {
        text-align: left;
        text-wrap: wrap;
    }
}

@media (max-width: 690px) {

    .subHeading {
        font-family: "Forum", serif;
        margin: 0 0 5vh 0;
        font-size: 30px;
        font-weight: bold;
    }

    /* the plan */
    .planHead {
        font-size: 25px;
        font-family: serif;
        border-bottom: 3px solid #FFB232;
    }

    .plan {
        text-align: left;
    }

    .top {
        display: flex;
        flex-direction: column;
    }

    .topLeft {
        width: 90vw;
    }

    .topRight {
        width: 90vw;
    }

    .bottom {
        display: flex;
        flex-direction: column;
    }

    .bottomLeft {
        width: 90vw;
    }

    .bottomRight {
        width: 90vw;
        display: flex;
        justify-content: space-between;
    }

    .listitems {
        font-size: 18px;
        font-family: "Forum", serif;
        line-height: 1.3;
        text-wrap: wrap;
        margin-right: 5vh;
        margin-top: 1vh;
    }

    .box {
        font-size: 20px;
        background-color: #FFB232;
        margin: 0 0 0 0;
        padding: 1vh;
        font-weight: bold;
        width: 50%;
    }

    /* contactpage */
    .contactPage {
        border: 3px solid #FFB232;
        margin: 10vw 10vw 10vh 10vw;
        font-size: 21px;
        border-radius: 10px;
        color: white;
        padding: 3vh;
        font-weight: 900;
        width: 80vw;
    }
}

@media (max-width: 530px) {
    .association {
        /* width: 80vw; */
        height: 33vh;
        padding-top: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .association img {
        height: 100%;
        width: auto;
    }

    .credit {
        font-size: 3vh;
        text-align: center;
        margin-top: 25vh;
    }
}

@media (max-width: 420px) {
    .heading {
        padding-top: 20vh;
        font-family: "Forum", serif;
        font-size: 8vh;
        text-align: center;
    }

    .credit {
        font-size: 3vh;
        text-align: center;
        margin-top: 20vh;
    }
    .association {
        /* width: 80vw; */
        height: 30vh;
        padding-top: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .association img {
        height: 100%;
        width: auto;
    }

}

@media (max-width: 320px) {
    .contentPage {
        margin: 3vh;
    }

    .heading {
        padding-top: 10vh;
        font-family: "Forum", serif;
        font-size: 8vh;
        text-align: center;
    }

    .credit {
        font-size: 2vh;
        text-align: center;
        margin-top: 20vh;
    }

    p {
        font-size: 13px;
        font-family: "Forum", serif;
        line-height: 1.3;
    }

    /* grp1 */
    .grp1Left {
        width: 80vw;
    }

    .grp1Left img {
        width: 100%;
    }

    .grp1Right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .grp1Right .point {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .box {
        font-size: 20px;
        background-color: #FFB232;
        margin: 0 0 0 0;
        padding: 1vh;
        font-weight: bold;
        width: 50%;
    }

    .point p {
        text-align: left;
        text-wrap: wrap;
    }

    .listitems {
        font-size: 13px;
        font-family: "Forum", serif;
        line-height: 1.3;
        text-wrap: wrap;
        margin-right: 5vh;
        margin-top: 1vh;
    }

    .association {
        /* width: 80vw; */
        height: 28vh;
        padding-top: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .association img {
        height: 100%;
        width: auto;
    }

    .credit {
        font-size: 2vh;
        text-align: center;
        margin-top: 25vh;
    }
}