.ic {
  overflow-x: hidden;
}

.ic_head {
  width: 100vw;
  height: 100vh;
}

.ic_head .icTop {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.icHeading {
  position: absolute;
  top: 100px;
  width: 100%;
}

.icAbout {
  margin-top: 38px;
}

.icMain h1 {
  font-size: 2.5rem;
  border-bottom: 1px solid #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

.icMain h2 {
  color: #fff;
  text-align: left;
}

.icMain {
  max-width: 80%;
  margin: auto;
}

.icMain .icGen div {
  color: #fff;
  margin-bottom: 30px;
}

.icMain p {
  text-align: left;
  font-size: 18px;
}
.icMain ul li {
  text-align: left;
  font-size: 18px;
}

.icDates {
  color: #fff;
  display: flex;
  justify-content: space-evenly;
}
.icDate_o {
  border: 1px solid #c9984f;
  margin-bottom: 30px;
}
.icDate_i {
  border: 3px solid #c9984f;
  transform: translate(5px, 5px);
}
.icDate_t {
  font-size: 18px;
  padding: 5px;
  border: 1px solid #c9984f;
}
.icDate_b {
  font-size: 18px;
  padding: 5px 15px;
}

.icContact {
  color: #fff;
  display: flex;
  justify-content: center;
}
.icContact_o {
  border: 1px solid #c9984f;
  margin-bottom: 30px;
}
.icContact_i {
  border: 3px solid #c9984f;
  transform: translate(5px, 5px);
  padding: 5px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 950px) {
  .icDates {
    flex-direction: column;
    max-width: 50%;
    margin: auto;
  }
}
@media (max-width: 658px) {
  .icDates {
    max-width: 100%;
  }
}
