/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* General Styles */
.wdata {
    display: flex;
    margin: auto;
    max-width: 90%;
    justify-content: space-between;
    position: relative;
    top: 260px;
    margin-top: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.sponsor {
    display: flex;
    justify-content: center;
}

.sponsor img {
    width: 100px;
    height: 50px;
    border-radius: 10px;
    object-fit: fill;
    border: 1px solid #fff;
}

.sponsor a {
    text-decoration: none;
    color: white;
}

.compi_img {
    width: 300px;
    height: 300px;
    border-radius: 20px;
    border: 1px solid #000000;
}

.overlay {
    position: relative;
    top: 80px;
}

.heading {
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.compi_prize {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 24px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statement {
    display: flex;
    justify-content: center;
}

.statement a {
    text-decoration: none;
    color: #fff;
    font-family: "Forum", sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
}

.stat_rect1 {
    height: 40px;
    width: 250px;
    border: 1px solid #fff;
    position: absolute;
}

.stat_rect2 {
    height: 40px;
    width: 250px;
    border: 1px solid #fff;
    margin-left: 8px;
    margin-top: 7px;
    position: absolute;
}

.int_reg {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.int_reg a {
    text-decoration: none;
    color: #fff;
    font-family: "Forum", sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
}

.reg_rect1 {
    height: 40px;
    width: 120px;
    border: 1px solid #fff;
    position: absolute;
}

.reg_rect2 {
    height: 40px;
    width: 121px;
    border: 1px solid #fff;
    margin-left: 16px;
    margin-top: 7px;
    position: absolute;
}

.compi_team {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.compi_team a {
    text-decoration: none;
    color: #fff;
    font-family: "Forum", sans-serif;
    letter-spacing: 0.1em;
    font-size: 18px;
}

.create_rect1,
.join_rect1,
.single_rect1 {
    border: 1px solid #fff;
    position: absolute;
}

.create_rect2,
.join_rect2,
.single_rect2 {
    border: 1px solid #fff;
    position: absolute;
}

.create_team {
    width: 200px;
}

.create_rect1 {
    height: 40px;
    width: 140px;
}

.create_rect2 {
    height: 40px;
    width: 140px;
    margin-left: 7px;
    margin-top: 6px;
}

.join_team {
    width: 120px;
    text-decoration: none;
    color: #fff;
    font-family: "Forum", sans-serif;
    letter-spacing: 0.1em;
    font-size: 18px;
}

.join_rect1 {
    height: 40px;
    width: 120px;
}

.join_rect2 {
    height: 40px;
    width: 121px;
    margin-left: 7px;
    margin-top: 6px;
}

.single_team {
    width: 200px;
}

.single_rect1 {
    height: 40px;
    width: 200px;
}

.single_rect2 {
    height: 40px;
    width: 200px;
    margin-left: 7px;
    margin-top: 6px;
}

.reg_button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    text-align: inherit;
    font: inherit;
    border-radius: 0;
    appearance: none;
    text-decoration: none;
    color: #fff;
    font-family: "Forum", sans-serif;
    letter-spacing: 0.1em;
    font-size: 18px;
}

.wImg {
    height: 350px;
}

.imgFrame {
    width: 350px;
    height: 312px;
    position: relative;
    top: 0%;
    border-radius: 26% 26% 0% 0%;
    z-index: 2;
}

.imgCompi {
    width: 280px;
    height: 300px;
    position: relative;
    top: 0%;
    border-radius: 0px;
    z-index: 1;
}

.youhave {
    margin-top: 20px;
    margin-bottom: 20px;
}

.youhave span {
    color: rgb(218, 135, 0);
    font-weight: bolder;
}

/* Mobile */
.mImgFrame {
    width: 365px;
    height: 319px;
    position: relative;
    top: 0%;
    left: 0.5%;
    border-radius: 26% 26% 0% 0%;
    z-index: 2;
}

.mImgCompi {
    width: 284px;
    height: 300px;
    position: relative;
    top: -50%;
    border-radius: 26% 26% 0% 0%;
    z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .imgFrame {
        left: -8.5%;
    }
}

@media (max-width: 992px) {
    .rightdata {
        margin-top: 60px;
    }

    .wImg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgFrame {
        position: absolute;
        top: 9%;
        left: auto;
    }

    .imgCompi {
        top: 0%;
    }
}

@media (max-width: 768px) {
    .sponsor {
        justify-content: center;
    }

    .wdata {
        margin-top: 70px;
    }

    .basicInfo {
        top: -275px;
        position: relative;
    }

    .compi_prize {
        margin-top: 0;
    }

    .lol_reg {
        top: -275px;
        position: relative;
    }

    .statement {
        top: -200px;
        position: relative;
    }
}

@media (max-width: 577px) {
    .compi_team {
        flex-direction: column;
        align-items: center;
    }

    .create_team {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 450px) {
    .doIt {
        justify-content: space-around;
    }

    .mImgFrame {
        width: 350px;
        height: 322px;
    }

    .mImgCompi {
        width: 284px;
        height: 301px;
    }
}

@media (max-width: 364px) {
    .mImgFrame {
        width: 300px;
    }

    .mImgCompi {
        width: 249px;
    }
}

.doIt {
    display: flex;
    flex-wrap: wrap;
    top: -290px;
    justify-content: space-evenly;
    position: relative;
}

.share {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.share_rect1 {
    height: 40px;
    width: 120px;
    border: 1px solid #fff;
    position: absolute;
}

.share_rect2 {
    height: 40px;
    width: 121px;
    border: 1px solid #fff;
    margin-left: 16px;
    margin-top: 7px;
    position: absolute;
    color: #fff;
    font-family: "Forum", sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
}

/* Info Section */
.info_head {
    display: flex;
    justify-content: space-between;

}

@keyframes letterUp {
    0% {
      transform: translateY(0);
      opacity: 1;
      font-weight: 500;
    }
    50% {
      transform: translateY(-10px);
      opacity: 0;
      font-weight: 500;
      /* font-weight: 600; */
    }
    
    100% {
      transform: translateY(-25px) scale(1);
      opacity: 1;
      font-weight: 700;
    }
  }
  
  /* @keyframes letterDown {
    0% {
      transform: translateY(-30px) scale(1);
      opacity: 0;
    }
    100% {
      transform: translateY(-60px) scale(2);
      opacity: 1;
    }
  } */
  
  .bar_oii {
    width: 14%;
    /* padding: 2px 0px; */
    height: 30px;
    font-size: 17px;
    cursor: pointer;
    color: white;
    border-radius: 0px;
    justify-content: center;
    display: flex;
    position: relative;
    background: rgba(0,0,0,0.7);
    border: 1px solid white;
    border-radius: 5px;
    /* overflow: hidden; */
  }

  .bar_oii:hover{
    background: white;
    color: black;
    /* transition: 0.1s ease-in-out; */
  }

  .bar_oii.active{
    background: white;
    /* margin-top: 10px; */
    color: black;
  }
  
  .bar_item {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  
  .bar_item span {
    display: inline-block;
    transition: transform 0.5s ease, opacity 0.5s ease;
    text-transform: uppercase;
  }
  
  .bar_oii:hover .bar_item span {
    animation: letterUp 0.5s forwards, letterDown 0.5s forwards 0.5s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(2) {
    animation-delay: 0.05s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(3) {
    animation-delay: 0.1s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(4) {
    animation-delay: 0.15s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(5) {
    animation-delay: 0.2s;
  }
  .bar_oii:hover .bar_item span:nth-child(6) {
    animation-delay: 0.25s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(7) {
    animation-delay: 0.3s;
  }
  
  .bar_oii:hover .bar_item span:nth-child(8) {
    animation-delay: 0.35s;
  }
  .bar_oii:hover .bar_item span:nth-child(9) {
    animation-delay: 0.4s;
  }
  
  /* Add more nth-child selectors as needed for additional letters */
  
  /* .bar_oii:hover {
    transform: scale(1);
    transition: transform 0.5s ease;
  } */
  
  
  

  

.bar_extra {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5.5px);
    width: 4%;
}

.bar_item {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.bar_item:hover {
    transform: scale(1);
    /* transition: all 0.5s ease; */
}

.bar_item.active{
    background: white;
    color: black;
}

.impdata {
    margin-bottom: 20px;
}

@keyframes axisMove {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.info_data {
    color: white;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 15px;
    /* border: 1px solid white; */
}

.info_tab {
    height: 300px;
    width: 100%;
    background: rgba(0,0,0,0.7);
    /* box-shadow: 20px 20px 33px #868686, -20px -20px 33px #ffffff; */
    margin-top: 20px;
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: white;
    border: 1px solid white;
}

.info_tab::-webkit-scrollbar {
    width: 10px;
}

.info_tab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.info_tab::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
}

.info_tab::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

.information {
    color: white;
}

.information .hide {
    display: none;
}

.whatuOpen {
    background: #fff;
}

.whatu {
    display: flex;
    margin-top: 10px;
    cursor: pointer;
}

/* .whatu_rect1 {
    height: 40px;
    width: 120px;
    border: 1px solid #fff;
    position: absolute;
} */

.whatu_rect2 {
    height: 40px;
    width: 120px;
    border: 3px solid #1ef8c5;
    margin-left: 8px;
    margin-top: 7px;
    position: absolute;
    color: #fff;
    font-family: 'Forum', sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(0,0,0,0.7);
}

.whatd {
    display: flex;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease, opacity 0.3s ease;
    margin-top: 60px;
    opacity: 0;
}

.whatdOpen {
    display: flex;
    flex-direction: column;
    height: 300px; /* Adjust the height as needed */
    transition: height 0.3s ease, opacity 0.3s ease;
    margin-top: 60px;
    margin-bottom: 20px;
    opacity: 1;
}

/* .whatd_rect1 {
    height: 300px;
    width: 88%;
    border: 1px solid #fff;
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5.5px);
    z-index: -1;
} */

.whatd_rect2 {
    height: 300px;
    width: 88%;
    border: 2px solid #1ef8c5;
    margin-left: 8px;
    margin-top: 7px;
    position: absolute;
    color: #fff;
    font-family: 'Forum', sans-serif;
    letter-spacing: 0.2em;
    font-size: 18px;
    overflow-y: scroll;
    padding: 20px 10px;
    text-align: left;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5.5px);
    border-radius: 15px;
}

.whatd_rect2::-webkit-scrollbar {
    width: 10px;
}

.whatd_rect2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px none;
    border-radius: 10px;
}

.whatd_rect2::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 10px;
}

.whatd_rect2::-webkit-scrollbar-thumb:hover {
    background: none;
}

.faq_answer {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.faq_answer_open {
    max-height: 1000px; /* Adjust this value to a sufficiently large value */
    opacity: 1;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.mWholeData {
    position: relative;
    top: -140px;
}

.impdata ul li {
    line-height: 20px;
    margin-bottom: 12px;
    margin-top: 8px;
    list-style: none;
    position: relative;
    left: -30px;
}
